import React from 'react';
import './firstCicle.scss';

const FirstCicle = () => {
    return (
        <div className="subpage first-cicle">
            <p>PRIMER CICLO</p>
        </div>
    )
}

export default FirstCicle;