// GENERAL LAYOUT FOR GLOBAL FOOTE
import './footer.scss';

import LogoFooter from '../../../assets/logo-ib-494x485.png';
import LogoEcoFooter from '../../../assets/logo-ecoescuela.png';
import LogoCambFooter from '../../../assets/logo_cambridge.png';
import LogoCertiportFooter from '../../../assets/certiport.png';
import LogoCiscoFooter from '../../../assets/cisco.png';
import LogoMOS2003Footer from '../../../assets/MOS-2003.png';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import himno from "../../../assets/himno.mp3"
import { useState, useRef, useContext } from 'react'
import { VolumeUp, VolumeOff, QueueMusic } from '@material-ui/icons'
import { baseURL } from '../../../api';

//Contexts
import MainContext from '../../../contexts/pages/main/mainContext';




const Footer = (props) => {
    const mainContext = useContext(MainContext);
    const { anthemLyrics } = mainContext;
    const anthemPdfURL = `${baseURL}${anthemLyrics?.url}`
    const audioref = useRef()

    const [playState, setPlayState] = useState(false)

    const playsound = () => {
        const audio = audioref.current
        audio.volume = 1

        if (!playState) {
            setPlayState(true)
            audio.play()
        } else {
            setPlayState(false)
            audio.pause()
        }
    }

    return (
      <div className="row footer">
        <div className="col-12">
          {/* <FooterLayout> */}
          <div className="footer-wrapper">
            <div className="row footer-inner">
              <div className="col-lg-7 col-md-7 col-sm-12">
                <div className="row info">
                  <div className="sub-info-inner">
                    <div className="sub-info-1">
                      <h6 className="address">SECUNDARIA BABEQUE</h6>
                      <p className="address-2">Av. Roberto Pastoriza</p>
                      <p className="address-2">#329, Naco, DN</p>
                      <p className="address-2">809-381-2673</p>
                    </div>
                    <div className="sub-info-2">
                      <div className="audio__player">
                        <audio ref={audioref} src={himno} loop={true} />
                        {/* {playState ?
                                            (<p onClick={playsound} className="anthem_btn">ESCUCHA EL HIMNO DEL COLEGIO AQUÍ <VolumeOff /></p>)
                                            // (<VolumeOff onClick={playsound} className="anthem_btn"/>) 
                                            //    : (<VolumeUp onClick={playsound} className="anthem_btn"/>)}
                                            : (<p onClick={playsound} className="anthem_btn">ESCUCHA EL HIMNO DEL COLEGIO AQUÍ <VolumeUp /></p>)}
                                        <a href={anthemPdfURL} target="_blank" rel="noopener" ><QueueMusic className="anthem_btn" /></a> */}

                        <p onClick={playsound} className="anthem_btn">
                          ESCUCHA EL HIMNO DEL COLEGIO AQUÍ
                        </p>
                        <div style={{marginLeft:"8px"}}>
                          {playState ? <VolumeOff /> : <VolumeUp />}{" "}
                          <a href={anthemPdfURL} target="_blank" rel="noopener">
                            <QueueMusic className="anthem_btn" />
                          </a>
                        </div>
                      </div>
                      <Link to="/contactanos">
                                            <p className="links">CONTÁCTANOS</p>
                                        </Link>
                                        <a href="https://forms.office.com/r/MSgALxkh0H" target="_blank" rel="noreferrer">
                                            <p className="links">TRABAJA CON NOSOTROS</p>
                                        </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12 footer-icons">
                <div className="logo-ib-footer">
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.ibo.org/es/"
                  >
                    <Image src={LogoFooter} />
                  </a>
                </div>
                <div className="logo-ib-footer">
                  <Image src={LogoCambFooter} />
                </div>
                <div className="logo-ib-footer">
                  <Image src={LogoEcoFooter} />
                </div>

                <div className="logo-ib-footer">
                  <Image src={LogoCiscoFooter} />
                </div>
                <div className="logo-ib-footer">
                  <Image src={LogoCertiportFooter} />
                </div>
                <div className="logo-ib-footer">
                  <Image src={LogoMOS2003Footer} />
                </div>
              </div>
            </div>
          </div>
          {/* </FooterLayout> */}
        </div>
      </div>
    );
};

export default Footer;