import React, { useContext, useEffect } from 'react';
import './vitualTools.scss';
import VirtualToolsCard from './VirtualToolsCard';
import DialogContentText from '@material-ui/core/DialogContentText';
import ReactMarkdown from 'react-markdown';

// CONTEXT
import AboutusContext from '../../../../../contexts/pages/aboutus/aboutusContext';

import Slider from '../../../../Globals/Slider'

const VitualTools = () => {

    const aboutusContext = useContext(AboutusContext);
    const { virtualTools } = aboutusContext;

    useEffect(() => {
    }, [virtualTools])

    return (
        <div className="aboutus-subpage vitual-tools">
            {virtualTools ? ( <Slider bannerData={virtualTools.banner} />) :null}
            <div className="vitual-tools-inner">
                <div className="vitual-tools-title">HERRAMIENTAS VIRTUALES</div>
                <div className="vitual-tools-text">
                    <DialogContentText>
                        <ReactMarkdown>
                            {virtualTools.contenido}
                        </ReactMarkdown>
                    </DialogContentText>
                </div>
                <div className="areas">
                    <div className="row">
                        {virtualTools
                            ?
                            virtualTools.area.map(tool => {
                                return (
                                    <VirtualToolsCard
                                        key={tool._id}
                                        // animationStates={animationStates}
                                        title={String(tool.nombre).toUpperCase() || ""}
                                        text={tool.contenido || ""}
                                        image={tool.imagen}

                                    />
                                )
                            })
                            : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VitualTools;