import { TYPES } from '../../types';

const mainReducer = (state, action) => {
    switch(action.type){
        case TYPES.GET_MAIN:
            return {
                ...state,
                banner: action.payload.banner || null,
                words: action.payload.palabras_bienvenida || null,
                coocurriculars: action.payload.optativas || null,
                coocurricularsTitle: action.payload.titulo_cocurriculares || null,
                news: action.payload.noticias || null,
                events: action.payload.calendario.eventos || null,
                calendar: action.payload.calendario || null,
                anthemLyrics: action.payload.letras_himno || null,
                activitiesLink: action.payload.actividad_link || null,
                eventBg: action.payload.calendario.fondo_eventos || null
            }
        default:
            return state
    }
}

export default mainReducer;