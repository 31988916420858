import React from 'react';
import './centroCultural.scss';


const CurriculumPage = () => {
    return (
        <div className="subpage centro-cultural">
            <h2>CENTRO SOCIOCULTURAL BABEQUE</h2>
            <h3>Sala de Teatro Laura Bertrán</h3>
            <p>
                La Sala de Teatro Laura Bertrán es la sede de la mayoría de las actividades organizadas por el Centro Sociocultural Babeque. Fue inaugurada en el año 2009. Está ubicada en la segunda planta del Edificio A de la Secundaria Babeque, en la Avenida Roberto Pastoriza #329.

                Su estratégica y céntrica locación la hace asequible desde los diversos puntos de Santo Domingo. Tiene una capacidad para sentar 180 personas en cómodas butacas colocadas de forma escalonada, con espacio para añadir sillas adicionales hasta completar los 200 asientos. Cuenta con aire acondicionado central, luces, sonido, proyector, pantalla, telón, y perseguidor. Tiene dos baños para el público, así como también cada camerino tiene su baño integrado.

                Las canchas del colegio hacen la doble función de estacionamiento en las noches y fines de semana, ofreciendo a los asistentes un ambiente seguro.

                La sala está disponible para alquiler en los siguientes contactos:

                centrocultural@secundariababeque.edu.do

                809-381-2673
            </p>

            <h3>Galería de Arte Babeque</h3>
            <p>
                La Galería de Arte del Centro Sociocultural Babeque es un espacio para la promoción y difusión de los talentos de artes visuales, tanto para artistas profesionales como para artistas emergentes y estudiantes del colegio. Al mismo tiempo funge como un laboratorio para los estudiantes de Artes Visuales, donde se promueve el desarrollo de la competencia estética de los alumnos.

                La Galería de Arte del Centro Sociocultural Babeque está ubicada en la Av. Roberto Pastoriza #329
            </p>

            <h3>Teatro-Estudio</h3>
            <p>
                El teatro estudio fue inaugurado en el 2015, como una sala de ensayos donde tienen lugar algunas de las clases de teatro. También acoge obras cortas, performances, festivales de poesía, conversatorios y, recientemente, es la sala asignada al Cinefórum Babeque.

                Esta sala puede ser rentada, haciendo contacto con centrocultural@secundariababeque.edu.do o al teléfono 809-381-2673.
            </p>
        </div>
    );
}

export default CurriculumPage;