import React, { useReducer } from 'react';
import ActivitiesContext from './activitiesContext';
import ActivitiesReducer from './activitiesReducer';
import api from '../../../api';

import { TYPES } from '../../types';

const ActivitiesState = (props) => {

    const initialState = {
        feriaCultural: null,
        gozabeque: null,
        musical: null,
        clubes: null,
        optativas: null,
        modeloNacionesUnidas: null,
        deporte: null
    };

    const [state, dispatch] = useReducer(ActivitiesReducer, initialState);

    const getActivities = async () => {
        try {
            const result = await api.get('/actividades');
            dispatch({
                type: TYPES.GET_ACTIVITIES,
                payload: result.data.actividad
            })
        } catch (error) {

        }
    }

    return (
        <ActivitiesContext.Provider
            value={{
                feriaCultural: state.feriaCultural,
                gozabeque: state.gozabeque,
                musical: state.musical,
                clubes: state.clubes,
                optativas: state.optativas,
                modeloNacionesUnidas: state.modeloNacionesUnidas,
                deporte: state.deporte,
                state: state,
                getActivities,
            }}
        >
            {props.children}
        </ActivitiesContext.Provider>
    )
}

export default ActivitiesState;