import React, { useState, createContext } from 'react'

export const videoPlayerContext = createContext();

const VideoPlayerContext = (props) => {
    const [active, setActive] = useState(false);

    const handleActivatePlayer = () => setActive(!active);
    const handleDeactivatePlayer = () => setActive(false);

    return (
        <videoPlayerContext.Provider
            value={{
                active,
                handleActivatePlayer,
                handleDeactivatePlayer,
            }}
        >
            {props.children}
        </videoPlayerContext.Provider>
    )
}

export default VideoPlayerContext;