import React, { useEffect, useContext } from 'react';
import './philosophy.scss';
import ValueCard from './ValueCard';
import DialogContentText from '@material-ui/core/DialogContentText';
import ReactMarkdown from 'react-markdown';

// CONTEXT
import AboutusContext from '../../../../../contexts/pages/aboutus/aboutusContext';

import Slider from '../../../../Globals/Slider';

import Dropdown from '../../../../Globals/Dropdown'

import rocketIcon from '../../../../../assets/rocket.svg';
import ideaIcon from '../../../../../assets/idea.svg';
import starIcon from '../../../../../assets/star.svg';
import { baseURL } from '../../../../../api';

const Philosophy = () => {

    const aboutusContext = useContext(AboutusContext);
    const { philosophy } = aboutusContext;

    const documents = philosophy?.documentos


    const values = [
        {
            titulo: "Responsabilidad",
            contenido: "Asumimos y cumplimos con nuestros deberes y compromisos."
        },
        {
            titulo: "Respeto",
            contenido: "Reconocemos la dignidad de las personas y la igualdad de derechos tomando en cuenta la diversidad."
        },
        {
            titulo: "Honestidad",
            contenido: "Promovemos la búsqueda de la verdad, la rectitud de intención y la transparencia en las acciones."
        },
        {
            titulo: "Solidaridad",
            contenido: "Mostramos sensibilidad y empatía y colaboramos con el desarrollo de una mejor comunidad."
        },
        {
            titulo: "Integridad",
            contenido: "Buscamos la coherencia entre lo que pensamos, sentimos, decimos y hacemos."
        },
        {
            titulo: "Excelencia",
            contenido: "Mostramos sensibilidad y empatía y colaboramos con el desarrollo de una mejor comunidad."
        },
        {
            titulo: "Innovación",
            contenido: "Impulsamos la creatividad  y estamos abiertos a nuevas iniciativas para la mejora y el crecimiento institucional."
        }
    ]

    useEffect(() => {
        // console.log("ahahha", philosophy)
    }, [philosophy])
    return (
        <div className="aboutus-subpage philosophy">
            {philosophy ? (<Slider bannerData={philosophy.banner} />) : null}
            {philosophy
                ?
                <div className="philosophy-inner">
                    <div className="philosophy-title">{philosophy.titulo}</div>
                    <div className="philosophy-text">
                        {/* <DialogContentText> */}
                        <ReactMarkdown>
                            {philosophy.contenido}
                        </ReactMarkdown>
                        {/* </DialogContentText> */}

                    </div>
                    <div className="phi-item-container">
                        <img src={rocketIcon} className="phi-item-icon" />
                        <div>
                            <div className="phi-item-title">{philosophy.mision.titulo}</div>
                            <div className="phi-item-text">
                                {/* <DialogContentText> */}
                                <ReactMarkdown>
                                    {philosophy.mision.contenido}
                                </ReactMarkdown>
                                {/* </DialogContentText> */}
                            </div>
                        </div>
                    </div>
                    <div className="phi-item-container">
                        <img src={ideaIcon} className="phi-item-icon" />
                        <div>
                            <div className="phi-item-title">{philosophy.vision.titulo}</div>
                            <div className="phi-item-text">
                                {/* <DialogContentText> */}
                                <ReactMarkdown>
                                    {philosophy.vision.contenido}
                                </ReactMarkdown>
                                {/* </DialogContentText> */}
                            </div>
                        </div>
                    </div>
                    <div id="phi-item-dropdown" className="phi-item-container">
                        <img src={starIcon} className="phi-item-icon" />
                        <div>
                            <div className="phi-item-title">VALORES INSTITUCIONALES</div>
                            {/* <div className="phi-item-text">
                            <DialogContentText>
                                <ReactMarkdown>
                                    {philosophy.vision.contenido}
                                </ReactMarkdown>
                            </DialogContentText>
                            </div> */}
                            <div className="dropdown-area">
                                <Dropdown values={philosophy.valores} />
                            </div>
                        </div>
                    </div>

                    {/* <div className="philosophy-title">Perfil del egresado babeque</div> */}
                    <div className="philosophy-text">

                        <ReactMarkdown>
                            {philosophy.perfil_egresado}
                        </ReactMarkdown>

                    </div>
                    <div className='institutional-docs__container'>
                        {
                            // [
                            //     {id:1,title:"Normas de convivencia",link:""},
                            //     {id:2,title:"Perfil de egreso",link:""},
                            //     {id:3,title:"Derechos de los estudiante",link:""}
                            // ]
                            Array.from(documents)
                                .map(({ id, name, url, }) => {
                                    const title = name.replace(".pdf","")
                                    return (
                                        <a key={id} href={`${baseURL}${url}`} target="__blank" rel="noopener" style={{ width: "100%" }}>
                                            <div className='institutional-docs__item'>
                                                <span className='institutional-docs__item-content'>{title}</span>
                                            </div>

                                        </a>
                                    )
                                })
                        }
                    </div>


                </div>
                :
                <div className="philosophy-inner">
                    <div className="philosophy-title">FILOSOFÍA INSTITUCIONAL</div>
                    <div className="philosophy-text">
                        <p>
                            La Secundaria Babeque suscribe los cuatro pilares de la educación postulados por la Comisión Internacional
                            sobre la educación para el siglo XXI, presidida por Jacques Delors (1997). Estos pilares resumen la aspiración
                            máxima de la educación hoy día, expresados como grandes aprendizajes: aprender a conocer, aprender a ser,
                            aprender a hacer y aprender a vivir juntos. Los mismos van encaminados hacia la formación de un individuo
                            con capacidad de respuestas creativas en un mundo en constante transformación; un individuo que sea capaz
                            de poner en acción procesos complejos para resolver problemas y actuar con autonomía, en pro de un bienestar
                            humano basado en la solidaridad y la dignidad.

                            La educación es un proceso dirigido al desarrollo de las capacidades y talentos del ser humano para dar
                            respuesta a las necesidades de transformación propia y de su entorno. La educación debe incidir en el desarrollo
                            cognitivo, emocional, físico y espiritual de cada persona, de forma tal que lo capacite para actuar en su medio
                            con proactividad, efectividad y sentido de trascendencia. La  educación, como proceso de desarrollo y socialización
                            del individuo, busca que cada persona se plantee un proyecto de vida con responsabilidad de sí mismo y que junto a
                            otros construya proyectos para vivir plenamente y en armonía con los demás y el ambiente.
                        </p>
                    </div>
                    <div className="mission-vision row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="mission-inner">
                                <div className="mission-title">MISIÓN</div>
                                <div className="mission-text">
                                    Somos una institución educativa, sin fines de lucro, de excelencia académica y sensibilidad
                                    social, orientada al desarrollo humano integral, que con la participación del profesorado,
                                    la familia y la comunidad, forma estudiantes con liderazgo, autonomía y pensamiento reflexivo,
                                    crítico y creativo; que inciden en la sociedad a favor del bien común, respetando la diversidad
                                    y el ambiente.
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="vision-inner">
                                <div className="vision-title">VISIÓN</div>
                                <div className="vision-text">
                                    Nos visualizamos como una institución de referencia, a nivel nacional e internacional,
                                    por ofrecer y promover una educación de calidad a través de un currículo con estrategias
                                    y prácticas docentes innovadoras, donde los estudiantes desarrollen sus talentos y
                                    competencias para contribuir con una sociedad basada en valores universales.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="values">
                        <div className="values-title">VALORES INSTITUCIONALES</div>
                        <div className="values-cards-inner">
                            <ValueCard values={values} spacing={5} qty={[0, 1]} />
                            <ValueCard values={values} spacing={5} qty={[2, 3, 4]} mt="15px" />
                        </div>
                    </div>
                </div>
            }
        </div >
    )
}

export default Philosophy;