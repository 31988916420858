import React from 'react';
// import { Button } from 'react-bootstrap';

async function scrollTo(cb, ms) {
    return new Promise(resolve => {
        setTimeout(cb, ms);
    });
}

const DepartmentCard = ({ title, text, animationStates, handleDeptState,active}) => {

    const {
        setBlankState,
        adminState,
        setAdminState,
        academicBoardState,
        setAcademicBoardState,
        directiveBoardState,
        setDirectiveBoardState,
        studentsBoardState,
        setStudentsBoardState,
        humanDevState,
        setHumanDevState,
        enlaceState,
        setEnlaceState,
        eduAdminState,
        setEduAdminState,
        // physicalPlantState,
        // setPhysicalPlantState,
        technologyState,
        setTechnologyState
    } = animationStates;
    return (
        <div
            className={`deptTile ${active ? "active":""}`}
            onClick={() => {
                handleDeptState(title)
                if (title === "CONSEJO DIRECTIVO") {
                    setBlankState({ active: false, enter: false });
                    setEduAdminState({ active: false, enter: false });
                    setAcademicBoardState({ active: false, enter: false });
                    setStudentsBoardState({ active: false, enter: false });
                    setHumanDevState({ active: false, enter: false });
                    setEnlaceState({ active: false, enter: false });
                    setAdminState({ active: false, enter: false });
                    setTechnologyState({ active: false, enter: false });
                    // setPhysicalPlantState({ active: false, enter: false });
                    setDirectiveBoardState({ ...directiveBoardState, enter: true });
                    scrollTo(() => {
                        if (document.querySelector(".directors-board"))
                            document.querySelector(".directors-board").scrollIntoView({ behavior: "smooth", block: "center" })
                    }, 1200);
                }
                else if (title === "CONSEJO ACADÉMICO") {
                    setBlankState({ active: false, enter: false });
                    setEduAdminState({ active: false, enter: false });
                    setDirectiveBoardState({ active: false, enter: false });
                    setStudentsBoardState({ active: false, enter: false });
                    setHumanDevState({ active: false, enter: false });
                    setEnlaceState({ active: false, enter: false });
                    setAdminState({ active: false, enter: false });
                    setTechnologyState({ active: false, enter: false });
                    // setPhysicalPlantState({ active: false, enter: false });
                    setAcademicBoardState({ ...academicBoardState, enter: true });
                    scrollTo(() => {
                        if (document.querySelector(".academic-board"))
                            document.querySelector(".academic-board").scrollIntoView({ behavior: "smooth", block: "start" })
                    }, 1200);
                }
                if (title === "CONSEJO ESTUDIANTIL") {
                    setBlankState({ active: false, enter: false });
                    setEduAdminState({ active: false, enter: false });
                    setDirectiveBoardState({ active: false, enter: false });
                    setAcademicBoardState({ active: false, enter: false });
                    setHumanDevState({ active: false, enter: false });
                    setEnlaceState({ active: false, enter: false });
                    setAdminState({ active: false, enter: false });
                    setTechnologyState({ active: false, enter: false });
                    // setPhysicalPlantState({ active: false, enter: false });
                    setStudentsBoardState({ ...studentsBoardState, enter: true });
                    scrollTo(() => {
                        if (document.querySelector(".students-board"))
                            document.querySelector(".students-board").scrollIntoView({ behavior: "smooth", block: "start" })
                    }, 1200);
                }
                if (title === "DESARROLLO HUMANO") {
                    setBlankState({ active: false, enter: false });
                    setEduAdminState({ active: false, enter: false });
                    setDirectiveBoardState({ active: false, enter: false });
                    setAcademicBoardState({ active: false, enter: false });
                    setStudentsBoardState({ active: false, enter: false });
                    setEnlaceState({ active: false, enter: false });
                    setAdminState({ active: false, enter: false });
                    setTechnologyState({ active: false, enter: false });
                    // setPhysicalPlantState({ active: false, enter: false });
                    setHumanDevState({ ...humanDevState, enter: true });
                    scrollTo(() => {
                        if (document.querySelector(".human-dev-board"))
                            document.querySelector(".human-dev-board").scrollIntoView({ behavior: "smooth", block: "start" })
                    }, 1200);
                }
                if (title === "ENLACE INSTITUCIONAL") {
                    setBlankState({ active: false, enter: false });
                    setEduAdminState({ active: false, enter: false });
                    setDirectiveBoardState({ active: false, enter: false });
                    setAcademicBoardState({ active: false, enter: false });
                    setStudentsBoardState({ active: false, enter: false });
                    setHumanDevState({ active: false, enter: false });
                    setAdminState({ active: false, enter: false });
                    setTechnologyState({ active: false, enter: false });
                    // setPhysicalPlantState({ active: false, enter: false });
                    setEnlaceState({ ...enlaceState, enter: true });
                    scrollTo(() => {
                        if (document.querySelector(".enlace-board"))
                            document.querySelector(".enlace-board").scrollIntoView({ behavior: "smooth", block: "start" })
                    }, 1200);
                }
                if (title === "ADMINISTRACIÓN") {
                    setBlankState({ active: false, enter: false });
                    setEduAdminState({ active: false, enter: false });
                    setDirectiveBoardState({ active: false, enter: false });
                    setAcademicBoardState({ active: false, enter: false });
                    setStudentsBoardState({ active: false, enter: false });
                    setHumanDevState({ active: false, enter: false });
                    setEnlaceState({ active: false, enter: false });
                    setTechnologyState({ active: false, enter: false });
                    // setPhysicalPlantState({ active: false, enter: false });
                    setAdminState({ ...adminState, enter: true });
                    scrollTo(() => {
                        if (document.querySelector(".administrative-board"))
                            document.querySelector(".administrative-board").scrollIntoView({ behavior: "smooth", block: "start" })
                    }, 1200);
                }
                if (title === "TECNOLOGÍA") {
                    setBlankState({ active: false, enter: false });
                    setEduAdminState({ active: false, enter: false });
                    setDirectiveBoardState({ active: false, enter: false });
                    setAcademicBoardState({ active: false, enter: false });
                    setStudentsBoardState({ active: false, enter: false });
                    setHumanDevState({ active: false, enter: false });
                    setEnlaceState({ active: false, enter: false });
                    setAdminState({ active: false, enter: false });
                    // setPhysicalPlantState({ active: false, enter: false });
                    setTechnologyState({ ...technologyState, enter: true });
                    scrollTo(() => {
                        if (document.querySelector(".technology-board"))
                            document.querySelector(".technology-board").scrollIntoView({ behavior: "smooth", block: "start" })
                    }, 1200);
                }
                if (title === "GESTIÓN ESTUDIANTIL Y DOCENTE") {
                    setBlankState({ active: false, enter: false });
                    setEduAdminState({ active: false, enter: false });
                    setDirectiveBoardState({ active: false, enter: false });
                    setAcademicBoardState({ active: false, enter: false });
                    setStudentsBoardState({ active: false, enter: false });
                    setHumanDevState({ active: false, enter: false });
                    setEnlaceState({ active: false, enter: false });
                    setAdminState({ active: false, enter: false });
                    setTechnologyState({ active: false, enter: false });
                    // setPhysicalPlantState({ active: false, enter: false });
                    setEduAdminState({ ...eduAdminState, enter: true });
                    // scrollTo(() => {
                    //     if (document.querySelector(".technology-board"))
                    //         document.querySelector(".technology-board").scrollIntoView({ behavior: "smooth", block: "start" })
                    // }, 1200);
                }
                // if (title === "PLANTA FÍSICA") {
                //     setBlankState({ active: false, enter: false });
                //     setDirectiveBoardState({ active: false, enter: false });
                //     setAcademicBoardState({ active: false, enter: false });
                //     setStudentsBoardState({ active: false, enter: false });
                //     setHumanDevState({ active: false, enter: false });
                //     setEnlaceState({ active: false, enter: false });
                //     setAdminState({ active: false, enter: false });
                //     setTechnologyState({ active: false, enter: false });
                //     // setPhysicalPlantState({ ...physicalPlantState, enter: true });
                //     scrollTo(() => {
                //         if (document.querySelector(".physical-plant-board"))
                //             document.querySelector(".physical-plant-board").scrollIntoView({ behavior: "smooth", block: "start" })
                //     }, 1200);
                // }
            }}
        >
            <div className="deptTile-title">{title}</div>
        </div>
        // <div className="col-card col-lg-3 col-md-4 col-sm-6 col-xs-12">
        //     <div className="area-card-container">
        //         <div className="area-card">
        //             <div
        //                 className="front"
        //                 onClick={() => {
        //                     if (title === "CONSEJO DIRECTIVO") {
        //                         setBlankState({ active: false, enter: false });
        //                         setAcademicBoardState({ active: false, enter: false });
        //                         setStudentsBoardState({ active: false, enter: false });
        //                         setHumanDevState({ active: false, enter: false });
        //                         setEnlaceState({ active: false, enter: false });
        //                         setAdminState({ active: false, enter: false });
        //                         setTechnologyState({ active: false, enter: false });
        //                         // setPhysicalPlantState({ active: false, enter: false });
        //                         setDirectiveBoardState({ ...directiveBoardState, enter: true });
        //                         scrollTo(() => {
        //                             if (document.querySelector(".directors-board"))
        //                                 document.querySelector(".directors-board").scrollIntoView({ behavior: "smooth", block: "center" })
        //                         }, 1200);
        //                     }
        //                     else if (title === "CONSEJO ACADÉMICO") {
        //                         setBlankState({ active: false, enter: false });
        //                         setDirectiveBoardState({ active: false, enter: false });
        //                         setStudentsBoardState({ active: false, enter: false });
        //                         setHumanDevState({ active: false, enter: false });
        //                         setEnlaceState({ active: false, enter: false });
        //                         setAdminState({ active: false, enter: false });
        //                         setTechnologyState({ active: false, enter: false });
        //                         // setPhysicalPlantState({ active: false, enter: false });
        //                         setAcademicBoardState({ ...academicBoardState, enter: true });
        //                         scrollTo(() => {
        //                             if (document.querySelector(".academic-board"))
        //                                 document.querySelector(".academic-board").scrollIntoView({ behavior: "smooth", block: "start" })
        //                         }, 1200);
        //                     }
        //                     if (title === "CONSEJO ESTUDIANTIL") {
        //                         setBlankState({ active: false, enter: false });
        //                         setDirectiveBoardState({ active: false, enter: false });
        //                         setAcademicBoardState({ active: false, enter: false });
        //                         setHumanDevState({ active: false, enter: false });
        //                         setEnlaceState({ active: false, enter: false });
        //                         setAdminState({ active: false, enter: false });
        //                         setTechnologyState({ active: false, enter: false });
        //                         // setPhysicalPlantState({ active: false, enter: false });
        //                         setStudentsBoardState({ ...studentsBoardState, enter: true });
        //                         scrollTo(() => {
        //                             if (document.querySelector(".students-board"))
        //                                 document.querySelector(".students-board").scrollIntoView({ behavior: "smooth", block: "start" })
        //                         }, 1200);
        //                     }
        //                     if (title === "DESARROLLO HUMANO") {
        //                         setBlankState({ active: false, enter: false });
        //                         setDirectiveBoardState({ active: false, enter: false });
        //                         setAcademicBoardState({ active: false, enter: false });
        //                         setStudentsBoardState({ active: false, enter: false });
        //                         setEnlaceState({ active: false, enter: false });
        //                         setAdminState({ active: false, enter: false });
        //                         setTechnologyState({ active: false, enter: false });
        //                         // setPhysicalPlantState({ active: false, enter: false });
        //                         setHumanDevState({ ...humanDevState, enter: true });
        //                         scrollTo(() => {
        //                             if (document.querySelector(".human-dev-board"))
        //                                 document.querySelector(".human-dev-board").scrollIntoView({ behavior: "smooth", block: "start" })
        //                         }, 1200);
        //                     }
        //                     if (title === "ENLACE INSTITUCIONAL") {
        //                         setBlankState({ active: false, enter: false });
        //                         setDirectiveBoardState({ active: false, enter: false });
        //                         setAcademicBoardState({ active: false, enter: false });
        //                         setStudentsBoardState({ active: false, enter: false });
        //                         setHumanDevState({ active: false, enter: false });
        //                         setAdminState({ active: false, enter: false });
        //                         setTechnologyState({ active: false, enter: false });
        //                         // setPhysicalPlantState({ active: false, enter: false });
        //                         setEnlaceState({ ...enlaceState, enter: true });
        //                         scrollTo(() => {
        //                             if (document.querySelector(".enlace-board"))
        //                                 document.querySelector(".enlace-board").scrollIntoView({ behavior: "smooth", block: "start" })
        //                         }, 1200);
        //                     }
        //                     if (title === "ADMINISTRACIÓN") {
        //                         setBlankState({ active: false, enter: false });
        //                         setDirectiveBoardState({ active: false, enter: false });
        //                         setAcademicBoardState({ active: false, enter: false });
        //                         setStudentsBoardState({ active: false, enter: false });
        //                         setHumanDevState({ active: false, enter: false });
        //                         setEnlaceState({ active: false, enter: false });
        //                         setTechnologyState({ active: false, enter: false });
        //                         // setPhysicalPlantState({ active: false, enter: false });
        //                         setAdminState({ ...adminState, enter: true });
        //                         scrollTo(() => {
        //                             if (document.querySelector(".administrative-board"))
        //                                 document.querySelector(".administrative-board").scrollIntoView({ behavior: "smooth", block: "start" })
        //                         }, 1200);
        //                     }
        //                     if (title === "TECNOLOGÍA") {
        //                         setBlankState({ active: false, enter: false });
        //                         setDirectiveBoardState({ active: false, enter: false });
        //                         setAcademicBoardState({ active: false, enter: false });
        //                         setStudentsBoardState({ active: false, enter: false });
        //                         setHumanDevState({ active: false, enter: false });
        //                         setEnlaceState({ active: false, enter: false });
        //                         setAdminState({ active: false, enter: false });
        //                         // setPhysicalPlantState({ active: false, enter: false });
        //                         setTechnologyState({ ...technologyState, enter: true });
        //                         scrollTo(() => {
        //                             if (document.querySelector(".technology-board"))
        //                                 document.querySelector(".technology-board").scrollIntoView({ behavior: "smooth", block: "start" })
        //                         }, 1200);
        //                     }
        //                     // if (title === "PLANTA FÍSICA") {
        //                     //     setBlankState({ active: false, enter: false });
        //                     //     setDirectiveBoardState({ active: false, enter: false });
        //                     //     setAcademicBoardState({ active: false, enter: false });
        //                     //     setStudentsBoardState({ active: false, enter: false });
        //                     //     setHumanDevState({ active: false, enter: false });
        //                     //     setEnlaceState({ active: false, enter: false });
        //                     //     setAdminState({ active: false, enter: false });
        //                     //     setTechnologyState({ active: false, enter: false });
        //                     //     // setPhysicalPlantState({ ...physicalPlantState, enter: true });
        //                     //     scrollTo(() => {
        //                     //         if (document.querySelector(".physical-plant-board"))
        //                     //             document.querySelector(".physical-plant-board").scrollIntoView({ behavior: "smooth", block: "start" })
        //                     //     }, 1200);
        //                     // }
        //                 }}
        //             >
        //                 <div className="title">{title}</div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default DepartmentCard;