import React from 'react';
import './links.scss';

import feriaIcon from '../../../../assets/feria-icon.svg';
import deporteIcon from '../../../../assets/deporte-icon.svg';
import gozabequeIcon from '../../../../assets/gozabeque-icon.svg';
import musicalIcon from '../../../../assets/musical-icon.svg';
import cocurricular from '../../../../assets/cocurricular-icon.svg';

const Links = ({ animationStates, linkState, handleCurrentLinkState }) => {
    const {
        clubesState,
        setClubesState,
        feriaCulturalState,
        setFeriaCulturalState,
        gozabequeState,
        setGozabequeState,
        modeloNacionesUnidasState,
        setMNUState,
        musicalState,
        setMusicalState,
        optativasState,
        setOptativasState,
        deporteState,
        setDeporteState,
        blankState,
        setBlankState

    } = animationStates;

    return (
        <div className="activities-links">
            <div className="row activities-links-row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div
                        onClick={() => {
                            handleCurrentLinkState({
                                clubesLinkState: false,
                                feriaCulturalLinkState: true,
                                gozabequeLinkState: false,
                                mnuLinkState: false,
                                musicalLinkState: false,
                                optativasLinkState: false,
                                deporteLinkState:false
                            })
                            if (document.querySelector(".activity-subpage")) document.querySelector(".activity-subpage").scrollIntoView({ behavior: "smooth", block: "start" });
                            setGozabequeState({
                                active: false,
                                enter: false
                            });
                            setMusicalState({
                                active: false,
                                enter: false
                            });
                            setClubesState({
                                active: false,
                                enter: false
                            });
                            setOptativasState({
                                active: false,
                                enter: false
                            });
                            setMNUState({
                                active: false,
                                enter: false
                            });
                            setFeriaCulturalState({
                                ...feriaCulturalState,
                                enter: true
                            });
                            setDeporteState({
                                active: false,
                                enter: false
                            });
                            setBlankState({
                                active: false,
                                enter: false
                            });
                        }}
                    >
                        <div className="activities-link .hvr-bounce-to-right" style={linkState.feriaCulturalLinkState ? { background: "#fee860" } : {}}>
                            <div className="link-icon">
                                <img className="icon-svg" src={feriaIcon} style={linkState.feriaCulturalLinkState ? { filter: "invert(1) sepia(1) saturate(5) hue-rotate(175deg)" } : {}} />
                            </div>
                            <div className="link-text" style={linkState.feriaCulturalLinkState ? { color: "#0a415d" } : {}}>Feria Cultural</div>
                        </div>
                    </div>
                </div>

                <div className=".col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div
                        onClick={() => {
                            handleCurrentLinkState({
                                clubesLinkState: false,
                                feriaCulturalLinkState: false,
                                gozabequeLinkState: true,
                                mnuLinkState: false,
                                musicalLinkState: false,
                                optativasLinkState: false,
                                deporteLinkState:false
                            })
                            if (document.querySelector(".activity-subpage")) document.querySelector(".activity-subpage").scrollIntoView({ behavior: "smooth", block: "start" });
                            setFeriaCulturalState({
                                active: false,
                                enter: false
                            });
                            setMusicalState({
                                active: false,
                                enter: false
                            });
                            setClubesState({
                                active: false,
                                enter: false
                            });
                            setOptativasState({
                                active: false,
                                enter: false
                            });
                            setMNUState({
                                active: false,
                                enter: false
                            });
                            setGozabequeState({
                                ...gozabequeState,
                                enter: true
                            });
                            setDeporteState({
                                active: false,
                                enter: false
                            });
                            setBlankState({
                                active: false,
                                enter: false
                            });
                        }}
                    >
                        <div className="activities-link .hvr-bounce-to-right" style={linkState.gozabequeLinkState ? { background: "#fee860" } : {}}>
                            <div className="link-icon">
                                <img className="icon-svg" src={gozabequeIcon} style={linkState.gozabequeLinkState ? { filter: "invert(1) sepia(1) saturate(5) hue-rotate(175deg)" } : {}} />
                            </div>
                            <div className="link-text" style={linkState.gozabequeLinkState ? { color: "#0a415d" } : {}}>Gozabeque</div>
                        </div>
                    </div>
                </div>

                <div className=".col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div
                        onClick={() => {
                            handleCurrentLinkState({
                                clubesLinkState: false,
                                feriaCulturalLinkState: false,
                                gozabequeLinkState: false,
                                mnuLinkState: false,
                                musicalLinkState: true,
                                optativasLinkState: false,
                                deporteLinkState:false
                            })
                            if (document.querySelector(".activity-subpage")) document.querySelector(".activity-subpage").scrollIntoView({ behavior: "smooth", block: "start" });
                            setFeriaCulturalState({
                                active: false,
                                enter: false
                            });
                            setGozabequeState({
                                active: false,
                                enter: false
                            });
                            setClubesState({
                                active: false,
                                enter: false
                            });
                            setOptativasState({
                                active: false,
                                enter: false
                            });
                            setMNUState({
                                active: false,
                                enter: false
                            });
                            setMusicalState({
                                ...musicalState,
                                enter: true
                            });
                            setDeporteState({
                                active: false,
                                enter: false
                            });
                            setBlankState({
                                active: false,
                                enter: false
                            });
                        }}
                    >
                        <div className="activities-link .hvr-bounce-to-right" style={linkState.musicalLinkState ? { background: "#fee860" } : {}}>
                            <div className="link-icon">
                                <img className="icon-svg" src={musicalIcon} style={linkState.musicalLinkState ? { filter: "invert(1) sepia(1) saturate(5) hue-rotate(175deg)" } : {}} />
                            </div>
                            <div className="link-text" style={linkState.musicalLinkState ? { color: "#0a415d" } : {}}>Musical</div>
                        </div>
                    </div>
                </div>

                {/* <div className=".col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div
                        onClick={() => {
                            handleCurrentLinkState({
                                clubesLinkState: true,
                                feriaCulturalLinkState: false,
                                gozabequeLinkState: false,
                                mnuLinkState: false,
                                musicalLinkState: false,
                                optativasLinkState: false,
                            })
                            if (document.querySelector(".activity-subpage")) document.querySelector(".activity-subpage").scrollIntoView({ behavior: "smooth", block: "start" });
                            setFeriaCulturalState({
                                active: false,
                                enter: false
                            });
                            setGozabequeState({
                                active: false,
                                enter: false
                            });
                            setMusicalState({
                                active: false,
                                enter: false
                            });
                            setOptativasState({
                                active: false,
                                enter: false
                            });
                            setMNUState({
                                active: false,
                                enter: false
                            });
                            setClubesState({
                                ...clubesState,
                                enter: true
                            });
                            setBlankState({
                                active: false,
                                enter: false
                            });
                        }}
                    >
                        <div className="activities-link .hvr-bounce-to-right" style={linkState.clubesLinkState ? { background: "#fee860" } : {}}>
                            <div className="link-icon">
                                <img className="icon-svg" src={musicalIcon} style={linkState.clubesLinkState ?{ filter: "invert(1) sepia(1) saturate(5) hue-rotate(175deg)" } : {}}/>
                            </div>
                            <div className="link-text" style={linkState.clubesLinkState ? { color: "#0a415d" } : {}}>Clubes</div>
                        </div>
                    </div>
                </div> */}

                <div className=".col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div
                        onClick={() => {
                            handleCurrentLinkState({
                                clubesLinkState: false,
                                feriaCulturalLinkState: false,
                                gozabequeLinkState: false,
                                mnuLinkState: false,
                                musicalLinkState: false,
                                optativasLinkState: true,
                                deporteLinkState:false
                            })
                            if (document.querySelector(".activity-subpage")) document.querySelector(".activity-subpage").scrollIntoView({ behavior: "smooth", block: "start" });
                            setFeriaCulturalState({
                                active: false,
                                enter: false
                            });
                            setGozabequeState({
                                active: false,
                                enter: false
                            });
                            setMusicalState({
                                active: false,
                                enter: false
                            });
                            setClubesState({
                                active: false,
                                enter: false
                            });
                            setMNUState({
                                active: false,
                                enter: false
                            });
                            setOptativasState({
                                ...optativasState,
                                enter: true
                            });
                            setDeporteState({
                                active: false,
                                enter: false
                            });
                            setBlankState({
                                active: false,
                                enter: false
                            });
                        }}
                    >
                        <div className="activities-link" style={linkState.optativasLinkState ? { background: "#fee860" } : {}}>
                            <div className="link-icon">
                            <img className="icon-svg" src={cocurricular} style={linkState.optativasLinkState ?{ filter: "invert(1) sepia(1) saturate(5) hue-rotate(175deg)" } : {}}/>
                            </div>
                            <div className="link-text" style={linkState.optativasLinkState ? { color: "#0a415d" } : {}}>Electivas</div>
                        </div>
                    </div>
                </div>

                <div className=".col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div
                        onClick={() => {
                            handleCurrentLinkState({
                                clubesLinkState: false,
                                feriaCulturalLinkState: false,
                                gozabequeLinkState: false,
                                mnuLinkState: false,
                                musicalLinkState: false,
                                optativasLinkState: false,
                                deporteLinkState:true
                            })
                            if (document.querySelector(".activity-subpage")) document.querySelector(".activity-subpage").scrollIntoView({ behavior: "smooth", block: "start" });
                            setFeriaCulturalState({
                                active: false,
                                enter: false
                            });
                            setGozabequeState({
                                active: false,
                                enter: false
                            });
                            setMusicalState({
                                active: false,
                                enter: false
                            });
                            setClubesState({
                                active: false,
                                enter: false
                            });
                            setMNUState({
                                active: false,
                                enter: false
                            });
                            setDeporteState({
                                ...deporteState,
                                enter: true
                            });
                            setOptativasState({
                                active: false,
                                enter: false
                            });
                            setBlankState({
                                active: false,
                                enter: false
                            });
                        }}
                    >
                        <div className="activities-link" style={linkState.deporteLinkState ? { background: "#fee860" } : {}}>
                            <div className="link-icon">
                            <img className="icon-svg" src={deporteIcon} style={linkState.deporteLinkState ?{ filter: "invert(1) sepia(1) saturate(5) hue-rotate(175deg)" } : {}}/>
                            </div>
                            <div className="link-text" style={linkState.deporteLinkState ? { color: "#0a415d" } : {}}>Deporte</div>
                        </div>
                    </div>
                </div>

                {/* <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div
                        onClick={() => {
                            handleCurrentLinkState({
                                clubesLinkState: false,
                                feriaCulturalLinkState: false,
                                gozabequeLinkState: false,
                                mnuLinkState: true,
                                musicalLinkState: false,
                                optativasLinkState: false,
                            })
                            if (document.querySelector(".activity-subpage")) document.querySelector(".activity-subpage").scrollIntoView({ behavior: "smooth", block: "start" });
                            setFeriaCulturalState({
                                active: false,
                                enter: false
                            });
                            setGozabequeState({
                                active: false,
                                enter: false
                            });
                            setMusicalState({
                                active: false,
                                enter: false
                            });
                            setClubesState({
                                active: false,
                                enter: false
                            });
                            setOptativasState({
                                active: false,
                                enter: false
                            });
                            setMNUState({
                                ...modeloNacionesUnidasState,
                                enter: true
                            });
                            setBlankState({
                                active: false,
                                enter: false
                            });
                        }}
                    >
                        <div className="activities-link" style={linkState.mnuLinkState ? { background: "#fee860" } : {}}>
                            <div className="link-icon">
                            <img className="icon-svg" src={feriaIcon} style={linkState.mnuLinkState ?{ filter: "invert(1) sepia(1) saturate(5) hue-rotate(175deg)" } : {}}/>
                            </div>
                            <div className="link-text" style={linkState.mnuLinkState ? { color: "#0a415d" } : {}}>Modelo Naciones Unidas</div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Links;