import React from 'react';

const Exit = ({ setState }) => (
    <div className="exit-arrow" onClick={
        () => setState({
            enter: false,
            active: false
        })}>
        <i className="fas fa-home"></i>
    </div>
)

export default Exit;