import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// import imgLogo from '../../assets/logo-csb-inv-200x155.png';
import imgLogo from '../../assets/logo-csb-1024x673.png';

const LogoImg = styled.div`
    width: 75px;
    height: 55px;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
    }
    
`;

const LogoText = styled.div`
    font-size: 16px;
    margin: 0;
    color: #0a415d;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .navbar-title{
        font-size: 0.9rem;
        font-weight: bold;
    }
    .navbar-motto{
        font-size: 10px;
        font-weight: lighter;
        margin-bottom: 0px;
    }
`;

const Logo = ({ setOpen }) => {

    const handleCloseMenu = () => {
        setTimeout(() => { setOpen(false) }, 100);
    };

    return (
        <Fragment>
            <LogoImg>
                <Link
                    to="/"
                    onClick={() => { handleCloseMenu() }}
                ><img src={imgLogo} alt="CSB logo" /></Link>
            </LogoImg>
            <LogoText>
                <h1 className="navbar-title">Colegio Secundaria Babeque</h1>
                <p className="navbar-motto">- por el desarrollo humano -</p>
            </LogoText>
        </Fragment>
    );
}

export default Logo;