import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Accessibility from './Accessibility';
import MenuToggle from './MenuToggle';

// import MainContext from '../../contexts/pages/main/mainContext';

// const admisiones = "https://babeque.azurewebsites.net/babequecid/adm_home.aspx"
const admisiones = "https://admisiones.secundariababeque.edu.do/"

const NavLinksContainer = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
`;

const LinksWrapper = styled.ul`
	margin: 0;
	padding: 0;
	display: flex;
	height: 100%;
	list-style: none;
	// background-color: #fff;
	background: rgba(255, 255, 255, 0.9);
	width: 100%;
	flex-direction: column;
	position: fixed;
	top: 10vh;
	left: 0;
	z-index: 11;
`;

const LinkItem = styled.li`
	width: 100%;
	padding: 0 1.1em;
	color: #0a415d;;
	font-weight: 500;
	font-size: 16px;
	display: flex;
`;

const Marginer = styled.div`
	height: 2em;
`;
const MarginerLine = styled.div`
	margin: 10px 0;
	height: 2px;
	width: 100%;
	background-color: #0a415d;;
`;

const MobileNavLinks = ({ isOpen, setOpen }) => {

	// const { activitiesLink } = useContext(MainContext)
	const activitiesLink = "https://centrocultural.secundariababeque.edu.do/"

	const handleCloseMenu = () => {
		setTimeout(() => { setOpen(false) }, 100);
	};

	return (
		<NavLinksContainer>
			<MenuToggle isOpen={isOpen} toggle={() => setOpen(!isOpen)} />
			{isOpen && (
				<LinksWrapper>
					<Accessibility handleCloseMenu={handleCloseMenu} />
					<MarginerLine />
					<Link to="/conocenos" onClick={() => { handleCloseMenu() }}>
						<LinkItem>CONOCENOS</LinkItem>
					</Link>
					<MarginerLine />
					<Link to="/curriculum" onClick={() => { handleCloseMenu() }}>
						<LinkItem>CURRICULUM</LinkItem>
					</Link>
					<MarginerLine />
					<Link to="/curriculum/bachillerato-ib" onClick={() => { handleCloseMenu() }}>
						<LinkItem>
							PROGRAMA IB
						</LinkItem>
					</Link>
					<MarginerLine />
					{/* <Link to="/" onClick={() => { handleCloseMenu() }}>ADMISIONES</Link> */}
					<a href={admisiones} target="_blank" rel="noopener" >
						<LinkItem>
							ADMISIONES
						</LinkItem>
					</a>
					<MarginerLine />
					{/* <Link to="/actividades" onClick={() => { handleCloseMenu() }}>ACTIVIDADES</Link> */}
					<Link to="/actividades" onClick={() => { handleCloseMenu() }}>
						<LinkItem>
							ACTIVIDADES
						</LinkItem>
					</Link>
					<MarginerLine />
					<a href={`${activitiesLink ?? ""}`} target="_blank" rel="noopener">
						<LinkItem>
							<h6>CENTRO SOCIOCULTURAL BABEQUE</h6>
							{/* <Link to="/centro-cultural" onClick={() => { handleCloseMenu() }}>CENTRO CULTURAL BABEQUE</Link> */}
						</LinkItem>
					</a>
					<MarginerLine />
					<Link to="/contactanos" onClick={() => { handleCloseMenu() }}>
						<LinkItem>
							CONTÁCTANOS
						</LinkItem>
					</Link>
					<MarginerLine />
					<a href="https://moodle.secundariababeque.edu.do/" target="_blank" rel="noopener">
						<LinkItem>
							MOODLE
						</LinkItem>
					</a>
					<MarginerLine />
					<a href="https://secundariababeque.powerschool.com/public/home.html" target="_blank" rel="noopener" >
						<LinkItem>
							POWERSCHOOL
						</LinkItem>
					</a>
					<MarginerLine />
					<Marginer />
				</LinksWrapper>
			)}
		</NavLinksContainer>
	);
}

export default MobileNavLinks;
