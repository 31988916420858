import React, { useContext } from 'react';
import './links.scss';

import philosophyIcon from '../../../../assets/philosophy.svg';
import commitmentIcon from '../../../../assets/commitment.svg';
import departmentsIcon from '../../../../assets/departments.svg';
import partnersIcon from '../../../../assets/partners.svg';
import vToolsIcon from '../../../../assets/virtualtools.svg';
import vTourIcon from '../../../../assets/virtualtour.svg';
// import commitmentIcon from '../../../../assets/commtment.svg';
// CONTEXT
import AboutusContext from '../../../../contexts/pages/aboutus/aboutusContext';
import { videoPlayerContext } from '../../../../contexts/helpers/videoPlayer/videoPlayerContext';

async function scrollTo(cb, ms) {
    return new Promise(resolve => {
        setTimeout(cb, ms);
    });
}

const Links = ({ animationStates, linkState, handleCurrentLinkState }) => {

    const aboutusContext = useContext(AboutusContext);
    const { updateAboutusBanner } = aboutusContext;

    const VideoPlayerContext = useContext(videoPlayerContext);
    const { handleActivatePlayer, handleDeactivatePlayer } = VideoPlayerContext;

    const {
        setHistoryState,
        philosophyState,
        setPhilosophyState,
        commitmentState,
        setCommitmentState,
        alliesState,
        setAlliesState,
        virtualToolsState,
        setVirtualToolsState,
        virtualTourState,
        setVirtualTourState,
        departmentsState,
        setDepartmentsState
    } = animationStates;
    // console.log(linkState)

    return (
        <div className="aboutus-links">
            <div className="row aboutus-links-row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div
                        onClick={() => {
                            handleCurrentLinkState({
                                historyLinkState: false,
                                philosophyLinkState: true,
                                commitmentLinkState: false,
                                virtualToolsLinkState: false,
                                virtualTourLinkState: false,
                                departmentsLinkState: false,
                                alliesState:false
                            })
                            updateAboutusBanner('philosophy')
                            if (document.querySelector(".aboutus-body")) document.querySelector(".aboutus-body").scrollIntoView({ behavior: "smooth", block: "start" });
                            setHistoryState({
                                active: false,
                                enter: false
                            });
                            setDepartmentsState({
                                active: false,
                                enter: false
                            });
                            setCommitmentState({
                                active: false,
                                enter: false
                            });
                            setVirtualToolsState({
                                active: false,
                                enter: false
                            });
                            setVirtualTourState({
                                active: false,
                                enter: false
                            });
                            setAlliesState({
                                active: false,
                                enter: false
                            });
                            setPhilosophyState({
                                ...philosophyState,
                                enter: true
                            });
                            handleDeactivatePlayer()
                        }}
                    >
                        <div className="aboutus-link" style={linkState.philosophyLinkState ? { background: "#fee860" } : {}}>
                            <div className="link-icon">
                                <img className="icon-svg" src={philosophyIcon} style={linkState.philosophyLinkState ? { filter: "invert(1) sepia(1) saturate(5) hue-rotate(175deg)" } : {}} />
                            </div>
                            <div className="link-text" style={linkState.philosophyLinkState ? { color: "#0a415d" } : {}}>Filosofía Institucional</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div
                        onClick={() => {
                            handleCurrentLinkState({
                                historyLinkState: false,
                                philosophyLinkState: false,
                                commitmentLinkState: true,
                                virtualToolsLinkState: false,
                                virtualTourLinkState: false,
                                departmentsLinkState: false,
                                alliesState:false
                            })
                            handleDeactivatePlayer()
                            updateAboutusBanner('commitment')
                            if (document.querySelector(".aboutus-body")) document.querySelector(".aboutus-body").scrollIntoView({ behavior: "smooth", block: "start" });
                            setHistoryState({
                                active: false,
                                enter: false
                            });
                            setDepartmentsState({
                                active: false,
                                enter: false
                            });
                            setPhilosophyState({
                                active: false,
                                enter: false
                            });
                            setVirtualToolsState({
                                active: false,
                                enter: false
                            });
                            setVirtualTourState({
                                active: false,
                                enter: false
                            });
                            setAlliesState({
                                active: false,
                                enter: false
                            });
                            setCommitmentState({
                                ...commitmentState,
                                enter: true
                            });
                        }}
                    >
                        <div className="aboutus-link" style={linkState.commitmentLinkState ? { background: "#fee860" } : {}}>
                            <div className="link-icon">
                                {/* <i className="fas fa-globe-americas"></i> */}
                                <img className="icon-svg" src={commitmentIcon} style={linkState.commitmentLinkState ? { filter: "invert(1) sepia(1) saturate(5) hue-rotate(175deg)" } : {}} />
                            </div>
                            <div className="link-text" style={linkState.commitmentLinkState ? { color: "#0a415d" } : {}}>Compromiso Social</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div
                        onClick={() => {
                            handleCurrentLinkState({
                                historyLinkState: false,
                                philosophyLinkState: false,
                                commitmentLinkState: false,
                                virtualToolsLinkState: false,
                                virtualTourLinkState: false,
                                departmentsLinkState: true,
                                alliesState:false
                            })
                            handleDeactivatePlayer()
                            updateAboutusBanner('departments')
                            if (document.querySelector(".aboutus-body")) document.querySelector(".aboutus-body").scrollIntoView({ behavior: "smooth", block: "start" });
                            setHistoryState({
                                active: false,
                                enter: false
                            });
                            setPhilosophyState({
                                active: false,
                                enter: false
                            });
                            setCommitmentState({
                                active: false,
                                enter: false
                            });
                            setVirtualToolsState({
                                active: false,
                                enter: false
                            });
                            setVirtualTourState({
                                active: false,
                                enter: false
                            });
                            setAlliesState({
                                active: false,
                                enter: false
                            });
                            setDepartmentsState({
                                ...departmentsState,
                                enter: true
                            });
                        }}
                    >
                        <div className="aboutus-link" style={linkState.departmentsLinkState ? { background: "#fee860" } : {}}>
                            <div className="link-icon">
                                {/* <i className="fas fa-sitemap"></i> */}
                                <img className="icon-svg" src={departmentsIcon} style={linkState.departmentsLinkState ? { filter: "invert(1) sepia(1) saturate(5) hue-rotate(175deg)" } : {}} />
                            </div>
                            <div className="link-text" style={linkState.departmentsLinkState ? { color: "#0a415d" } : {}}>Organismos y Departamentos</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row aboutus-links-row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <div
                    onClick={() => {
                            handleCurrentLinkState({
                                historyLinkState: false,
                                philosophyLinkState: false,
                                commitmentLinkState: false,
                                alliesLinkState: true,
                                virtualToolsLinkState: false,
                                virtualTourLinkState: false,
                                departmentsLinkState: false,
                            })
                            handleDeactivatePlayer()
                            updateAboutusBanner('allies')
                            if (document.querySelector(".aboutus-body")) document.querySelector(".aboutus-body").scrollIntoView({ behavior: "smooth", block: "start" });
                            setHistoryState({
                                active: false,
                                enter: false
                            });
                            setDepartmentsState({
                                active: false,
                                enter: false
                            });
                            setCommitmentState({
                                active: false,
                                enter: false
                            });
                            setPhilosophyState({
                                active: false,
                                enter: false
                            });
                            setVirtualTourState({
                                active: false,
                                enter: false
                            });
                            setVirtualToolsState({
                                active: false,
                                enter: false
                            });
                            setAlliesState({
                                ...alliesState,
                                enter: true
                            });
                        }}
                    >

                    
<div className="aboutus-link" style={linkState.alliesLinkState ? { background: "#fee860" } : {}}>
                            <div className="link-icon">
                                {/* <i className="fas fa-cogs"></i> */}
                                <img className="icon-svg" src={partnersIcon} style={linkState.alliesLinkState ? { filter: "invert(1) sepia(1) saturate(5) hue-rotate(175deg)" } : {}} />
                            </div>
                            <div className="link-text"  style={linkState.alliesLinkState ? { color: "#0a415d" } : {}}>Aliados y Acreditaciones</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div
                        onClick={() => {
                            handleCurrentLinkState({
                                historyLinkState: false,
                                philosophyLinkState: false,
                                commitmentLinkState: false,
                                virtualToolsLinkState: true,
                                virtualTourLinkState: false,
                                departmentsLinkState: false,
                                alliesState:false
                            })
                            handleDeactivatePlayer()
                            updateAboutusBanner('virtualTools')
                            if (document.querySelector(".aboutus-body")) document.querySelector(".aboutus-body").scrollIntoView({ behavior: "smooth", block: "start" });
                            setHistoryState({
                                active: false,
                                enter: false
                            });
                            setDepartmentsState({
                                active: false,
                                enter: false
                            });
                            setCommitmentState({
                                active: false,
                                enter: false
                            });
                            setPhilosophyState({
                                active: false,
                                enter: false
                            });
                            setVirtualTourState({
                                active: false,
                                enter: false
                            });
                            setAlliesState({
                                active: false,
                                enter: false
                            });
                            setVirtualToolsState({
                                ...virtualToolsState,
                                enter: true
                            });
                        }}
                    >
                        <div className="aboutus-link" style={linkState.virtualToolsLinkState ? { background: "#fee860" } : {}}>
                            <div className="link-icon">
                                {/* <i className="fas fa-cogs"></i> */}
                                <img className="icon-svg" src={vToolsIcon} style={linkState.virtualToolsLinkState ? { filter: "invert(1) sepia(1) saturate(5) hue-rotate(175deg)" } : {}} />
                            </div>
                            <div className="link-text"  style={linkState.virtualToolsLinkState ? { color: "#0a415d" } : {}}>Herramientas Virtuales</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div
                        onClick={() => {
                            handleCurrentLinkState({
                                historyLinkState: false,
                                philosophyLinkState: false,
                                commitmentLinkState: false,
                                virtualToolsLinkState: false,
                                virtualTourLinkState: true,
                                departmentsLinkState: false,
                                alliesState:false
                            })
                            handleActivatePlayer()
                            scrollTo(() => {
                                if (document.querySelector(".video-player-wrapper"))
                                    document.querySelector(".video-player-wrapper").scrollIntoView({ behavior: "smooth", block: "center" })
                            }, 1200);
                            setHistoryState({
                                active: false,
                                enter: false
                            });
                            setDepartmentsState({
                                active: false,
                                enter: false
                            });
                            setPhilosophyState({
                                active: false,
                                enter: false
                            });
                            setCommitmentState({
                                active: false,
                                enter: false
                            });
                            setVirtualToolsState({
                                active: false,
                                enter: false
                            });
                            setAlliesState({
                                active: false,
                                enter: false
                            });
                            setVirtualTourState({
                                ...virtualTourState,
                                enter: true
                            });
                        }}
                    >
                        <div className="aboutus-link" style={linkState.virtualTourLinkState ? { background: "#fee860" } : {}}>
                            <div className="link-icon">
                                {/* <i className="fas fa-video"></i> */}
                                <img className="icon-svg" src={vTourIcon} style={linkState.virtualTourLinkState ? { filter: "invert(1) sepia(1) saturate(5) hue-rotate(175deg)" } : {}} />
                            </div>
                            <div className="link-text" style={linkState.virtualTourLinkState ? { color: "#0a415d" } : {}}>Tour Virtual</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Links;