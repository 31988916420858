import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        height: 200,
        width: 200,
    },
    control: {
        padding: theme.spacing(2),
    },
}));

const iconsOptions = (option) => {
    switch(option){
        case 0:
            return <i className="fas fa-praying-hands"></i>
        case 1:
            return <i className="fas fa-handshake"></i>
        case 2:
            return <i className="fas fa-hand-sparkles"></i>
        case 3:
            return <i className="fas fa-hands"></i>
        case 4:
            return <i className="fas fa-medal"></i>
        default:
            return <i className="fas fa-praying-hands"></i>
    }
}

const ValueCard = ({ spacing, values, qty = [0], mt = "0px" }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.root} spacing={2} style={{ marginTop: mt }}>
            <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={spacing}>
                    {qty.map((value,index) => (
                        <Grid key={index} item>
                            <Paper className={classes.paper} >
                                {/* <Image src={image[value]} atl={value} /> */}
                                <div className="value-card-container">
                                    <div className="value-card">
                                        <div className={`front color-${index}`}>
                                            <div className="icon">{iconsOptions(index)}</div>
                                            <div className="title">{values[index].titulo}</div>
                                        </div>
                                        <div className={`back color-${index}`}>
                                            {values[index].contenido}
                                        </div>
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ValueCard;