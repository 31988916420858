import React, { useReducer, useState } from 'react';
import CurriculumContext from './curriculumContext';
import CurriculumReducer from './curriculumReducer';
import api from '../../../api';

import { TYPES } from '../../types';

const CurriculumState = (props) => {

    const initialState = {
        banner: null,
        description: null,
        subjects: null,
        evaluation: null,
        ibBachelor: null,
    }

    const subjectsList = [
        "LENGUA ESPAÑOLA Y LITERATURA",
        "LENGUAS EXTRANJERAS",
        "MATEMÁTICAS",
        "CIENCIAS SOCIALES",
        "CIENCIAS DE LA NATURALEZA",
        "EDUCACIÓN ARTÍSTICA",
        "EDUCACIÓN FÍSICA",
        "FORMACIÓN INTEGRAL HUMANA Y RELIGIOSA",
        // "INFORMÁTICA"
    ]

    const [dotsLoaded, setDotsLoaded] = useState(false);

    const [state, dispatch] = useReducer(CurriculumReducer, initialState);

    //FETCH ALL CURRICULUM PAGE DATA FROM API
    const getCurriculum = async () => {
        try {
            const result = await api.get('/curriculum');
            dispatch({
                type: TYPES.GET_CURRICULUM,
                payload: result.data
            })
        } catch (error) {
            
        }
    }

    return(
        <CurriculumContext.Provider
            value={{
                banner: state.banner,
                description: state.description,
                subjects: state.subjects,
                evaluation: state.evaluation,
                ibBachelor: state.ibBachelor,
                subjectsList,
                dotsLoaded,
                setDotsLoaded,
                getCurriculum,
            }}
        >
            {props.children}
        </CurriculumContext.Provider>
    )

}

export default CurriculumState;