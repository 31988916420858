import React, { useState, Fragment, useContext, useEffect } from 'react';
import './departments.scss';
import { CSSTransition } from 'react-transition-group';
import DepartmentCard from './DepartmentCard';
import ConsejoDirectivo from './Areas/ConsejoDirectivo';
import ConsejoAcademico from './Areas/ConsejoAcademico';
import ConsejoEstudiantil from './Areas/ConsejoEstudiantil';
import Administracion from './Areas/Administracion';
import AdministracionDocente from './Areas/AdministracionDocente';
import DesarrolloHumano from './Areas/DesarrolloHumano';
import Enlace from './Areas/Enlace';
// import PlantaFisica from './Areas/PlantaFisica';
import Tecnologia from './Areas/Tecnologia';

// CONTEXT
import AboutusContext from '../../../../../contexts/pages/aboutus/aboutusContext';
import AboutusDeparmentsContext from '../../../../../contexts/pages/aboutus/departments/departmentsContext';

import Slider from '../../../../Globals/Slider';

const Departments = () => {

    const aboutusContext = useContext(AboutusContext)
    const { departments} = aboutusContext;

    const departmentsContext = useContext(AboutusDeparmentsContext)
    const {
        directiveBoard,
        academicBoard,
        studentsBoard} = departmentsContext;

    // States from switching between departments
    const [blankState, setBlankState] = useState({
        enter: false,
        active: true
    });
    const [adminState, setAdminState] = useState({
        enter: false,
        active: false
    });
    const [academicBoardState, setAcademicBoardState] = useState({
        enter: false,
        active: false
    });
    const [directiveBoardState, setDirectiveBoardState] = useState({
        enter: false,
        active: false
    });
    const [studentsBoardState, setStudentsBoardState] = useState({
        enter: false,
        active: false
    });
    const [humanDevState, setHumanDevState] = useState({
        enter: false,
        active: false
    });
    const [enlaceState, setEnlaceState] = useState({
        enter: false,
        active: false
    });
    // const [physicalPlantState, setPhysicalPlantState] = useState({
    //     enter: false,
    //     active: false
    // });
    const [eduAdminState, setEduAdminState] = useState({
        enter: false,
        active: false
    });
    const [technologyState, setTechnologyState] = useState({
        enter: false,
        active: false
    });

    const animationStates = {
        blankState,
        setBlankState,
        adminState,
        setAdminState,
        academicBoardState,
        setAcademicBoardState,
        directiveBoardState,
        setDirectiveBoardState,
        studentsBoardState,
        setStudentsBoardState,
        humanDevState,
        setHumanDevState,
        enlaceState,
        setEnlaceState,
        eduAdminState,
        setEduAdminState,
        // physicalPlantState,
        // setPhysicalPlantState,
        technologyState,
        setTechnologyState
    };

    useEffect(() => {
    }, [departments])

 
    const deptBanner = [
        directiveBoard,
        academicBoard,
        studentsBoard
    ].map((item)=>{
       if (Array.isArray(item.imagen)) {
        
           return item.imagen[0]
       } else if (!item.imagen.isArray) {
           
           return item.imagen
       } else {
           return {}
       }
    })
    const [deptState, setDeptState] = useState("");

    const handleDeptState = (id) => {
        setDeptState(id)
    }
    return (
        <div className="aboutus-subpage departments">
            {departmentsContext ? (<Slider bannerData={deptBanner} />) : null}
            <div className="departments-inner">
                <div className="departments-title">ORGANISMOS Y DEPARTAMENTOS</div>
                <div className="areas">
                    <div className="row-dept">
                        {departments
                            ?
                            departments.map(department => {
                                if (department.titulo.toUpperCase() === "PLANTA FÍSICA") return null
                                
                                return (
                                    <DepartmentCard
                                        active={department.titulo === deptState}
                                        handleDeptState={()=>handleDeptState(department.titulo)}
                                        key={department._id}
                                        animationStates={animationStates}
                                        title={String(department.titulo).toUpperCase() || ""}
                                        text={department.contenido || ""}
                                    />
                                )
                            })
                            : null
                        }
                    </div>
                </div>
            </div>
            <CSSTransition
                in={blankState.active}
                timeout={500}
                classNames="blank-space"
                unmountOnExit
                onEnter={() => {
                    setAdminState({ enter: false, active: false });
                    setEduAdminState({enter:false,active: false});
                    setDirectiveBoardState({ enter: false, active: false });
                    setAcademicBoardState({ enter: false, active: false });
                    setStudentsBoardState({ enter: false, active: false });
                    setHumanDevState({ enter: false, active: false });
                    setEnlaceState({ enter: false, active: false });
                    // setPhysicalPlantState({ enter: false, active: false });
                    setTechnologyState({ enter: false, active: false });
                }}
                onExited={() => {
                    if (adminState.enter) setAdminState({ ...adminState, active: true });
                    if (eduAdminState.enter) setEduAdminState({ ...eduAdminState, active: true });
                    if (directiveBoardState.enter) setDirectiveBoardState({ ...directiveBoardState, active: true });
                    if (academicBoardState.enter) setAcademicBoardState({ ...academicBoardState, active: true });
                    if (studentsBoardState.enter) setStudentsBoardState({ ...studentsBoardState, active: true });
                    if (humanDevState.enter) setHumanDevState({ ...humanDevState, active: true });
                    if (enlaceState.enter) setEnlaceState({ ...enlaceState, active: true });
                    // if (physicalPlantState.enter) setPhysicalPlantState({ ...physicalPlantState, active: true });
                    if (technologyState.enter) setTechnologyState({ ...technologyState, active: true });
                }}
            >
                <Fragment>
                    <div></div>
                </Fragment>
            </CSSTransition>

            <CSSTransition
                in={directiveBoardState.active}
                timeout={500}
                classNames="directive-board-dpt"
                unmountOnExit
                onEnter={() => {
                    setBlankState({ enter: false, active: false });
                    setAdminState({ enter: false, active: false });
                    setEduAdminState({enter:false,active: false});
                    setAcademicBoardState({ enter: false, active: false });
                    setStudentsBoardState({ enter: false, active: false });
                    setHumanDevState({ enter: false, active: false });
                    setEnlaceState({ enter: false, active: false });
                    // setPhysicalPlantState({ enter: false, active: false });
                    setTechnologyState({ enter: false, active: false });
                }}
                onExited={() => {
                    if (blankState.enter) setBlankState({ ...blankState, active: true });
                    if (adminState.enter) setAdminState({ ...adminState, active: true });
                    if (eduAdminState.enter) setEduAdminState({ ...eduAdminState, active: true });
                    if (academicBoardState.enter) setAcademicBoardState({ ...academicBoardState, active: true });
                    if (studentsBoardState.enter) setStudentsBoardState({ ...studentsBoardState, active: true });
                    if (humanDevState.enter) setHumanDevState({ ...humanDevState, active: true });
                    if (enlaceState.enter) setEnlaceState({ ...enlaceState, active: true });
                    // if (physicalPlantState.enter) setPhysicalPlantState({ ...physicalPlantState, active: true });
                    if (technologyState.enter) setTechnologyState({ ...technologyState, active: true });
                }}
            >
                <ConsejoDirectivo />
            </CSSTransition>

            <CSSTransition
                in={academicBoardState.active}
                timeout={500}
                classNames="academic-board-dpt"
                unmountOnExit
                onEnter={() => {
                    setBlankState({ enter: false, active: false });
                    setAdminState({ enter: false, active: false });
                    setEduAdminState({enter:false,active: false});
                    setDirectiveBoardState({ enter: false, active: false });
                    setStudentsBoardState({ enter: false, active: false });
                    setHumanDevState({ enter: false, active: false });
                    setEnlaceState({ enter: false, active: false });
                    // setPhysicalPlantState({ enter: false, active: false });
                    setTechnologyState({ enter: false, active: false });
                }}
                onExited={() => {
                    if (blankState.enter) setBlankState({ ...blankState, active: true });
                    if (adminState.enter) setAdminState({ ...adminState, active: true });
                    if (eduAdminState.enter) setEduAdminState({ ...eduAdminState, active: true });
                    if (directiveBoardState.enter) setDirectiveBoardState({ ...directiveBoardState, active: true });
                    if (studentsBoardState.enter) setStudentsBoardState({ ...studentsBoardState, active: true });
                    if (humanDevState.enter) setHumanDevState({ ...humanDevState, active: true });
                    if (enlaceState.enter) setEnlaceState({ ...enlaceState, active: true });
                    // if (physicalPlantState.enter) setPhysicalPlantState({ ...physicalPlantState, active: true });
                    if (technologyState.enter) setTechnologyState({ ...technologyState, active: true });
                }}
            >
                <ConsejoAcademico />
            </CSSTransition>

            <CSSTransition
                in={studentsBoardState.active}
                timeout={500}
                classNames="students-board-dpt"
                unmountOnExit
                onEnter={() => {
                    setBlankState({ enter: false, active: false });
                    setAdminState({ enter: false, active: false });
                    setEduAdminState({enter:false,active: false});
                    setDirectiveBoardState({ enter: false, active: false });
                    setAcademicBoardState({ enter: false, active: false });
                    setHumanDevState({ enter: false, active: false });
                    setEnlaceState({ enter: false, active: false });
                    // setPhysicalPlantState({ enter: false, active: false });
                    setTechnologyState({ enter: false, active: false });
                }}
                onExited={() => {
                    if (blankState.enter) setBlankState({ ...blankState, active: true });
                    if (adminState.enter) setAdminState({ ...adminState, active: true });
                    if (eduAdminState.enter) setEduAdminState({ ...eduAdminState, active: true });
                    if (directiveBoardState.enter) setDirectiveBoardState({ ...directiveBoardState, active: true });
                    if (academicBoardState.enter) setAcademicBoardState({ ...academicBoardState, active: true });
                    if (humanDevState.enter) setHumanDevState({ ...humanDevState, active: true });
                    if (enlaceState.enter) setEnlaceState({ ...enlaceState, active: true });
                    // if (physicalPlantState.enter) setPhysicalPlantState({ ...physicalPlantState, active: true });
                    if (technologyState.enter) setTechnologyState({ ...technologyState, active: true });
                }}
            >
                <ConsejoEstudiantil />
            </CSSTransition>

            <CSSTransition
                in={adminState.active}
                timeout={500}
                classNames="administrative-board-dpt"
                unmountOnExit
                onEnter={() => {
                    setBlankState({ enter: false, active: false });
                    setEduAdminState({enter:false,active: false});
                    setStudentsBoardState({ enter: false, active: false });
                    setDirectiveBoardState({ enter: false, active: false });
                    setAcademicBoardState({ enter: false, active: false });
                    setHumanDevState({ enter: false, active: false });
                    setEnlaceState({ enter: false, active: false });
                    // setPhysicalPlantState({ enter: false, active: false });
                    setTechnologyState({ enter: false, active: false });
                }}
                onExited={() => {
                    if (blankState.enter) setBlankState({ ...blankState, active: true });
                    if (eduAdminState.enter) setEduAdminState({ ...eduAdminState, active: true });
                    if (studentsBoardState.enter) setStudentsBoardState({ ...studentsBoardState, active: true });
                    if (directiveBoardState.enter) setDirectiveBoardState({ ...directiveBoardState, active: true });
                    if (academicBoardState.enter) setAcademicBoardState({ ...academicBoardState, active: true });
                    if (humanDevState.enter) setHumanDevState({ ...humanDevState, active: true });
                    if (enlaceState.enter) setEnlaceState({ ...enlaceState, active: true });
                    // if (physicalPlantState.enter) setPhysicalPlantState({ ...physicalPlantState, active: true });
                    if (technologyState.enter) setTechnologyState({ ...technologyState, active: true });
                }}
            >
                <Administracion />
            </CSSTransition>

            <CSSTransition
                in={eduAdminState.active}
                timeout={500}
                classNames="edu-admin-dpt"
                unmountOnExit
                onEnter={() => {
                    setBlankState({ enter: false, active: false });
                    setStudentsBoardState({ enter: false, active: false });
                    setDirectiveBoardState({ enter: false, active: false });
                    setAcademicBoardState({ enter: false, active: false });
                    setHumanDevState({ enter: false, active: false });
                    setEnlaceState({ enter: false, active: false });
                    setAdminState({ enter: false, active: false });
                    // setPhysicalPlantState({ enter: false, active: false });
                    setTechnologyState({ enter: false, active: false });
                }}
                onExited={() => {
                    if (blankState.enter) setBlankState({ ...blankState, active: true });
                    if (studentsBoardState.enter) setStudentsBoardState({ ...studentsBoardState, active: true });
                    if (directiveBoardState.enter) setDirectiveBoardState({ ...directiveBoardState, active: true });
                    if (academicBoardState.enter) setAcademicBoardState({ ...academicBoardState, active: true });
                    if (humanDevState.enter) setHumanDevState({ ...humanDevState, active: true });
                    if (enlaceState.enter) setEnlaceState({ ...enlaceState, active: true });
                    if (adminState.enter) setAdminState({ ...adminState, active: true });
                    // if (physicalPlantState.enter) setPhysicalPlantState({ ...physicalPlantState, active: true });
                    if (technologyState.enter) setTechnologyState({ ...technologyState, active: true });
                }}
            >
                <AdministracionDocente />
            </CSSTransition>

            <CSSTransition
                in={humanDevState.active}
                timeout={500}
                classNames="human-dev-dpt"
                unmountOnExit
                onEnter={() => {
                    setBlankState({ enter: false, active: false });
                    setEduAdminState({enter:false,active: false});
                    setStudentsBoardState({ enter: false, active: false });
                    setDirectiveBoardState({ enter: false, active: false });
                    setAcademicBoardState({ enter: false, active: false });
                    setAdminState({ enter: false, active: false });
                    setEnlaceState({ enter: false, active: false });
                    // setPhysicalPlantState({ enter: false, active: false });
                    setTechnologyState({ enter: false, active: false });
                }}
                onExited={() => {
                    if (blankState.enter) setBlankState({ ...blankState, active: true });
                    if (eduAdminState.enter) setEduAdminState({ ...eduAdminState, active: true });
                    if (studentsBoardState.enter) setStudentsBoardState({ ...studentsBoardState, active: true });
                    if (directiveBoardState.enter) setDirectiveBoardState({ ...directiveBoardState, active: true });
                    if (academicBoardState.enter) setAcademicBoardState({ ...academicBoardState, active: true });
                    if (adminState.enter) setAdminState({ ...adminState, active: true });
                    if (enlaceState.enter) setEnlaceState({ ...enlaceState, active: true });
                    // if (physicalPlantState.enter) setPhysicalPlantState({ ...physicalPlantState, active: true });
                    if (technologyState.enter) setTechnologyState({ ...technologyState, active: true });
                }}
            >
                <DesarrolloHumano />
            </CSSTransition>

            <CSSTransition
                in={enlaceState.active}
                timeout={500}
                classNames="enlace-dpt"
                unmountOnExit
                onEnter={() => {
                    setBlankState({ enter: false, active: false });
                    setEduAdminState({enter:false,active: false});
                    setStudentsBoardState({ enter: false, active: false });
                    setDirectiveBoardState({ enter: false, active: false });
                    setAcademicBoardState({ enter: false, active: false });
                    setAdminState({ enter: false, active: false });
                    setHumanDevState({ enter: false, active: false });
                    // setPhysicalPlantState({ enter: false, active: false });
                    setTechnologyState({ enter: false, active: false });
                }}
                onExited={() => {
                    if (blankState.enter) setBlankState({ ...blankState, active: true });
                    if (eduAdminState.enter) setEduAdminState({ ...eduAdminState, active: true });
                    if (studentsBoardState.enter) setStudentsBoardState({ ...studentsBoardState, active: true });
                    if (directiveBoardState.enter) setDirectiveBoardState({ ...directiveBoardState, active: true });
                    if (academicBoardState.enter) setAcademicBoardState({ ...academicBoardState, active: true });
                    if (adminState.enter) setAdminState({ ...adminState, active: true });
                    if (humanDevState.enter) setHumanDevState({ ...humanDevState, active: true });
                    // if (physicalPlantState.enter) setPhysicalPlantState({ ...physicalPlantState, active: true });
                    if (technologyState.enter) setTechnologyState({ ...technologyState, active: true });
                }}
            >
                <Enlace />
            </CSSTransition>

            {/* <CSSTransition
                in={physicalPlantState.active}
                timeout={500}
                classNames="physical-plant-dpt"
                unmountOnExit
                onEnter={() => {
                    setBlankState({ enter: false, active: false });
                    setStudentsBoardState({ enter: false, active: false });
                    setDirectiveBoardState({ enter: false, active: false });
                    setAcademicBoardState({ enter: false, active: false });
                    setAdminState({ enter: false, active: false });
                    setHumanDevState({ enter: false, active: false });
                    setEnlaceState({ enter: false, active: false });
                    setTechnologyState({ enter: false, active: false });
                }}
                onExited={() => {
                    if (blankState.enter) setBlankState({ ...blankState, active: true });
                    if (studentsBoardState.enter) setStudentsBoardState({ ...studentsBoardState, active: true });
                    if (directiveBoardState.enter) setDirectiveBoardState({ ...directiveBoardState, active: true });
                    if (academicBoardState.enter) setAcademicBoardState({ ...academicBoardState, active: true });
                    if (adminState.enter) setAdminState({ ...adminState, active: true });
                    if (humanDevState.enter) setEnlaceState({ ...humanDevState, active: true });
                    if (enlaceState.enter) setEnlaceState({ ...enlaceState, active: true });
                    if (technologyState.enter) setTechnologyState({ ...technologyState, active: true });
                }}
            >
                <PlantaFisica />
            </CSSTransition> */}

            <CSSTransition
                in={technologyState.active}
                timeout={500}
                classNames="technology-dpt"
                unmountOnExit
                onEnter={() => {
                    setBlankState({ enter: false, active: false });
                    setEduAdminState({enter:false,active: false});
                    setStudentsBoardState({ enter: false, active: false });
                    setDirectiveBoardState({ enter: false, active: false });
                    setAcademicBoardState({ enter: false, active: false });
                    setAdminState({ enter: false, active: false });
                    setHumanDevState({ enter: false, active: false });
                    setEnlaceState({ enter: false, active: false });
                    // setPhysicalPlantState({ enter: false, active: false });
                }}
                onExited={() => {
                    if (blankState.enter) setBlankState({ ...blankState, active: true });
                    if (eduAdminState.enter) setEduAdminState({ ...eduAdminState, active: true });
                    if (studentsBoardState.enter) setStudentsBoardState({ ...studentsBoardState, active: true });
                    if (directiveBoardState.enter) setDirectiveBoardState({ ...directiveBoardState, active: true });
                    if (academicBoardState.enter) setAcademicBoardState({ ...academicBoardState, active: true });
                    if (adminState.enter) setAdminState({ ...adminState, active: true });
                    if (humanDevState.enter) setEnlaceState({ ...humanDevState, active: true });
                    if (enlaceState.enter) setEnlaceState({ ...enlaceState, active: true });
                    // if (physicalPlantState.enter) setPhysicalPlantState({ ...physicalPlantState, active: true });
                }}
            >
                <Tecnologia />
            </CSSTransition>
        </div >
    )
}

export default Departments;