import React, { Fragment, useContext, useEffect } from 'react';
import CoCurriculars from './Cocurriculars';
import Slider from './Slider';
// import Cicles from '../../Helpers/Cicles';
// import CiclesMobile from '../../Helpers/Cicles/CiclesMobile';
// import Words from './Words';
import News from './News';
import Events from './Events';
// import Socials from './Socials';

//Contexts
import MainContext from '../../../contexts/pages/main/mainContext';
import AboutusContext from '../../../contexts/pages/aboutus/aboutusContext'

import NewsSlider from './NewsSlider';

const MainPage = () => {

    const mainContext = useContext(MainContext);
    const { getMain } = mainContext;

    const aboutusContext = useContext(AboutusContext);
    const { getAboutus } = aboutusContext;


    useEffect(() => {
        getMain()
        getAboutus()
        // eslint-disable-next-line
    },[]);

    return (
        <Fragment>
                <Slider ClassName={'main-slider'} />
                <CoCurriculars />
                {/* Se fueron 23-08-2021 */}
                {/* <Cicles /> */}
                {/* <CiclesMobile /> */}
                {/* <Words /> */}
                <NewsSlider ClassName={'new-slider'} />
                {/* <News /> */}
                <Events />
                
                {/* <Socials /> */}
        </Fragment>
    );
}

export default MainPage;