// GENERAL LAYOUT FOR GLOBAL HEADER

import React, { Fragment, useState } from 'react';
import './header.scss';

import { CSSTransition } from 'react-transition-group';
import Navbar from '../../Navbar/index';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import Logo from '../../../assets/logo-csb-1024x673.png';

// Sub-menues
import Accounts from './Submenu/Accounts';
import Main from './Submenu/Main';
import AboutUs from './Submenu/AboutUs';
import Curriculum from './Submenu/Curriculum';

import useScrollDirection from "../../Helpers/Hooks/useScrollDirection"

const Header = () => {

    // States from switching between principal and secundary pages
    const [mainState, setMainState] = useState(true);
    const [aboutState, setAboutState] = useState({
        enter: false,
        active: false
    });
    const [curriculumState, setCurriculumState] = useState({
        enter: false,
        active: false
    });
    const scrollDirection = useScrollDirection();

    return (
        <Fragment>
            {/* Navbar for large devices */}
            <div className="header-wrapper" id="headerTop">
                <div className="row header">
                    <div className="col-12">
                        <div className="header-inner">
                            <div className="menu">
                                <div className="sub-menu-inner">
                                    <Accounts />
                                    <div className={`sub-menu-2 ${ scrollDirection === "down" ? "hide" : "show"}`}>
                                        <div className="logo">
                                            <Link
                                                to='/'
                                                onClick={() => {
                                                    setCurriculumState({
                                                        enter: false,
                                                        active: false
                                                    });
                                                    if(document.querySelector('.container-fluid'))document.querySelector('.container-fluid').scrollIntoView({ behavior: 'smooth', block: 'start' })
                                                }}
                                            >
                                                <Image src={Logo} />
                                            </Link>
                                            {/* <div className="logo-text">
                                                <p>- por el desallorro humano -</p>
                                            </div> */}
                                        </div>
                                        <CSSTransition
                                            in={mainState}
                                            timeout={500}
                                            classNames="sub-menu-main"
                                            unmountOnExit
                                            onEnter={() => {
                                                setAboutState({
                                                    enter: false,
                                                    active: false
                                                });
                                                setCurriculumState({
                                                    enter: false,
                                                    active: false
                                                });
                                            }}
                                            onExited={() => {
                                                if (aboutState.enter)
                                                    setAboutState({
                                                        ...aboutState,
                                                        active: true
                                                    });
                                                if (curriculumState.enter)
                                                    setCurriculumState({
                                                        ...curriculumState,
                                                        active: true
                                                    });
                                            }}
                                        >
                                            <Main
                                                setMainState={setMainState}
                                                aboutState={aboutState}
                                                setAboutState={setAboutState}
                                                curriculumState={curriculumState}
                                                setCurriculumState={setCurriculumState}
                                            />
                                        </CSSTransition>
                                        <CSSTransition
                                            in={aboutState.active}
                                            timeout={500}
                                            classNames="sub-menu-aboutus"
                                            unmountOnExit
                                            onEnter={() => setMainState(false)}
                                            onExited={() => setMainState(true)}
                                        >
                                            <AboutUs setAboutState={setAboutState} />
                                        </CSSTransition>
                                        <CSSTransition
                                            in={curriculumState.active}
                                            timeout={500}
                                            classNames="sub-menu-curriculum"
                                            unmountOnExit
                                            onEnter={() => setMainState(false)}
                                            onExited={() => setMainState(true)}
                                        >
                                            <Curriculum setCurriculumState={setCurriculumState} />
                                        </CSSTransition>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            {/* Navbar for medium and small devices */}
            <Navbar />
        </Fragment>
    );
};

export default Header;