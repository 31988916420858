// GENERAL LAYOUT FOR CURRICULUM SUBMENU INSIDE GLOBAL HEADER

import React from 'react';
import './submenu.scss';

import Exit from './Exit';
import { Link } from 'react-router-dom';


const Curriculum = ({ setCurriculumState }) => {
    return (
        // Links for curriculum areas: Primer Ciclo, Segundo Ciclo & Bachillerato IB
        <div className="submenu submenu-curriculum">
            <Link to="/" className="exit-link">
                <Exit setState={setCurriculumState} />
            </Link>
            <div className="links-group">
                <div className="links">
                    <Link to="/curriculum/primer-ciclo">
                        <h6>Primer Ciclo</h6>
                    </Link>
                    <div className="fg"></div>
                </div>
                <div className="links">
                    <Link to="/curriculum/segundo-ciclo">
                        <h6>Segundo Ciclo</h6>
                    </Link>
                    <div className="fg"></div>
                </div>
                <div className="links">
                    <Link to="/curriculum/bachillerato-ib">
                        <h6>Programa IB</h6>
                    </Link>
                    <div className="fg"></div>
                </div>
            </div>
        </div>
    )
}

export default Curriculum;