import React, { useRef, useEffect, useContext, Fragment } from 'react';
import './dialog.scss';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Gallery from '../Gallery';
import ReactMarkdown from 'react-markdown';

// CONTEXT
import { dialogContext } from '../../../contexts/helpers/dialog/dialogContext';


const DialogComponent = () => {

    const scroll = 'paper';

    const DialogContext = useContext(dialogContext)
    const { open, handleClose, content, profile, virtualTool, commitment, clubes, type } = DialogContext;

    const descriptionElementRef = useRef(null);

    const dialogCase = caseType => {
        switch (caseType) {
            case 'profileCard':
                return (
                    <div className="profile-dialog">
                        <DialogTitle id="scroll-dialog-title" style={{ textTransform: 'uppercase' }}>
                            <div className="profile-dialog-header">
                                <div className={`profile-dialog-image profile-dialog-image-${profile.nombre}`}>
                                    <img src={profile.imagen} alt={profile.nombre} />
                                </div>
                                <div className="profile-dialog-image-info">
                                    <div className="profile-dialog-name">{profile.nombre}</div>
                                    <div className="profile-dialog-position">{profile.cargo}</div>
                                </div>

                            </div>
                        </DialogTitle>
                        <DialogContent>
                            <div className="profile-dialog-body">
                                <div className="profile-dialog-body-nacionalidad info"><span>Nacionalidad: </span>{profile.nacionalidad}</div>
                                <div className="profile-dialog-body-profesion info"><span>Profesión: </span>{profile.profesion}</div>
                                <div className="profile-dialog-body-correo info"><span>Correo: </span>{profile.correo === 'undefined' ? '' : profile.correo }</div>
                            </div>
                            <DialogContentText
                                className="profile-dialog-frase"
                                id="scroll-dialog-description"
                                ref={descriptionElementRef}
                                tabIndex={-1}
                                style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}
                            >
                                
                                    <ReactMarkdown>{profile?.frase}</ReactMarkdown>

                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="default">
                                Cerrar
                            </Button>
                        </DialogActions>
                    </div>
                )
            case 'virtualTool':
                return (
                    <div className="profile-dialog">
                        <DialogTitle id="scroll-dialog-title">
                            <div className={`virtualtool-dialog-image virtualtool-dialog-image-${virtualTool.nombre}`}>
                                <img src={virtualTool.imagen} alt={virtualTool.nombre} />
                            </div>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText
                                className="profile-dialog-frase"
                                id="scroll-dialog-description"
                                ref={descriptionElementRef}
                                tabIndex={-1}
                                style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}
                            >
                               
                                    <ReactMarkdown>{virtualTool.contenido}</ReactMarkdown>
                                
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="default">
                                Cerrar
                            </Button>
                        </DialogActions>
                    </div>
                )
            case 'commitment':
                return (
                    <div className="commitment-dialog">
                        <DialogTitle id="scroll-dialog-title">
                            {commitment.nombre}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText
                                className="commitment-dialog-frase"
                                id="scroll-dialog-description"
                                ref={descriptionElementRef}
                                tabIndex={-1}
                                style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}
                            >
                              
                                    <ReactMarkdown>{commitment.contenido}</ReactMarkdown>
                                
                            </DialogContentText>
                        </DialogContent>
                        <Gallery photos={commitment.imagen} />
                        <DialogActions>
                            <Button onClick={handleClose} color="default">
                                Cerrar
                            </Button>
                        </DialogActions>
                    </div>
                )
            case 'subActivity':
                return (
                    <div className="commitment-dialog">
                        <DialogTitle id="scroll-dialog-title">
                            {clubes.titulo}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText
                                className="commitment-dialog-frase"
                                id="scroll-dialog-description"
                                ref={descriptionElementRef}
                                tabIndex={-1}
                                style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}
                            >
                              
                                    <ReactMarkdown>{clubes.descripcion}</ReactMarkdown>
                                
                            </DialogContentText>
                        </DialogContent>
                        <Gallery photos={clubes.imagenes} />
                        <DialogActions>
                            <Button onClick={handleClose} color="default">
                                Cerrar
                            </Button>
                        </DialogActions>
                    </div>
                )
            default:
                return (
                    <Fragment>
                        <DialogTitle id="scroll-dialog-title" style={{ textTransform: 'uppercase' }}>
                            <div className="dialog-header">
                                
                                <div className="dialog-fecha">{content.fecha}</div>
                            </div>
                            <div className="dialog-titulo">{content.titulo}</div>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText
                                id="scroll-dialog-description"
                                ref={descriptionElementRef}
                                tabIndex={-1}
                                style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}
                            >
                               
                                    <ReactMarkdown>{content.contenido}</ReactMarkdown>
                              
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="default">
                                Cerrar
                            </Button>
                        </DialogActions>
                    </Fragment>
                )
        }
    }

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <div className="dialog">
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                {dialogCase(type)}
            </Dialog>
        </div>
    )
}

export default DialogComponent;