import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const AccessibilityContainer = styled.div`
	// width: 100%;
    // display: flex;
    // margin-left: 10px;
	// margin-top: 10px;
	width: 100%;
    display: grid;
    padding: 10px 10px;
	grid-template-columns: minmax(0, 1fr) minmax(0, 1fr)
`;

const Button = styled.button`
	border: 0;
	outline: 0;
	padding: 8px 1em;
	color: #0a415d;
	font-size: 13px;
	font-weight: 600;
	border-radius: 20px;
	background-color: transparent;
	border: 2px solid #0A415D;
	transition: all 240ms ease-in-out;
	cursor: pointer;
	margin: 5px;

	&:hover {
		color: #fff;
		background-color: #0A415D;
	}

	&:not(:last-of-type) {
		margin-right: 7px;
	}
`;

const Accessibility = ({ handleCloseMenu }) => {
	return (
		<AccessibilityContainer>
			<Button
				onClick={() => { handleCloseMenu() }}
			>
				<a href="https://intranet.secundariababeque.edu.do" target="_blank" rel="noreferrer">INTRANET</a>
			</Button>
		</AccessibilityContainer>
	);
}

export default Accessibility;