import React, { useContext } from 'react';
import './videoPlayer.scss';

import ReactPlayer from 'react-player';

// CONTEXT
import { videoPlayerContext } from '../../../contexts/helpers/videoPlayer/videoPlayerContext';

const VideoPlayer = () => {

    const VideoPlayerContext = useContext(videoPlayerContext);
    const { active } = VideoPlayerContext;

    return (
        active
            ?
            <div className="video-player-wrapper">
                <ReactPlayer
                    playing
                    controls
                    className='react-player'
                    url='https://youtu.be/Cx3JR-xlJrQ'
                    width='80vw'
                    height='70vh'
                />
            </div>
            : null
    )
}

export default VideoPlayer;