import React, { useEffect, useContext } from 'react';
import './ibBachelor.scss';

import DialogContentText from '@material-ui/core/DialogContentText';
import ReactMarkdown from 'react-markdown';
import { baseURL } from '../../../../../api';
import Dropdown from '../../../../Globals/Dropdown'
import { Image } from 'react-bootstrap';
// CONTEXT
import CurriculumContext from '../../../../../contexts/pages/curriculum/curriculumContext';

const IbBachelor = () => {

    const aboutusContext = useContext(CurriculumContext)
    const { ibBachelor, getCurriculum } = aboutusContext;

    useEffect(() => {
        getCurriculum();
        // eslint-disable-next-line
    }, [])
    // console.log(ibBachelor)

    return (
        <div className="subpage ib-bachelor">
            <div className="ib-bachelor-inner">
                <div className="ib-bachelor-title">Programa del Diploma del Bachillerato Internacional (IB)</div>
                <div className="ib-bachelor-text">
                    {/* <DialogContentText> */}
                    <ReactMarkdown>
                        {ibBachelor ? ibBachelor.contenido : ""}
                    </ReactMarkdown>
                    {/* </DialogContentText> */}
                </div>
                {ibBachelor ?
                    // <div className="files">
                    //     <div className="row">
                    //         <div className="file-col col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    //             <a href={`${baseURL}${ibBachelor.programa.url}`} target="_blank" rel="noopener noreferrer">
                    //                 <div className="file">
                    //                     <h6>Programa</h6>
                    //                 </div>
                    //             </a>
                    //         </div>
                    //         <div className="file-col col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    //             <a href={`${baseURL}${ibBachelor.probidad.url}`} target="_blank" rel="noopener noreferrer">
                    //                 <div className="file">
                    //                     <h6>Probidad Academica</h6>
                    //                 </div>
                    //             </a>
                    //         </div>
                    //         <div className="file-col col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    //             <a href={`${baseURL}${ibBachelor.politica.url}`} target="_blank" rel="noopener noreferrer">
                    //                 <div className="file">
                    //                     <h6>Política de evaluación</h6>
                    //                 </div>
                    //             </a>
                    //         </div>
                    //     </div>
                    // </div>
                    <div className="content-ib">
                      
                        {/* <a href="mailto:user@domain.com?Subject=Hello%20User">Inbox me!</a> */}
                        <div className="logo-ib">
                            <Image src={`${baseURL}${ibBachelor.grafico_ib.url}`} alt={`image-logo-ib`}></Image>
                        </div>

                        <div className="ib-bachelor-text">
                           <p>
                           A continuación, se presenta una síntesis de los diversos grupos de materias, con información 
                            extraída del sitio web del BI (www.ibo.org) y un breve detalle de las asignaturas que forman parte del Programa 
                            del Diploma en nuestro Colegio.
                           </p>
                        </div>
                        <div className="grupos-ib">
                            <Dropdown values={ibBachelor.grupos_ib} />
                        </div>
                        <div className="ib-bachelor-title">Componentes Troncales del Programa del Diploma</div>
                        <div className="grupos-ib ib-bachelor-text">
                            <Dropdown values={ibBachelor.troncales_ib} />
                        </div>
                    </div>
                    : null
                }
            </div>
        </div>
    )
}

export default IbBachelor;