import FirstCicle from './FirstCicle';
import SecondCicle from './SecondCicle';
import IbBachelor from './IbBachelor';

const CurriculumPages = {
    FirstCicle,
    SecondCicle,
    IbBachelor
}

export default CurriculumPages;