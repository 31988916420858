import { useState, useEffect, useContext } from 'react';
import AwesomeSlider from 'react-awesome-slider';
import WithAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/src/styles';
import './slider.scss';

import { baseURL } from '../../../../api'

import AboutusContext from '../../../../contexts/pages/aboutus/aboutusContext';

const AutoplaySlider = WithAutoplay(AwesomeSlider);

const Slider = ({ ClassName }) => {

    const [activeImages, setActiveImages] = useState([]);

    const aboutusContext = useContext(AboutusContext);
    const { state: AboutusState } = aboutusContext;

    // const activitiesPagesContext = useContext(secundaryPagesContext);
    // const {
    //     feriaCulturalState,
    //     gozabequeState,
    //     musicalState,
    //     clubesState,
    //     optativasState,
    //     MNUState: modeloNacionesUnidasState,
    // } = activitiesPagesContext;

    useEffect(() => {
        const getActiveActivity = () => {
            if (AboutusState.history) {
                setActiveImages(AboutusState.history.banner)
            }
        }
        getActiveActivity();
        // eslint-disable-next-line 
    }, [AboutusState])


    return (
        <div className={`${ClassName || 'slider'}`}>
            {activeImages.length > 0 ?
                <AutoplaySlider
                    bullets={false}
                    play={true}
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={5000}
                    showTimer={false}
                >
                    {activeImages
                        ?
                        activeImages.map(value => {
                            return <div key={value._id} data-src={`${baseURL}${value.url}`} />
                        })
                        :
                        <div data-src={""} />
                    }
                </AutoplaySlider>
                : null}
        </div>

    )
}

export default Slider;