import React, { useReducer } from 'react';
import AboutusContext from './aboutusContext';
import AboutusReducer from './aboutusReducer';
import api from '../../../api';

import { TYPES } from '../../types';

const AboutusState = (props) => {

    const initialState = {
        allies: null,
        commitment: null,
        departments: null,
        history: null,
        philosophy: null,
        virtualTools: null,
        virtualTour: null,
        banner: null,
    };

    const [state, dispatch] = useReducer(AboutusReducer, initialState);

    const getAboutus = async () => {
        try {
            const result = await api.get('/conocenos');
            dispatch({
                type: TYPES.GET_ABOUTUS,
                payload: result.data
            })
        } catch (error) {

        }
    }

    const updateAboutusBanner = (subpage) => {
        try {
            // console.log(state[subpage]);
        } catch (error) {
            
        }
    }

    return (
        <AboutusContext.Provider
            value={{
                allies: state.allies,
                commitment: state.commitment,
                departments: state.departments,
                history: state.history,
                philosophy: state.philosophy,
                virtualTools: state.virtualTools,
                virtualTour: state.virtualTour,
                banner: state.banner,
                state: state,
                getAboutus,
                updateAboutusBanner,
            }}
        >
            {props.children}
        </AboutusContext.Provider>
    )
}

export default AboutusState;