import React from 'react';
import './cards.scss';
import { baseURL } from '../../../../../../../../api';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import DirectorsCard from './DirectorsCard';
// import image from '../../../../../../../../assets/directors-card.jpg';

const Cocurriculars = ({ directiveBoard }) => {

    const responsive = {
        desktop: {
            breakpoint: { max: 4000, min: 1024 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <div className="directors-cards-carousel">
            <div className="directors-cards-inner">
                <Carousel
                    additionalTransfrom={0}
                    arrows
                    autoPlay
                    autoPlaySpeed={30000}
                    centerMode={false}
                    className=""
                    containerClass="container-with-dots"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={responsive}
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                >
                    {directiveBoard
                        ?
                        directiveBoard.tarjetas_consejo_directivo.map(value => (
                            <DirectorsCard
                                key={value._id}
                                name={value.nombre}
                                charge={value.cargo}
                                daughters={""}
                                profession={value.contenido}
                                hobbies={[""]}
                                width="auto"
                                image={`${baseURL}${value.foto.url}`}
                            />
                        ))
                        : null
                    }
                    {/* <DirectorsCard
                        name="Claudia Cepeda Darauche"
                        charge="Presidenta"
                        daughters="Maria Perez, Juan Perez"
                        profession="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                        hobbies={["Nadar", "Leer"]}
                        width="auto"
                        image={image}
                    /> */}
                </Carousel>
            </div>
        </div>

    )
}

export default Cocurriculars;