import { TYPES } from '../../types';

const activitiesReducer = (state, action) => {
    switch (action.type) {
        case TYPES.GET_ACTIVITIES:
            return {
                ...state,
                feriaCultural: action.payload[0] || null,
                gozabeque: action.payload[1] || null,
                musical: action.payload[2] || null,
                clubes: action.payload[3] || null,
                optativas: action.payload[4] || null,
                modeloNacionesUnidas: action.payload[5] || null,
                deporte: action.payload[6] || null
            }
        default:
            return state
    }
}

export default activitiesReducer;