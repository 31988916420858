import { useEffect, useState } from 'react'
import Slider from "../Curriculum/Slider";
import "./students.scss"
import moodleimage from "../../../assets/bbqmoodle.png"
import moodleIcon from '../../../assets/moodle-icon.svg'
import powerschoolimage from "../../../assets/bbqpowers.png"
import powerschoolIcon from '../../../assets/powerschool-icon.svg'
import edusystemimage from "../../../assets/edusystemimg.jpeg"
import edusystemIcon from '../../../assets/edusystem-icon.svg'
import ConsejoEstudiantil from '../Aboutus/Subpages/Departments/Areas/ConsejoEstudiantil';

import { CSSTransition } from 'react-transition-group';
async function scrollTo(cb, ms) {
    return await new Promise(resolve => {
        setTimeout(cb, ms);
    });
}


const Students = () => {
    useEffect(() => {
        if (document.querySelector(".sub-menu-1")){
            document.querySelector(".sub-menu-1").style.background = "#0a415d"
            document.querySelectorAll(".iconColorGroup").forEach(
                (i) => i.style.filter = "brightness(0) invert(99%) sepia(67%) saturate(1604%) hue-rotate(327deg) brightness(104%) contrast(88%)")
        }
    
        return () => {
            if (document.querySelector(".sub-menu-1")){
                document.querySelector(".sub-menu-1").style.background = "#fee860"
                document.querySelectorAll(".iconColorGroup").forEach(
                    (i) => i.style.filter = "brightness(0) saturate(100%) invert(19%) sepia(14%) saturate(5890%) hue-rotate(172deg) brightness(79%) contrast(92%)")
            }

        }
    }, [])

    const menuElements = [
        {
            title: "AREAS DE INTERES",
            icon: moodleIcon,
            name: "areas"
        },
        {
            title: "MENU CAFETERIA",
            icon: moodleIcon,
            name: "menu"
        },
        {
            title: "CONSEJO ESTUDIANTIL",
            icon: moodleIcon,
            name: "students"
        },
    ]

    // const studentsIframe = '<iframe width="620px" height="361px" src="https://www.yumpu.com/es/embed/view/2o7MQlLPI6iyetvW" frameborder="0" allowfullscreen="true"  allowtransparency="true"></iframe>'; 
    const studentsIframe = '<iframe width="620px" height="531px" src="https://www.yumpu.com/es/embed/view/Nx7KiRWbtAGOr0f4" frameborder="0" allowfullscreen="true"  allowtransparency="true"></iframe>'; 
    const [useActiveChoice, setActiveChoice] = useState({
        areas: false,
        menu: false,
        students: false
    })

    const handleScrollAction = () => {
        scrollTo(() => {
            if (document.querySelector(".students-page"))
                document.querySelector(".students-page").scrollIntoView({ behavior: "smooth", block: "start" })
        }, 500);
    }

    const handleActiveChoice = (data) => {
        const newStateList = Object.keys(useActiveChoice).map((i) => i === data ? [i, true] : [i, false])
        const newState = Object.fromEntries(newStateList)
        // window.scrollTo(0,100)
        handleScrollAction()

        return setActiveChoice({ ...newState })
      
    }
    const Iframe = (props) => {
        return (<div className="students-book"  dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
    }

    // console.log(useActiveChoice)

    return (
        <div className="subpage students-page">
            <Slider id="top-header" ClassName={'curriculum-slider'} />
            
            <CSSTransition
                in={useActiveChoice.areas}
                timeout={300}
                classNames="areas-container"
                unmountOnExit>
                <div className="areas-container">
                    <div className="areas-title">Areas de interes</div>
                    <div className="container">
                        <div className="item">
                            <img className="image-bg" src={moodleimage} alt="" />
                            <div className="content">
                                <div className="text-content">
                                    <img src={moodleIcon} alt="" />
                                    <h6>Moodle</h6>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <img className="image-bg" src={powerschoolimage} alt="" />
                            <div className="content">
                                <div className="text-content">
                                    <img src={powerschoolIcon} alt="" />
                                    <h6>Powerschool</h6>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <img className="image-bg" src={edusystemimage} alt="" />
                            <div className="content">
                                <div className="text-content">
                                    <img src={edusystemIcon} alt="" />
                                    <h6>Edusystem</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>

            <CSSTransition
                in={useActiveChoice.menu}
                timeout={300}
                classNames="menu-container"
                unmountOnExit>
                <div className="menu-container">
                   <div className="menu-image">
                       <img src="https://mcusercontent.com/baf0ec0b92385225716ab3f49/images/e549c3a3-3ef3-ec3a-eeb8-67d0a8e33e9f.jpg" alt="menu" />
                   </div>
                </div>
            </CSSTransition>

            <CSSTransition
                in={useActiveChoice.students}
                timeout={300}
                classNames="students-container"
                unmountOnExit>

                <div className="students-container">
                    <div className="students-title">Consejo Estudiantil</div>
                    <ConsejoEstudiantil />
                    {/* <Iframe iframe={studentsIframe}/> */}
                </div>
            </CSSTransition>

            <div className="menu-section">
                {
                    menuElements.map((el) => {
                        return (
                            <div className={`menu-item ${useActiveChoice[el.name] ? 'menu-item__active' : ''}`} key={el.name} onClick={() => handleActiveChoice(el.name)}>

                                <div className="menu-content">
                                    <img src={el.icon} alt="" />
                                    <h6>{el.title}</h6>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            {/* <iframe width="525" height="406" src="//e.issuu.com/embed.html#22176108/31739896" frameborder="0" allowfullscreen></iframe> */}
            <h1 style={{ color: "white" }}>500</h1>

        </div>
    )
}

export default Students;