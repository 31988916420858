import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import DefaultContainer from './containers/Default';
import Pages, { AboutusPages, CurriculumPages } from './components/Pages';

//CONTEXT
import MainContext from './contexts/pages/main/mainState';

import AboutusContext from './contexts/pages/aboutus/aboutusState';
import AboutusPagesContext from './contexts/pages/aboutus/secundaryPages/secundaryPagesContext';
import AboutusDeparmentsContext from './contexts/pages/aboutus/departments/departmentsState';

import ActivitiesContext from './contexts/pages/activities/activitiesState';
import ActivitiesPagesContext from './contexts/pages/activities/secundaryPages/secundaryPagesContext';

import CurriculumContext from './contexts/pages/curriculum/curriculumState';

import DialogContext from './contexts/helpers/dialog/dialogContext';

import VideoPlayerContext from './contexts/helpers/videoPlayer/videoPlayerContext';

function App() {
  return (
    <MainContext>
      <AboutusContext>
        <AboutusPagesContext>
          <AboutusDeparmentsContext>
            <ActivitiesContext>
              <ActivitiesPagesContext>
                <CurriculumContext>
                  <DialogContext>
                    <VideoPlayerContext>
                    <Router>
                      <DefaultContainer>
                          <Switch>
                            {/* MAIN PAGE */}
                            <Route exact path='/' component={Pages.Main} />

                            {/* ACTIVITIES PAGE */}
                            <Route exact path='/actividades' component={Pages.Activities} />

                            {/* CENTRO CUTURAL PAGE */}

                            {/* CONTACT US PAGE */}
                            <Route exact path='/contactanos' component={Pages.Contactus} />

                            {/* ABOUT US SUBPAGES */}
                            <Route exact path='/conocenos' component={Pages.Aboutus} />
                            <Route exact path='/conocenos/aliados' component={AboutusPages.Allies} />
                            <Route exact path='/conocenos/compromiso' component={AboutusPages.Commitment} />
                            <Route exact path='/conocenos/organismos-departamentos' component={AboutusPages.Departments} />
                            <Route exact path='/conocenos/historia' component={AboutusPages.History} />
                            <Route exact path='/conocenos/filosofia' component={AboutusPages.Philosophy} />
                            <Route exact path='/conocenos/herramientas' component={AboutusPages.VirtualTools} />
                            <Route exact path='/conocenos/tour-virtual' component={AboutusPages.VirtualTour} />

                            {/* CURRICULUM SUBPAGES */}
                            <Route exact path='/curriculum' component={Pages.Curriculum} />
                            <Route exact path='/curriculum/primer-ciclo' component={Pages.Construction} />
                            <Route exact path='/curriculum/segundo-ciclo' component={Pages.Construction} />
                            <Route exact path='/curriculum/bachillerato-ib' component={CurriculumPages.IbBachelor} />

                            {/* STUDENTS AREA PAGE */}
                            <Route exact path='/estudiantes' component={Pages.Students} />

                            <Route path='*' component={Pages.FourZeroFour} />
                          </Switch>
                          </DefaultContainer>
                        </Router>
                    </VideoPlayerContext>
                  </DialogContext>
                </CurriculumContext>
              </ActivitiesPagesContext>
            </ActivitiesContext>
          </AboutusDeparmentsContext>
        </AboutusPagesContext>
      </AboutusContext>
    </MainContext>
  );
}

export default App;