import React, { useContext } from 'react';
import './courseTable.scss';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

// CONTEXT
import departmentsContext from '../../../../../../../../contexts/pages/aboutus/departments/departmentsContext';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

function groupBy(arr, groupingFunction) {
    return arr.reduce((grouped, currentItem) => {
      const key = groupingFunction(currentItem);
  
      // If the key doesn't exist in the result, create an empty array for it
      if (!grouped[key]) {
        grouped[key] = [];
      }
  
      // Push the current item to the array corresponding to the key
      grouped[key].push(currentItem);
  
      return grouped;
    }, {});
  }


const CourseTable = ({ selectedCourse }) => {
    const classes = useStyles();

    const {studentsBoard} = useContext(departmentsContext)

    const {Primero, Segundo, Tercero, Cuarto, Quinto, Sexto} = groupBy(studentsBoard.tabla_consejo_estudiantil, ({curso})=>curso)

    const courses = {
        '1ro': Primero,
        '2do': Segundo,
        '3ro': Tercero,
        '4to': Cuarto,
        '5to': Quinto,
        '6to': Sexto,
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Curso</TableCell>
                        <TableCell align="left">Presidente</TableCell>
                        <TableCell align="left">Vicepresidente(a)</TableCell>
                        <TableCell align="left">Tesorero(a)</TableCell>
                        <TableCell align="left">Procurador(a) Verde</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {courses[selectedCourse].map((row, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">
                                {row.curso+" "+row.seccion}
                            </TableCell>
                            <TableCell align="left">{row.Presidente}</TableCell>
                            <TableCell align="left">{row.VicePresidente}</TableCell>
                            <TableCell align="left">{row.Tesorero}</TableCell>
                            <TableCell align="left">{row.ProcuradorVerde}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default CourseTable;
