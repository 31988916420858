import React, { useContext } from 'react';
import { baseURL } from '../../../../../../api';

// CONTEXT
import { dialogContext } from '../../../../../../contexts/helpers/dialog/dialogContext';

const DepartmentCard = ({ title, text, animationStates, image }) => {

    const DialogContext = useContext(dialogContext);
    const { handleOpen, setVirtualTool, setType } = DialogContext;

    const handleClick = (value) => {
        const { imagen, nombre, contenido } = value;
        setType("virtualTool");
        setVirtualTool({ imagen, nombre, contenido});
        handleOpen();
    }

    return (
        <div className="col-card col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <div className="area-card-container">
                <div className="area-card">
                    <div
                        className="front"
                        onClick={() => { handleClick({
                            imagen: `${baseURL}${image[0].url}`,
                            nombre: title.toLowerCase(),
                            contenido: text,
                        }) }}
                    >
                        {/* <div className="title">{title}</div> */}
                        <div className={`image image-${title.toLowerCase()}`}>
                            <img src={`${baseURL}${image[0].url}`} alt={title} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DepartmentCard;