// GENERAL LAYOUT FOR MAIN MENU INSIDE GLOBAL HEADER

import React, { useContext } from 'react';

import './submenu.scss';
import { Link } from 'react-router-dom';

// CONTEXT
import { secundaryPagesContext } from '../../../../contexts/pages/aboutus/secundaryPages/secundaryPagesContext';
import { videoPlayerContext } from '../../../../contexts/helpers/videoPlayer/videoPlayerContext';
// import MainContext from '../../../../contexts/pages/main/mainContext';

// const admisiones = "https://babeque.azurewebsites.net/babequecid/adm_home.aspx"
const admisiones = "https://admisiones.secundariababeque.edu.do/"

const Main = ({ setMainState, aboutState, setAboutState, curriculumState, setCurriculumState }) => {

    // Get states
    const SecundaryPagesContext = useContext(secundaryPagesContext);
    const { setHistoryState, setPhilosophyState, setCommitmentState, setVirtualToolsState, setDepartmentsState } = SecundaryPagesContext;

    const VideoPlayerContext = useContext(videoPlayerContext)
    // const {activitiesLink} = useContext(MainContext)
    const { handleDeactivatePlayer } = VideoPlayerContext;

    // const { activitiesLink } = useContext(MainContext)
    const activitiesLink = "https://centrocultural.secundariababeque.edu.do/"

    async function scrollTo(cb, ms) {
        return new Promise(resolve => {
            setTimeout(cb, ms);
        });
    }
    return (
        <div className="submenu submenu-main">
            <div className="links-group">
                <div className="links">
                    <Link to="/conocenos"
                        onClick={() => {
                            handleDeactivatePlayer()
                            setHistoryState({ enter: false, active: true })
                            setPhilosophyState({ enter: false, active: false })
                            setCommitmentState({ enter: false, active: false })
                            setVirtualToolsState({ enter: false, active: false })
                            setDepartmentsState({ enter: false, active: false })
                            scrollTo(() => {
                                if (document.querySelector(".aboutus-subpage"))
                                    document.querySelector(".aboutus-subpage").scrollIntoView({ behavior: "smooth", block: "start" })
                            }, 500);
                        }}
                    >
                        <h6>CONÓCENOS</h6>
                    </Link>
                    <div className="fg"></div>
                </div>
                <div className="border-left"></div>
                <div className="links">
                    <Link to="/curriculum" onClick={() => {
                        //Hide curriculum submenu
                        // setMainState(false);
                        // setCurriculumState({
                        //     ...curriculumState,
                        //     enter: true
                        // });
                    }}>
                        <h6>CURRICULUM</h6>
                    </Link>
                    <div className="fg"></div>
                </div>
                <div className="border-left"></div>
                <div className="links">
                    <Link to="/curriculum/bachillerato-ib">
                        <h6>PROGRAMA IB</h6>
                    </Link>
                    <div className="fg"></div>
                </div>
                <div className="border-left"></div>
                <div className="links">
                    <a href={admisiones} target="_blank" rel="noopener">
                        <h6>ADMISIONES</h6>
                    </a>
                    <div className="fg"></div>
                </div>
                <div className="border-left"></div>
                <div className="links">
                    <Link to="/actividades">
                        <h6>ACTIVIDADES</h6>
                    </Link>
                    <div className="fg"></div>
                </div>
                <div className="border-left"></div>
                <div className="links">
                    <a href={`${activitiesLink ?? ""}`} target="_blank" rel="noopener">
                        <h6>CENTRO SOCIOCULTURAL BABEQUE</h6>
                    </a>
                    <div className="fg"></div>
                </div>
                <div className="border-left"></div>
                <div className="links">
                    <Link to="/contactanos">
                        <h6>CONTÁCTANOS</h6>
                    </Link>
                    <div className="fg"></div>
                </div>
            </div>
        </div>
    )
}

export default Main;