import React, { useEffect, useContext } from 'react';
import './musical.scss';
import DialogContentText from '@material-ui/core/DialogContentText';
import ReactMarkdown from 'react-markdown';

// CONTEXT
import ActivitiesContext from '../../../../../contexts/pages/activities/activitiesContext';

const Musical = () => {

    const activitiesContext = useContext(ActivitiesContext);
    const { musical } = activitiesContext;

    useEffect(() => {
    }, [musical])

    return (
        <div className="musical activity-subpage">
            <div className="musical-inner act">
                <div className="musical-title">{musical ? musical.titulo.toUpperCase() : "MUSICAL"}</div>
                <div className="musical-text">
                    {musical
                        ?
                        // <DialogContentText>
                            <ReactMarkdown>
                                {musical.contenido}
                            </ReactMarkdown>
                        // </DialogContentText>
                        :
                        <p>
                            La Secundaria Babeque es un centro educativo auspiciado por la Fundación Pedagógica Dominicana Inc.,
                            institución sin fines de lucro creada en el año de 1977  por un grupo de madres y padres cuyos hijos
                            terminaban el sexto grado del Colegio Babeque Inicial y Primaria. Con esta iniciativa se proponían
                            velar por el desarrollo de los valores universales, la dominicanidad y la formación integral de sus hijos e hijas.

                            Se seleccionó el nombre Babeque, voz de origen taíno que significa tierra de oro, por considerar a
                            la niñez y la juventud como la esperanza y el tesoro más preciado de una nación.
                        </p>
                    }
                </div>
            </div>
        </div>
    )
}

export default Musical;