import { useState, useEffect, useContext } from 'react';

import AwesomeSlider from 'react-awesome-slider';
import WithAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/src/styles';
import './slider.scss';

import { baseURL } from '../../../api';


const AutoplaySlider = WithAutoplay(AwesomeSlider);

const Slider = ({bannerData}) => {

  
    return (
        <div className="default-slider">
            
                <AutoplaySlider
                    bullets={true}
                    play={true}
                    organicArrows={false}
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={3000}
                    showTimer={true}
                >
                    {
                        bannerData ?
                        bannerData.map((value)=>{
                            return (
                                <div key={value._id} data-src={`${baseURL}${value.url}`} />
                            )
                        }) : null
                    }

                </AutoplaySlider>
    
        </div>
    )

}

export default Slider;