import React, { useState, useContext, useEffect } from 'react';
import './tecnologia.scss';
import TechnologyProfiles from '../../../../../../Helpers/ProfileCard';
import TeamCarousel from '../../../../../../Helpers/TeamCarousel';

// CONTEXT
import AboutusDepartmentsContext from '../../../../../../../contexts/pages/aboutus/departments/departmentsContext';

const Tecnologia = () => {

    const [profiles, setProfiles] = useState(null)
    const [description, setDescription] = useState("")

    const aboutusDepartmentsContext = useContext(AboutusDepartmentsContext);
    const { departments } = aboutusDepartmentsContext;

    useEffect(() => {
        const getAdministrative = () => {
            if (!departments) return;
            const currProfiles = departments.filter(value => value.titulo.toUpperCase() === 'TECNOLOGÍA');
            setDescription(currProfiles[0].descripcion);
            setProfiles(currProfiles[0].tarjetas_personal);
        }
        getAdministrative()
    }, [departments, profiles])

    return (
        <div className="technology-board">
            <div className="technology-board-inner">
                <div className="technology-board-title">TECNOLOGÍA</div>
                <div className="technology-board-text">
                    <p>
                        {description}
                    </p>
                </div>
            </div>
            <div className="technology-board-profiles-inner">
                {/* {profiles ? <TechnologyProfiles width={250} height={250} spacing={4} src={profiles} small /> : null} */}
                {profiles ?   <TeamCarousel items={profiles}/>: null}
            </div>
        </div>
    )
}

export default Tecnologia;