import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './construction.scss';

const Construction = () => {
    return (
        <div className="subpage construction">
            <h1>Oops!</h1>
            <p>Esta area del la Secundaria está en Construcción</p>
            <Link to="/" className="construction-button">
                <Button><i className="icon-home"></i> Click aquí para ir a la Recepción</Button>
            </Link>
        </div>
    )
}

export default Construction;