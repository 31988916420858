import React, { useEffect, useContext } from 'react';
import './history.scss';
import DialogContentText from '@material-ui/core/DialogContentText';
import ReactMarkdown from 'react-markdown';

// CONTEXT
import AboutusContext from '../../../../../contexts/pages/aboutus/aboutusContext';

import Slider from '../../../../Globals/Slider'

const History = () => {

    const aboutusContext = useContext(AboutusContext);
    const { history } = aboutusContext;

    useEffect(() => {
    }, [history])
    return (
        <div className="aboutus-subpage history">
            {history ? (<Slider bannerData={history.banner} />) :null}
            <div className="history-inner">
                <div className="history-title">{history ? history.titulo : "NUESTRA HISTORIA"}</div>
                <div className="history-text">
                    {history
                        ?
                        // <DialogContentText>
                            <ReactMarkdown>
                                {history.contenido}
                            </ReactMarkdown>
                        // </DialogContentText>
                        :
                        <p>
                            La Secundaria Babeque  es un centro educativo auspiciado por la Fundación Pedagógica Dominicana Inc.,
                            institución sin fines de lucro creada en el año de 1977  por un grupo de madres y padres cuyos hijos
                            terminaban el sexto grado del Colegio Babeque Inicial y Primaria. Con esta iniciativa se proponían
                            velar por el desarrollo de los valores universales, la dominicanidad y la formación integral de sus hijos e hijas.

                            Se seleccionó el nombre Babeque, voz de origen taíno que significa tierra de oro, por considerar a
                            la niñez y la juventud como la esperanza y el tesoro más preciado de una nación.
                        </p>
                    }
                </div>
            </div>
        </div>
    )
}

export default History;