import React, { createContext, useState } from 'react';

export const dialogContext = createContext();

const DialogContext = (props) => {

    const [open, setOpen] = useState(false);
    const [content, setContent] = useState({
        titulo: '',
        fecha: '',
        contenido: '',
    });
    const [profile, setProfile] = useState({
        imagen: "",
        nombre: "",
        cardo: "",
        profesion: "",
        frase: "",
        email: "",
        nacionalidad: "",
    })
    const [virtualTool, setVirtualTool] = useState({
        imagen: "",
        nombre: "",
        contenido: "",
    })
    const [commitment, setCommitment] = useState({
        nombre: "",
        contenido: "",
        imagen: [],
    })
    const [clubes, setClubes] = useState({
        titulo: "",
        descripcion: "",
        imagenes: [],
    })

    const [type, setType] = useState("");

    const handleOpen = () => setOpen(true)

    const handleClose = () => {
        setOpen(false)
        setContent({
            titulo: '',
            fecha: '',
            contenido: '',
        })
        setProfile({
            imagen: "",
            nombre: "",
            profesion: "",
            frase: "",
            email: "",
            nacionalidad: "",
        })
        setVirtualTool({
            imagen: "",
            contenido: "",
        })
        setCommitment({
            contenido: "",
            imagen: []
        })
        setClubes({
            titulo: "",
            descripcion: "",
            imagenes: [],
        })
        setType("")
    }

    return (
        <dialogContext.Provider
            value={{
                open,
                type,
                content,
                profile,
                commitment,
                virtualTool,
                clubes,
                setOpen,
                setType,
                setContent,
                setProfile,
                setCommitment,
                setVirtualTool,
                setClubes,
                handleOpen,
                handleClose
            }}
        >
            {props.children}
        </dialogContext.Provider>
    );
}

export default DialogContext;