import React,{useState, useReducer} from 'react';
import './contactus.scss';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { AccountCircle, AlternateEmail, SubjectRounded, MessageRounded } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1)
    },
    cssLabel: {
        color : 'green'
      },
    
      cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
          borderColor: `${theme.palette.primary.main} !important`,
        }
      },
}));

const formReducer = (state, event) => {
    return {
      ...state,
      [event.name]: event.value
    }
   }

const Contactus = () => {

    const classes = useStyles();

    const [formData, setFormData] = useReducer(formReducer, {name:"",email:"",subject:"",body:""});
    const [submitting, setSubmitting] = useState(false);


    const handleChange = event => {
        // console.log(event.target)
        
        setFormData({
          name: event.target.name,
          value: event.target.value
        });
        // console.log(formData)
      }
    
    const handleSubmit = event => {
        event.preventDefault();
        setSubmitting(true);
        const {email,subject,body, name} = formData;
        var recipient="e.institucional";
        var at = String.fromCharCode(64);
        var dotcom="secundariababeque.edu.do";
        var mail="mailto:";
        window.open(mail+recipient+at+dotcom+"?subject="+subject+"&body="+body+"%0D%0A%0D%0A"+name+"%0D%0A"+email);

        // console.log(formData)
        setTimeout(() => {
          setSubmitting(false);
        }, 3000);
      }
    
    return (
        <div className="subpage contact-us">
            <div className="row contact-us-inner">
                <div className="contact-us-inner-bg"></div>
                <div className="contact-us-info col-lg-6 col-md-12 col-sm-12">
                    <div className="contact-us-info-inner">
                        <div className="title">
                            <div className="title-icon">
                                <i className="fas fa-envelope"></i>
                            </div>
                            <div className="title-text">
                                ¿Tienes algo que decirnos?
                            </div>
                        </div>
                        <div className="subtitle">

                        </div>
                        <div className="writer-info">
                            <div className="name">
                                <div className={classes.margin}>
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item>
                                            <AccountCircle />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                id="contact-us-name"
                                                label="Nombre"
                                                name="name"
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                            <div className="email">
                                <div className={classes.margin}>
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item>
                                            <AlternateEmail />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                id="contact-us-email"
                                                label="Correo"
                                                name="email"
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                            <div className="subject">
                                <div className={classes.margin}>
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item>
                                            <SubjectRounded />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                id="contact-us-subject"
                                                label="Asunto"
                                                name="subject"
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                            <div className="text">
                                <div className={classes.margin}>
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item>
                                            <MessageRounded />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                id="contact-us-text"
                                                label="Mensaje"
                                                multiline
                                                minRows={5}
                                                variant="outlined"
                                                name="body"
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                        {/* <div className="writer-info">
                            <div className={classes.margin}>
                                <div className="info-text">
                                    <div>Escribenos a</div>
                                    <a href="mailto:e.institucional@secundariababeque.edu.do?Subject=[Contacto]">e.institucional@secundariababeque.edu.do</a>
                                </div>

                            </div>
                        </div> */}
                        {/* <div className="writer-info">
                            <div className="name">
                                <div className={classes.margin}>
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item>
                                            <AccountCircle />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                id="contact-us-name"
                                                label="Nombre"
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                            <div className="email">
                                <div className={classes.margin}>
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item>
                                            <AlternateEmail />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                id="contact-us-email"
                                                label="Correo"
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                            <div className="subject">
                                <div className={classes.margin}>
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item>
                                            <SubjectRounded />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                id="contact-us-subject"
                                                label="Asunto"
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                            <div className="text">
                                <div className={classes.margin}>
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item>
                                            <MessageRounded />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                id="contact-us-text"
                                                label="Mensaje"
                                                multiline
                                                rows={5}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div> */}
                        <div className="btn" onClick={handleSubmit}>Enviar</div>
                    </div>
                    
                </div>
                <div className="contact-us-map col-lg-6 col-md-12 col-sm-12">
                    <iframe
                        title="CSB"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.302196763058!2d-69.93274898572625!3d18.469965887438466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eaf89ddf1f9a775%3A0x7fd7549536768d36!2sBabeque%20High%20School!5e0!3m2!1sen!2sdo!4v1617643243181!5m2!1sen!2sdo"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy">
                    </iframe>
                </div>
            </div>
        </div>
    )
}

export default Contactus;