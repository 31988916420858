import React, { useContext } from 'react';
import { dialogContext } from '../../../../../../contexts/helpers/dialog/dialogContext'

// async function scrollTo(cb, ms) {
//     return new Promise(resolve => {
//         setTimeout(cb, ms);
//     });
// }

function AreaCard({ title, text, area }) {

    const DialogContext = useContext(dialogContext);
    const { handleOpen, setCommitment, setType } = DialogContext;

    const handleClick = (value) => {
        const { nombre, contenido, imagen } = value;
        setType("commitment");
        setCommitment({ nombre, contenido, imagen });
        handleOpen();
    }

    return (
        <div className="col-card col-lg-4 col-md-4 col-sm-6 col-xs-12">
            <div className="area-card-container">
                <div className="area-card">
                    <div
                        className="front"
                        onClick={() => {
                            handleClick({
                                nombre: title,
                                contenido: text,
                                imagen: area.imagen
                            })
                        }}
                    >
                        <div className="title">{title}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AreaCard;