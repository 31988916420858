// GENERAL LAYOUT FOR GLOBAL FOOTER

import React from 'react';
import './dropdown.scss';
// import DialogContentText from '@material-ui/core/DialogContentText';
import ReactMarkdown from 'react-markdown';


import { useState } from 'react'



const Dropdown = ({ values }) => {

    // const valuesState = values.map((val, indx)=>{
    //     return {
    //         [indx]:false
    //     }
    // })
    const [openedItem, setOpenedItem] = useState(0)

    // console.log(openedItem
    const handleChange = (index) => {
        // console.log(index)
        setOpenedItem(index)
    }
    return (
        <div className="dropdown">
            {
                values ? 
                values.map((item,index) => (
                    <div key={index} className="chooser-item">
                        <div style={{display:'inline-flex'}}>
                        <div className="item-label" onClick={()=>handleChange(index)}>
                            <div className="item-title">{item.titulo}</div>
                            <i className={`fas fa-sort-${index === openedItem ? "up":"down"}`} />
                        </div>
                        </div>
                        {
                            index === openedItem ?
                            (<div className="item-text">
                            {/* <DialogContentText> */}
                                <ReactMarkdown>
                                    {item.contenido}
                                </ReactMarkdown>
                            {/* </DialogContentText> */}
                        </div>):null
                        }

                    </div> 
                ))
                : null
            }
        </div>
    );
};

export default Dropdown;

