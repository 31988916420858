import React, { useContext, useEffect } from 'react';
import { baseURL } from '../../../../api';
import AwesomeSlider from 'react-awesome-slider';
import WithAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/src/styles';
import 'react-awesome-slider/dist/custom-animations/fold-out-animation.css';
import './NewsSlider.scss';

//Context
import MainContext from '../../../../contexts/pages/main/mainContext';


import { Typography } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';

const AutoplaySlider = WithAutoplay(AwesomeSlider);

const Slider = ({ ClassName }) => {

    const mainContext = useContext(MainContext);
    const { news } = mainContext;

    useEffect(() => {
    }, [news])
    // console.log(news)

    return (
        <div className={`${ClassName || 'slider'}`}>
            <AutoplaySlider
                // animation='foldOutAnimation'
                bullets={true}
                organicArrows={true}
                play={true}
                cancelOnInteraction={false} // should stop playing on user interaction
                interval={8000}
                showTimer={true}
                loaderBar="#fff"

            >
                {

                    news ? news.map((value) => (
                        <div key={value._id}  onClick={value.link ? ()=>window.open(`${value.link}`,'mywindow'):null}  className="news__container" data-src={`${baseURL}${value?.imagen?.url}`} >
                            {
                                value.contenido.trim() != "." ?
                                    (
                                        <div style={{ alignContent: 'flex-start' }}>
                                            <h1 className="news__heading"><b>#NOTICIAS</b>BABEQUE</h1>
                                            <h1 className="news__title">{value.titulo}</h1>
                                            <div className="news__body" >
                                                <ReactMarkdown>
                                                    {value.contenido.length > 300 ? `${value.contenido.substr(0, 300)}...` : value.contenido}
                                                </ReactMarkdown>
                                            </div>
                                        </div>
                                    ) : null
                            }
                        </div>
                    )) : null
                }

            </AutoplaySlider>
        </div>
    )
}

export default Slider;