import React, { useContext, useEffect, useState } from 'react';
import './AdministracionDocente.scss';
import AdministrativeProfiles from '../../../../../../Helpers/ProfileCard';
import TeamCarousel from '../../../../../../Helpers/TeamCarousel'

// CONTEXT
import AboutusDepartmentsContext from '../../../../../../../contexts/pages/aboutus/departments/departmentsContext';

const AdministracionDocente = () => {

    const [profiles, setProfiles] = useState(null)
    const [description, setDescription] = useState("")

    const aboutusDepartmentsContext = useContext(AboutusDepartmentsContext);
    const { departments } = aboutusDepartmentsContext;
    
    useEffect(() => {
        const getAdministrative = () => {
            if (!departments) return;
            const currProfiles = departments.filter(value => value.titulo.toUpperCase() === 'GESTIÓN ESTUDIANTIL Y DOCENTE');
            setDescription(currProfiles[0].descripcion)
            setProfiles(currProfiles[0].tarjetas_personal);
        }
        getAdministrative()
    }, [departments, profiles])
   

    return (
        <div className="administrative-board">
            <div className="administrative-board-inner">
                <div className="administrative-board-title">GESTIÓN ESTUDIANTIL Y DOCENTE</div>
                <div className="administrative-board-text">
                    <p>
                        {description??""}
                    </p>
                </div>
            </div>
            <div className="administrative-board-profiles-inner">
                {/* {profiles ? <AdministrativeProfiles width={250} height={250} spacing={4} src={profiles} small /> : null} */}
                {profiles ?   <TeamCarousel items={profiles}/>: null}
            </div>
        </div>
    )
}

export default AdministracionDocente;