import React, { useReducer } from 'react';
import DepartmentsContext from './departmentsContext';
import DepartmentsReducer from './departmentsReducer';
import api from '../../../../api';

import { TYPES } from '../../../types';

const DepartmentsState = (props) => {

    const initialState = {
        directiveBoard: {},
        academicBoard: {},
        studentsBoard: {},
        departments: [],
    };

    const [state, dispatch] = useReducer(DepartmentsReducer, initialState);

    const getDepartments = async () => {
        try {
            const result = await api.get('/conocenos-departamentos');
            
            dispatch({
                type: TYPES.GET_DEPARTMENTS,
                payload: result.data
            })
        } catch (error) {

        }
    }
    return (
        <DepartmentsContext.Provider
            value={{
                directiveBoard: state.directiveBoard,
                academicBoard: state.academicBoard,
                studentsBoard: state.studentsBoard,
                departments: state.departments,
                getDepartments,
            }}
        >
            {props.children}
        </DepartmentsContext.Provider>
    )
}

export default DepartmentsState;