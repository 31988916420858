import React, { useContext, useEffect } from 'react';
import { baseURL } from '../../../../api';
import AwesomeSlider from 'react-awesome-slider';
import WithAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/src/styles';
import 'react-awesome-slider/dist/custom-animations/fold-out-animation.css';
import './slider.scss';

//Context
import MainContext from '../../../../contexts/pages/main/mainContext';


const Slider = ({ ClassName }) => {

    const mainContext = useContext(MainContext);
    const { banner } = mainContext;

    const AutoplaySlider = WithAutoplay(AwesomeSlider);

    useEffect(() => {
    }, [banner])

    return (
        <div className={`${ClassName || 'slider'}`}>
            <AutoplaySlider
                // animation='foldOutAnimation'
                bullets={true}
                organicArrows={false}
                play={true}
                cancelOnInteraction={true} // should stop playing on user interaction
                interval={2000}
                showTimer={false}
            >
                { banner ? banner.map(value => (
                    value['enlace']
                        ? <div key={value._id} data-src={`${baseURL}${value.imagen.formats.large.url}`} className="slider-link" ><a href={`${value.enlace}`} target="_blank" rel="noopener noreferrer"><div className="slider-link"></div></a></div>
                        : <div key={value._id} data-src={`${baseURL}${value.imagen.formats.large.url}`} />
                )) : []}
            </AutoplaySlider>
        </div>
    )
}

export default Slider;