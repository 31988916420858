import React, { useContext } from 'react';
import './events.scss';
import EventsCarousel from './EventsCarousel';
import { baseURL } from '../../../../api';

// CONTEXT
import MainContext from '../../../../contexts/pages/main/mainContext';

const Events = () => {

    const mainContext = useContext(MainContext);
    const { calendar, getMonthName, } = mainContext;
    const eventsPdfURL = calendar ? calendar.hasOwnProperty('CalendarioGeneral') ? `${baseURL}${calendar.CalendarioGeneral.url}` : "" :""
    return (
        <div className="events">
            <div className="events-header">
                <p className="events-header-title titles">{`calendario ${calendar
                        ?
                        calendar.mes
                        :
                        getMonthName((new Date()).getMonth())
                    } `}{new Date().getFullYear()}</p>
                <div className="divisor"></div>
                {/* <div className="events-header-figures">
                    <FilledTriangle Style={{
                        transform: 'translate(10px,-210px) rotate(310deg) scale(4)'
                    }} />
                    <HollowTriangle Style={{
                        transform: 'translate(-90px,-240px) rotate(350deg) scale(0.9)'
                    }} />
                </div> */}
            </div>
            <div className="events-body">
                <EventsCarousel />
                <div className="events-footer-button">
                    {/* <Button className="events-button" href={eventsPdfURL} target="_blank">ver todos</Button> */}
                    
                    <a href={eventsPdfURL} target="_blank" rel="noreferrer"><div className="events-button">Ver todos</div></a>
                </div>
            </div>
        </div>
    )
}

export default Events;