import { TYPES } from '../../types';

const mainReducer = (state, action) => {
    switch(action.type){
        case TYPES.GET_CURRICULUM:
            return {
                ...state,
                banner: action.payload.imagen || null,
                description: action.payload.descripcion || null,
                subjects: action.payload.competencias || null,
                evaluation: action.payload.evaluacion || null,
                ibBachelor: action.payload.bachilleratoIb || null,
            }
        default:
            return state
    }
}

export default mainReducer;