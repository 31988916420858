import React, { useContext } from 'react';
import "./ButtonCard.scss";

// CONTEXT
import { dialogContext } from '../../../contexts/helpers/dialog/dialogContext'

const ButtonCard = ({ title, actividad }) => {

    const DialogContext = useContext(dialogContext)
    const { handleOpen, setClubes, setType } = DialogContext;

    const handleClick = (value) => {
        setType("subActivity")
        const { titulo, descripcion, imagenes } = value;
        setClubes({ titulo, descripcion, imagenes });
        handleOpen();
    }

    return (
        // <div className="col-card col-lg-3 col-md-4 col-sm-6 col-xs-12">
        //     <div className="area-card-container">
        //         <div className="area-card">
        //             <div
        //                 className="front"
        //                 onClick={() => {
        //                     handleClick(actividad)
        //                 }}
        //             >
        //                 <div className="title">{title}</div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        
            <div
                className="activityTile"
                onClick={() => {
                    handleClick(actividad)
                }}
            // active={subject.titulo === subjectState.titulo}
            // onClick={()=>handleSubjectState(currSubject)}
            >
                <div className="activity-title">{title.toUpperCase()}</div>

            </div>
    )
}

export default ButtonCard;