// Principal Pages
import Main from './Main';
import Aboutus from './Aboutus';
import Curriculum from './Curriculum';
import Contactus from './Contactus';
import Activities from './Activities';
import CentroCultural from './CentroCultural';

// Secundary Pages
import aboutusPages from './Aboutus/Subpages';
import curriculumPages from './Curriculum/Subpages';

// Error Pages
import FourZeroFour from './404';

// Under Construction Page
import Construction from './Construction';

//Students Area Page
import Students from './Students';

// Principal Pages
const Pages = {
    Main,
    Aboutus,
    Curriculum,
    Activities,
    CentroCultural,
    Contactus,
    FourZeroFour,
    Construction,
    Students
}

// About us Secundary Pages
export const AboutusPages = {...aboutusPages}

// Curriculum Secundary Pages
export const CurriculumPages = {...curriculumPages}

export default Pages;