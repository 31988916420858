import React, { useState, useContext, useEffect } from 'react';
import './enlace.scss';
import EnlaceProfiles from '../../../../../../Helpers/ProfileCard';

// CONTEXT
import AboutusDepartmentsContext from '../../../../../../../contexts/pages/aboutus/departments/departmentsContext';
import TeamCarousel from '../../../../../../Helpers/TeamCarousel';

const Administracion = () => {

    const [profiles, setProfiles] = useState(null)
    const [description, setDescription] = useState("")

    const aboutusDepartmentsContext = useContext(AboutusDepartmentsContext);
    const { departments } = aboutusDepartmentsContext;

    useEffect(() => {
        const getAdministrative = () => {
            if (!departments) return;
            const currProfiles = departments.filter(value => value.titulo.toUpperCase() === 'ENLACE INSTITUCIONAL');
            setDescription(currProfiles[0].descripcion);
            setProfiles(currProfiles[0].tarjetas_personal);
        }
        getAdministrative()
    }, [departments, profiles])
    return (
        <div className="enlace-board">
            <div className="enlace-board-inner">
                <div className="enlace-board-title">ENLACE INSTITUCIONAL</div>
                <div className="enlace-board-text">
                    <p>
                        {description}
                    </p>
                </div>
            </div>
            <div className="enlace-board-profiles-inner">
                {/* {profiles ? <EnlaceProfiles width={250} height={250} spacing={4} src={profiles} small /> : null} */}
                <TeamCarousel items={profiles}/>
            </div>
        </div>
    )
}

export default Administracion;