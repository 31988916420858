import React from 'react';
import './vitualTour.scss';

const VitualTour = () => {
    return (
        <div className="vitual-tour">
        </div>
    )
}

export default VitualTour;