import React, { useEffect, useContext } from 'react';
import './optativas.scss';
import DialogContentText from '@material-ui/core/DialogContentText';
import ReactMarkdown from 'react-markdown';
import ButtonCard from "../../../../Helpers/ButtonCard"


// CONTEXT
import ActivitiesContext from '../../../../../contexts/pages/activities/activitiesContext';

const Optativas = () => {

    const activitiesContext = useContext(ActivitiesContext);
    const { optativas, clubes } = activitiesContext;

    useEffect(() => {
    }, [])
    // console.log(optativas)
    return (
        <div className="optativas activity-subpage">
            <div className="optativas-inner act">
                <div className="optativas-title">{optativas ? optativas.titulo.toUpperCase() : "OPTATIVAS"}</div>
                <div className="optativas-text">
                    {optativas
                        ?
                        // <DialogContentText>
                            <ReactMarkdown>
                                {optativas.contenido}
                            </ReactMarkdown>
                        // </DialogContentText>
                        :
                        <p>
                            La Secundaria Babeque es un centro educativo auspiciado por la Fundación Pedagógica Dominicana Inc.,
                            institución sin fines de lucro creada en el año de 1977  por un grupo de madres y padres cuyos hijos
                            terminaban el sexto grado del Colegio Babeque Inicial y Primaria. Con esta iniciativa se proponían
                            velar por el desarrollo de los valores universales, la dominicanidad y la formación integral de sus hijos e hijas.

                            Se seleccionó el nombre Babeque, voz de origen taíno que significa tierra de oro, por considerar a
                            la niñez y la juventud como la esperanza y el tesoro más preciado de una nación.
                        </p>
                    }
                </div>
                {
                    clubes ?
                    (
                        <div className="areas">
                            <div className="optativa-grid">
                                {
                                    clubes.subactividad.map(actividad => {
                                        return (
                                            <ButtonCard
                                                key={actividad._id}
                                                title={String(actividad.titulo).toUpperCase() || ""}
                                                actividad={actividad}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </div>                                  
                    ):
                    null
                }
            </div>
        </div>
    )
}

export default Optativas;