import React, { useEffect, useContext, useState } from 'react';
import Carousel from 'better-react-carousel';
import EventTile from './EventTile';

// CONTEXT
import MainContext from '../../../../../contexts/pages/main/mainContext';
import { baseURL } from '../../../../../api';

const EventsCarousel = () => {

    const mainContext = useContext(MainContext);
    const { events, calendar, getMonthNumber,eventBg } = mainContext;
    const eventBgUrl =  eventBg ? `${baseURL}${eventBg?.url}`:""

    const [fullCalendar, setFullCalendar] = useState([]);

    const days = function (month, year) {
        return new Date(year, month, 0).getDate();
    };


    useEffect(() => {
        const mDays = calendar ? days(getMonthNumber(calendar.mes.toUpperCase().trim()) + 1, (new Date()).getFullYear()) : days((new Date()).getMonth() + 1, (new Date()).getFullYear())
        const fillCalendar = () => {
            const monthArray = new Array(mDays + 1)
            monthArray.pop()
            if (events) {
                events.forEach(event => {
                    monthArray[Number(event.fecha.split("-")[2])] = event
                })
            } else {

            }
            for (let i = 1; i <= mDays; i++) {
                if (!monthArray[i]) {
                    monthArray[i] = {
                        contenido: "",
                        fecha: null,
                        day: i,
                        titulo: "",
                    }
                } else {
                    monthArray[i] = {
                        ...monthArray[i],
                        day: i,
                    }
                }
            }
            monthArray.shift();
            setFullCalendar(monthArray)
        }
        fillCalendar()
        // eslint-disable-next-line
    }, [events, calendar])

    return (
        <div className="events-body-tiles">
            <Carousel
                className="event-body-carousel"
                cols={7}
                rows={2}
                gap={15}
                containerStyle={{ background: 'transparent' }}
                responsiveLayout={[
                    {
                        breakpoint: 2000,
                        cols: 7
                    },
                    {
                        breakpoint: 1300,
                        cols: 5
                    },
                    {
                        breakpoint: 1000,
                        cols: 4
                    },
                    {
                        breakpoint: 990,
                        cols: 4
                    },
                    {
                        breakpoint: 780,
                        cols: 3
                    }
                ]}
                mobileBreakpoint={670}
            >
                {fullCalendar
                    ?
                    fullCalendar.map(value => {
                        if (!value.contenido) value.contenido=""
                        return (
                            <Carousel.Item className="event-tile-grid" key={value.fecha ? value.fecha.split("-")[2] : value.day}>
                                <EventTile
                                    number={value.fecha ? Number(value.fecha.split("-")[2]) : Number(value.day)}
                                    title={value.titulo}
                                    date={value.fecha}
                                    content={`${value.contenido.substr(0, 120)}...`}
                                    value={value}
                                    eventBackground={eventBgUrl??""}
                                />
                            </Carousel.Item>)
                    })
                    : null
                }
            </Carousel>
        </div>

    )
}

export default EventsCarousel;