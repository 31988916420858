import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './fourZeroFour.scss';

const FourZeroFour = () => {
    return (
        <div className="subpage fourZeroFour">
            <h1 style={{color:"white"}}>404</h1>
            <h1>404</h1>
            <p>Oops! Parece que se ha perdido en la Secundaria Babebeque</p>
            <Link to="/" className="fzf-button">
                <Button><i className="icon-home"></i> Click aquí para ir a la Recepción</Button>
            </Link>
            <h1 style={{color:"white"}}>404</h1>
            
        </div>
    )
}

export default FourZeroFour;