import React, { useEffect, useContext, useState } from 'react';
import './activities.scss';

import { CSSTransition } from 'react-transition-group';
import Slider from './Slider';
import Links from './Links';
import Dialog from '../../Helpers/Dialog';

// Subpages
import FeriaCultural from './Subpages/FeriaCultural';
import Gozabeque from './Subpages/Gozabeque';
import Musical from './Subpages/Musical';
import Clubes from './Subpages/Clubes';
import Optativas from './Subpages/Optativas';
import MNU from './Subpages/MNU';


// CONTEXT
import ActivitiesContext from '../../../contexts/pages/activities/activitiesContext';
import { secundaryPagesContext } from '../../../contexts/pages/activities/secundaryPages/secundaryPagesContext';
import Deporte from './Subpages/Deporte/Deporte';



const ActivitiesPage = () => {

    const activitiesContext = useContext(ActivitiesContext);
    const { getActivities } = activitiesContext;

    // console.log(getActivities())

    const activitiesPagesContext = useContext(secundaryPagesContext);
    const {
        clubesState,
        setClubesState,
        feriaCulturalState,
        setFeriaCulturalState,
        gozabequeState,
        setGozabequeState,
        MNUState: modeloNacionesUnidasState,
        setMNUState,
        musicalState,
        setMusicalState,
        optativasState,
        setOptativasState,
        deporteState,
        setDeporteState,
        blankState,
        setBlankState
    } = activitiesPagesContext;

    const [linkState, setLinkState]  = useState({
        clubesLinkState:false,
        feriaCulturalLinkState:false,
        gozabequeLinkState:false,
        mnuLinkState:false,
        musicalLinkState:false,
        optativasLinkState:false,
        deportLinkState:false
    })

    const handleCurrentLinkState = (newLinkState) => {
        setLinkState({
            ...newLinkState
        })
    }

    const animationStates = {
        clubesState,
        setClubesState,
        feriaCulturalState,
        setFeriaCulturalState,
        gozabequeState,
        setGozabequeState,
        modeloNacionesUnidasState,
        setMNUState,
        musicalState,
        setMusicalState,
        optativasState,
        setOptativasState,
        deporteState,
        setDeporteState,
        blankState,
        setBlankState
    }

    useEffect(() => {
        getActivities()
        // eslint-disable-next-line 
    }, [])
    // console.log(linkState)
    return (
        <div className="row activities-body" >
            <Slider ClassName={'activities-slider'} />
            
            <CSSTransition
                in={feriaCulturalState.active}
                timeout={500}
                classNames="feriaCultural-subarea"
                unmountOnExit
                onEnter={() => {
                    setClubesState({ enter: false, active: false });
                    setGozabequeState({ enter: false, active: false });
                    setMNUState({ enter: false, active: false });
                    setMusicalState({ enter: false, active: false });
                    setOptativasState({ enter: false, active: false });
                    setDeporteState({enter: false, active: false})
                }}
                onExited={() => {
                    if (clubesState.enter) setClubesState({ ...clubesState, active: true });
                    if (gozabequeState.enter) setGozabequeState({ ...gozabequeState, active: true });
                    if (modeloNacionesUnidasState.enter) setMNUState({ ...modeloNacionesUnidasState, active: true });
                    if (musicalState.enter) setMusicalState({ ...musicalState, active: true });
                    if (optativasState.enter) setOptativasState({ ...optativasState, active: true });
                    if (deporteState.enter) setDeporteState({ ...deporteState, active: true });
                }}
            >
                <FeriaCultural />
            </CSSTransition>

            <CSSTransition
                in={gozabequeState.active}
                timeout={500}
                classNames="gozabeque-subarea"
                unmountOnExit
                onEnter={() => {
                    setClubesState({ enter: false, active: false });
                    setFeriaCulturalState({ enter: false, active: false });
                    setMNUState({ enter: false, active: false });
                    setMusicalState({ enter: false, active: false });
                    setOptativasState({ enter: false, active: false });
                    setDeporteState({enter: false, active: false})
                }}
                onExited={() => {
                    if (clubesState.enter) setClubesState({ ...clubesState, active: true });
                    if (feriaCulturalState.enter) setFeriaCulturalState({ ...feriaCulturalState, active: true });
                    if (modeloNacionesUnidasState.enter) setMNUState({ ...modeloNacionesUnidasState, active: true });
                    if (musicalState.enter) setMusicalState({ ...musicalState, active: true });
                    if (optativasState.enter) setOptativasState({ ...optativasState, active: true });
                    if (deporteState.enter) setDeporteState({ ...deporteState, active: true });
                }}
            >
                <Gozabeque />
            </CSSTransition>

            <CSSTransition
                in={musicalState.active}
                timeout={500}
                classNames="musical-subarea"
                unmountOnExit
                onEnter={() => {
                    setClubesState({ enter: false, active: false });
                    setFeriaCulturalState({ enter: false, active: false });
                    setGozabequeState({ enter: false, active: false });
                    setMNUState({ enter: false, active: false });
                    setOptativasState({ enter: false, active: false });
                    setDeporteState({enter: false, active: false})
                }}
                onExited={() => {
                    if (clubesState.enter) setClubesState({ ...clubesState, active: true });
                    if (feriaCulturalState.enter) setFeriaCulturalState({ ...feriaCulturalState, active: true });
                    if (gozabequeState.enter) setGozabequeState({ ...gozabequeState, active: true });
                    if (modeloNacionesUnidasState.enter) setMNUState({ ...modeloNacionesUnidasState, active: true });
                    if (optativasState.enter) setOptativasState({ ...optativasState, active: true });
                    if (deporteState.enter) setDeporteState({ ...deporteState, active: true });
                }}
            >
                <Musical />
            </CSSTransition>

            {/* <CSSTransition
                in={clubesState.active}
                timeout={500}
                classNames="clubes-subarea"
                unmountOnExit
                onEnter={() => {
                    setFeriaCulturalState({ enter: false, active: false });
                    setGozabequeState({ enter: false, active: false });
                    setMNUState({ enter: false, active: false });
                    setMusicalState({ enter: false, active: false });
                    setOptativasState({ enter: false, active: false });
                }}
                onExited={() => {
                    if (feriaCulturalState.enter) setFeriaCulturalState({ ...feriaCulturalState, active: true });
                    if (gozabequeState.enter) setGozabequeState({ ...gozabequeState, active: true });
                    if (modeloNacionesUnidasState.enter) setMNUState({ ...modeloNacionesUnidasState, active: true });
                    if (musicalState.enter) setMusicalState({ ...musicalState, active: true });
                    if (optativasState.enter) setOptativasState({ ...optativasState, active: true });
                }}
            >
                <Clubes />
            </CSSTransition> */}

            <CSSTransition
                in={optativasState.active}
                timeout={500}
                classNames="optativas-subarea"
                unmountOnExit
                onEnter={() => {
                    setClubesState({ enter: false, active: false });
                    setFeriaCulturalState({ enter: false, active: false });
                    setGozabequeState({ enter: false, active: false });
                    setMNUState({ enter: false, active: false });
                    setMusicalState({ enter: false, active: false });
                    setDeporteState({enter: false, active: false})
                }}
                onExited={() => {
                    if (clubesState.enter) setClubesState({ ...clubesState, active: true });
                    if (feriaCulturalState.enter) setFeriaCulturalState({ ...feriaCulturalState, active: true });
                    if (gozabequeState.enter) setGozabequeState({ ...gozabequeState, active: true });
                    if (modeloNacionesUnidasState.enter) setMNUState({ ...modeloNacionesUnidasState, active: true });
                    if (musicalState.enter) setMusicalState({ ...musicalState, active: true });
                    if (deporteState.enter) setDeporteState({ ...deporteState, active: true });
                }}
            >
                <Optativas />
            </CSSTransition>

            <CSSTransition
                in={modeloNacionesUnidasState.active}
                timeout={500}
                classNames="mnu-subarea"
                unmountOnExit
                onEnter={() => {
                    setClubesState({ enter: false, active: false });
                    setFeriaCulturalState({ enter: false, active: false });
                    setGozabequeState({ enter: false, active: false });
                    setOptativasState({ enter: false, active: false });
                    setMusicalState({ enter: false, active: false });
                    setDeporteState({enter: false, active: false})
                }}
                onExited={() => {
                    if (clubesState.enter) setClubesState({ ...clubesState, active: true });
                    if (feriaCulturalState.enter) setFeriaCulturalState({ ...feriaCulturalState, active: true });
                    if (gozabequeState.enter) setGozabequeState({ ...gozabequeState, active: true });
                    if (optativasState.enter) setOptativasState({ ...optativasState, active: true });
                    if (musicalState.enter) setMusicalState({ ...musicalState, active: true });
                    if (deporteState.enter) setDeporteState({ ...deporteState, active: true });
                }}
            >
                <MNU />
            </CSSTransition>

            <CSSTransition
                in={deporteState.active}
                timeout={500}
                classNames="feriaCultural-subarea"
                unmountOnExit
                onEnter={() => {
                    setClubesState({ enter: false, active: false });
                    setGozabequeState({ enter: false, active: false });
                    setMNUState({ enter: false, active: false });
                    setMusicalState({ enter: false, active: false });
                    setFeriaCulturalState({ enter: false, active: false });
                    setOptativasState({ enter: false, active: false });
                }}
                onExited={() => {
                    if (clubesState.enter) setClubesState({ ...clubesState, active: true });
                    if (gozabequeState.enter) setGozabequeState({ ...gozabequeState, active: true });
                    if (feriaCulturalState.enter) setFeriaCulturalState({ ...feriaCulturalState, active: true });
                    if (modeloNacionesUnidasState.enter) setMNUState({ ...modeloNacionesUnidasState, active: true });
                    if (musicalState.enter) setMusicalState({ ...musicalState, active: true });
                    if (optativasState.enter) setOptativasState({ ...optativasState, active: true });
                }}
            >
                <Deporte />
            </CSSTransition>

            <CSSTransition
                in={blankState.active}
                timeout={500}
                classNames="optativas-subarea"
                unmountOnExit
                onEnter={() => {
                    setClubesState({ enter: false, active: false });
                    setFeriaCulturalState({ enter: false, active: false });
                    setGozabequeState({ enter: false, active: false });
                    setMNUState({ enter: false, active: false });
                    setOptativasState({ enter: false, active: false });
                    setMusicalState({ enter: false, active: false });
                }}
                onExited={() => {
                    if (clubesState.enter) setClubesState({ ...clubesState, active: true });
                    if (feriaCulturalState.enter) setFeriaCulturalState({ ...feriaCulturalState, active: true });
                    if (gozabequeState.enter) setGozabequeState({ ...gozabequeState, active: true });
                    if (modeloNacionesUnidasState.enter) setMNUState({ ...modeloNacionesUnidasState, active: true });
                    if (musicalState.enter) setMusicalState({ ...musicalState, active: true });
                    if (optativasState.enter) setOptativasState({ ...optativasState, active: true });
                }}
            >
                <div className="activity-subpage" style={{transition: "all 0.3s ease-in", paddingTop: "30vh"}}>
                  
                </div>
            </CSSTransition>

            <div className="subpage activies-container">
                <Links animationStates={animationStates} linkState={linkState} handleCurrentLinkState={handleCurrentLinkState}/>
            </div>
            
            <Dialog />
        </div>
    );
}

export default ActivitiesPage;