import React from 'react';
import './courseTile.scss';

const CourseTile = ({ course, setSelectedCourse }) => {
    return (
        <div className="course-tile">
            <div className="course-tile-inner">
                <div
                    className="tile hvr-outline-out"
                    onMouseEnter={() => { setSelectedCourse(course) }}
                >
                    {course}
                </div>
            </div>
        </div>
    )
}

export default CourseTile;