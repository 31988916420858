import AwesomeSlider from 'react-awesome-slider';
import WithAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/src/styles';
import './slider.scss';
import { useContext } from 'react'
import { baseURL } from '../../../../api';
//CONTEXT
import CurriculumContext from '../../../../contexts/pages/curriculum/curriculumContext';




const Slider = ({ ClassName }) => {
    const curriculumContext = useContext(CurriculumContext);
    const { banner } = curriculumContext;
    const AutoplaySlider = WithAutoplay(AwesomeSlider);
    return (
        <div className={`${ClassName || 'slider'}`}>
            <AutoplaySlider
                bullets={true}
                play={true}
                cancelOnInteraction={false} // should stop playing on user interaction
                interval={2000}
                showTimer={false}
            >
                {/* <div data-src={Images.image4} /> */}
                {banner
                    ?
                    banner.map(value => {
                        return <div key={value._id} data-src={`${baseURL}${value.url}`} />
                    })
                    :
                    <div data-src={""} />
                }
            </AutoplaySlider>
        </div>

    )
}
export default Slider;