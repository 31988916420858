import axios from 'axios';

// Base URL for connection to Headless CMS API
// PRODUCTION
// export const baseURL = process.env.REACT_APP_BASE_URL

// DEVELOPMENT
export const baseURL = 'https://admin.secundariababeque.edu.do'

// Create axios instance to use it globally based on the baseUrl
const api = axios.create({
    baseURL
});

export default api;