import React, { useState, useContext, useEffect } from 'react';
import './commitment.scss';
import AreaCard from './AreaCard';
import DialogContentText from '@material-ui/core/DialogContentText';
import ReactMarkdown from 'react-markdown';

// CONTEXT
import AboutusContext from '../../../../../contexts/pages/aboutus/aboutusContext';

import Slider from '../../../../Globals/Slider'

import Dropdown from '../../../../Globals/Dropdown'


const Commitment = () => {

    const aboutusContext = useContext(AboutusContext)
    const { commitment } = aboutusContext;

    // States from switching between areas
    const [blankState, setBlankState] = useState({
        enter: false,
        active: true
    });
    const [educationState, setEducationState] = useState({
        enter: false,
        active: false
    });
    const [environmentState, setEnvironmentState] = useState({
        enter: false,
        active: false
    });
    const [healthState, setHealthState] = useState({
        enter: false,
        active: false
    });
    const [animalProtectionState, setAnimalProtectionState] = useState({
        enter: false,
        active: false
    });
    const [coolaborationState, setCoolaborationState] = useState({
        enter: false,
        active: false
    });
    const [casState, setCasState] = useState({
        enter: false,
        active: false
    });
    const animationStates = {
        blankState,
        setBlankState,
        educationState,
        setEducationState,
        environmentState,
        setEnvironmentState,
        healthState,
        setHealthState,
        animalProtectionState,
        setAnimalProtectionState,
        coolaborationState,
        setCoolaborationState,
        casState,
        setCasState,
    };

    const payload = commitment.area.map((item)=>{
        return {
            titulo: item.nombre,
            contenido: item.contenido
        }
    }) 

    useEffect(() => {
    }, [commitment])

    return (
        <div className="aboutus-subpage commitment">
            {commitment ? ( <Slider bannerData={commitment.banner} />) :null}
            <div className="commitment-inner">
                <div className="commitment-title">COMPROMISO SOCIAL</div>
                <div className="commitment-text">
                    <DialogContentText>
                        <ReactMarkdown>
                            {commitment.contenido}
                        </ReactMarkdown>
                    </DialogContentText>
                </div>
               
              
                {payload ? (<Dropdown values={payload} />):null}
                {/* <div className="areas">
                    <div className="row">
                        {commitment
                            ?
                            commitment.area.map(area => {
                                return (
                                    <AreaCard
                                        key={area._id}
                                        animationStates={animationStates}
                                        title={String(area.nombre).toUpperCase() || ""}
                                        text={area.contenido || ""}
                                        area={area}
                                    />
                                )
                            })
                            : null
                        }
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Commitment;