import React, { useContext } from 'react';
import Carousel from 'better-react-carousel';
import { Card } from 'react-bootstrap';

// CONTEXT
import { dialogContext } from '../../../../../contexts/helpers/dialog/dialogContext';

const EventTile = ({ number, title, content, value, eventBackground }) => {

    const DialogContext = useContext(dialogContext);
    const { handleOpen, setContent } = DialogContext;

    const tilesColors = ['#6da093', '#0a415d', '#b80421'];
    // const random = Math.floor(Math.random() * tilesColors.length);

    const handleClick = (value) => {
        const { titulo, fecha, contenido } = value;
        setContent({ titulo, fecha, contenido });
        handleOpen();
    }

    return (
        <Carousel.Item>
            <div className="event-tile">
                <Card onClick={() => {
                    if (value.fecha) {
                        handleClick(value)
                    }
                }}>
                    <div className="event-tile-img" style={
                        {
                            background: value.fecha ? (
                                eventBackground ? `url(${eventBackground})` : tilesColors[1]
                            ) : tilesColors[0]
                        }}>
                        <span>{number}</span>
                    </div>
                    <Card.Body>
                        <Card.Title className="event-tile-title">{title || ""}</Card.Title>
                        <Card.Text className="event-tile-text">{content === '...' || ""}</Card.Text>
                    </Card.Body>
                </Card>
            </div>
        </Carousel.Item>
    )


}

export default EventTile;