import React from 'react';
import { Image } from 'react-bootstrap'

const DirectorsCard = ({ name, charge, daughters, profession, hobbies, image, width }) => {
    return (
        <div style={{ width: width }} className="directors-card">
            <div className="card-inner">
            <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="card-left">
                            <div className="card-image">
                                <Image src={image} alt={`image-${name}`}></Image>
                                <div className="yellow-circle"></div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <div className="card-right">
                            {/* <div className="info-parent">{daughters}</div> */}
                            <div className="card-name">
                                {name}
                            </div>
                            <div className="card-charge">
                                {charge}
                            </div>
                            <div className="info-profession">
                                {/* {profession.length < 500 ?  profession : `${profession.substr(0,500)}...`} */}
                                {profession}
                            </div>
                            {/* <div className="info-hobbies">
                                <ul>
                                    {hobbies ? hobbies.map((hobbie, i) => <li key={i}>{hobbie}</li>) : null}
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DirectorsCard;