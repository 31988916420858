import React from 'react';
import { Card } from 'react-bootstrap';

const CocurricularCard = ({ title, text, image, variant, width }) => {
  
    return (
        <Card style={{ width: width }} className="cocurricular-card">
            <header className="cocurricular-card-header">
                <Card.Img className="cocurricular-logo" variant={variant} src={image}/>
                <div className="cocurricular-card-desciption">
                    <i></i>
                    <p>{text}</p>
                </div>
               
            </header>
            {/* <div class="circle">
                    <span>
                        {text}
                    </span>
                </div> */}
            <Card.Body >
                <Card.Title className="cocurricular-card-text">{String(title).toUpperCase()}</Card.Title>
            </Card.Body>
        </Card>
    )
}

export default CocurricularCard;