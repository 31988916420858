import React, { useContext, useEffect } from 'react';
import VirtualToolsCard from '../VirtualTools/VirtualToolsCard';
import DialogContentText from '@material-ui/core/DialogContentText';
import ReactMarkdown from 'react-markdown';
import './allies.scss';

// CONTEXT
import AboutusContext from '../../../../../contexts/pages/aboutus/aboutusContext';

import Slider from '../../../../Globals/Slider'

const Allies = () => {
    const aboutusContext = useContext(AboutusContext);
    const { allies } = aboutusContext;

    useEffect(() => {
    }, [allies])

    return (
        <div className="aboutus-subpage vitual-tools">
            {allies ? ( <Slider bannerData={allies.banner} />) :null}
            <div className="vitual-tools-inner">
                <div className="vitual-tools-title">ALIADOS Y ACREDITACIONES</div>
                <div className="vitual-tools-text">
                    <DialogContentText>
                        <ReactMarkdown>
                            {allies.contenido}
                        </ReactMarkdown>
                    </DialogContentText>
                </div>
                <div className="areas">
                    <div className="row">
                        {allies
                            ?
                            allies.area.map(tool => {
                                return (
                                    <VirtualToolsCard
                                        key={tool._id}
                                        // animationStates={animationStates}
                                        title={String(tool.nombre).toUpperCase() || ""}
                                        text={tool.contenido || ""}
                                        image={tool.imagen}

                                    />
                                )
                            })
                            : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Allies;