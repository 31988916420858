import React, { useEffect, useContext, useState } from 'react';
import './aboutus.scss';
import { CSSTransition } from 'react-transition-group';
import Slider from './Slider';
import Links from './Links';

// Dialog Window
import Dialog from '../../Helpers/Dialog';

// Video Player
import VideoPlayer from '../../Helpers/VideoPlayer';

// Subpages
import History from './Subpages/History';
import Philosophy from './Subpages/Philosophy';
import Commitment from './Subpages/Commitment';
import Departments from './Subpages/Departments';
import VirtualTools from './Subpages/VirtualTools';
import VirtualTour from './Subpages/VirtualTour';
import Allies from './Subpages/Allies/Allies';

// CONTEXT
import AboutusContext from '../../../contexts/pages/aboutus/aboutusContext';
import AboutusDepartmentsContext from '../../../contexts/pages/aboutus/departments/departmentsContext';
import { secundaryPagesContext } from '../../../contexts/pages/aboutus/secundaryPages/secundaryPagesContext';
import { videoPlayerContext } from '../../../contexts/helpers/videoPlayer/videoPlayerContext';


const AboutusPage = () => {

    const aboutusContext = useContext(AboutusContext);
    const { getAboutus } = aboutusContext;

    const aboutusDepartmentsContext = useContext(AboutusDepartmentsContext)
    const { getDepartments } = aboutusDepartmentsContext;

    const VideoPlayerContext = useContext(videoPlayerContext);
    const { handleDeactivatePlayer } = VideoPlayerContext;

    const [linkState, setLinkState]  = useState({
        historyLinkState:false,
        philosophyLinkState:false,
        commitmentLinkState:false,
        virtualToolsLinkState:false,
        virtualTourLinkState:false,
        departmentsLinkState:false,
        alliesLinkState:false
    })

    const handleCurrentLinkState = (newLinkState) => {
        setLinkState({
            ...newLinkState
        })
    }

    const aboutusPagesContext = useContext(secundaryPagesContext);
    const {
        historyState,
        setHistoryState,
        philosophyState,
        setPhilosophyState,
        commitmentState,
        setCommitmentState,
        virtualToolsState,
        setVirtualToolsState,
        virtualTourState,
        setVirtualTourState,
        departmentsState,
        setDepartmentsState,
        alliesState,
        setAlliesState
    } = aboutusPagesContext;

    const animationStates = {
        historyState,
        setHistoryState,
        philosophyState,
        setPhilosophyState,
        commitmentState,
        setCommitmentState,
        alliesState,
        setAlliesState,
        virtualToolsState,
        setVirtualToolsState,
        virtualTourState,
        setVirtualTourState,
        departmentsState,
        setDepartmentsState
    }

    useEffect(() => {
        getAboutus()
        getDepartments()
        handleDeactivatePlayer()
        // eslint-disable-next-line 
    }, [])

    return (
        <div className="row aboutus-body">
            {/* <Slider ClassName={'aboutus-slider'} /> */}

            <CSSTransition
                in={historyState.active}
                timeout={500}
                classNames="history-subarea"
                unmountOnExit
                onEnter={() => {
                    setPhilosophyState({ enter: false, active: false });
                    setCommitmentState({ enter: false, active: false });
                    setVirtualToolsState({ enter: false, active: false });
                    setVirtualTourState({ enter: false, active: false });
                    setDepartmentsState({ enter: false, active: false });
                    setAlliesState({ enter: false, active: false });
                }}
                onExited={() => {
                    if (philosophyState.enter) setPhilosophyState({ ...philosophyState, active: true });
                    if (commitmentState.enter) setCommitmentState({ ...commitmentState, active: true });
                    if (virtualToolsState.enter) setVirtualToolsState({ ...virtualToolsState, active: true });
                    if (virtualTourState.enter) setVirtualTourState({ ...virtualTourState, active: true });
                    if (departmentsState.enter) setDepartmentsState({ ...departmentsState, active: true });
                    if (alliesState.enter) setAlliesState({ ...alliesState, active: true });
                }}
            >
                <History />
            </CSSTransition>

            <CSSTransition
                in={philosophyState.active}
                timeout={500}
                classNames="philosophy-subarea"
                unmountOnExit
                onEnter={() => {
                    setHistoryState({ enter: false, active: false });
                    setCommitmentState({ enter: false, active: false });
                    setVirtualToolsState({ enter: false, active: false });
                    setVirtualTourState({ enter: false, active: false });
                    setDepartmentsState({ enter: false, active: false });
                    setAlliesState({ enter: false, active: false });
                }}
                onExited={() => {
                    if (historyState.enter) setHistoryState({ ...historyState, active: true });
                    if (commitmentState.enter) setCommitmentState({ ...commitmentState, active: true });
                    if (virtualToolsState.enter) setVirtualToolsState({ ...virtualToolsState, active: true });
                    if (virtualTourState.enter) setVirtualTourState({ ...virtualTourState, active: true });
                    if (departmentsState.enter) setDepartmentsState({ ...departmentsState, active: true });
                    if (alliesState.enter) setAlliesState({ ...alliesState, active: true });
                }}
            >
                <Philosophy />
            </CSSTransition>

            <CSSTransition
                in={commitmentState.active}
                timeout={500}
                classNames="commitment-subarea"
                unmountOnExit
                onEnter={() => {
                    setHistoryState({ enter: false, active: false });
                    setPhilosophyState({ enter: false, active: false });
                    setVirtualToolsState({ enter: false, active: false });
                    setVirtualTourState({ enter: false, active: false });
                    setDepartmentsState({ enter: false, active: false });
                    setAlliesState({ enter: false, active: false });
                }}
                onExited={() => {
                    if (historyState.enter) setHistoryState({ ...historyState, active: true });
                    if (philosophyState.enter) setPhilosophyState({ ...philosophyState, active: true });
                    if (departmentsState.enter) setDepartmentsState({ ...departmentsState, active: true });
                    if (virtualTourState.enter) setVirtualTourState({ ...virtualTourState, active: true });
                    if (virtualToolsState.enter) setVirtualToolsState({ ...virtualToolsState, active: true });
                    if (alliesState.enter) setAlliesState({ ...alliesState, active: true });
                }}
            >
                <Commitment />
            </CSSTransition>

            <CSSTransition
                in={alliesState.active}
                timeout={500}
                classNames="allies-subarea"
                unmountOnExit
                onEnter={() => {
                    setHistoryState({ enter: false, active: false });
                    setPhilosophyState({ enter: false, active: false });
                    setVirtualToolsState({ enter: false, active: false });
                    setVirtualTourState({ enter: false, active: false });
                    setDepartmentsState({ enter: false, active: false });
                    setCommitmentState({ enter: false, active: false });
                }}
                onExited={() => {
                    if (historyState.enter) setHistoryState({ ...historyState, active: true });
                    if (philosophyState.enter) setPhilosophyState({ ...philosophyState, active: true });
                    if (departmentsState.enter) setDepartmentsState({ ...departmentsState, active: true });
                    if (virtualTourState.enter) setVirtualTourState({ ...virtualTourState, active: true });
                    if (virtualToolsState.enter) setVirtualToolsState({ ...virtualToolsState, active: true });
                    if (commitmentState.enter) setCommitmentState({ ...commitmentState, active: true });
                }}
            >
                <Allies />
            </CSSTransition>

            <CSSTransition
                in={virtualToolsState.active}
                timeout={500}
                classNames="vitual-tools-subarea"
                unmountOnExit
                onEnter={() => {
                    setHistoryState({ enter: false, active: false });
                    setPhilosophyState({ enter: false, active: false });
                    setCommitmentState({ enter: false, active: false });
                    setDepartmentsState({ enter: false, active: false });
                    setVirtualTourState({ enter: false, active: false });
                    setAlliesState({ enter: false, active: false });
                }}
                onExited={() => {
                    if (historyState.enter) setHistoryState({ ...historyState, active: true });
                    if (philosophyState.enter) setPhilosophyState({ ...philosophyState, active: true });
                    if (commitmentState.enter) setCommitmentState({ ...commitmentState, active: true });
                    if (virtualTourState.enter) setVirtualTourState({ ...virtualTourState, active: true });
                    if (departmentsState.enter) setDepartmentsState({ ...departmentsState, active: true });
                    if (alliesState.enter) setAlliesState({ ...alliesState, active: true });
                }}
            >
                <VirtualTools />
            </CSSTransition>

            <CSSTransition
                in={virtualTourState.active}
                timeout={500}
                classNames="vitual-tour-subarea"
                unmountOnExit
                onEnter={() => {
                    setHistoryState({ enter: false, active: false });
                    setPhilosophyState({ enter: false, active: false });
                    setCommitmentState({ enter: false, active: false });
                    setDepartmentsState({ enter: false, active: false });
                    setVirtualToolsState({ enter: false, active: false });
                    setAlliesState({ enter: false, active: false });
                }}
                onExited={() => {
                    if (historyState.enter) setHistoryState({ ...historyState, active: true });
                    if (philosophyState.enter) setPhilosophyState({ ...philosophyState, active: true });
                    if (commitmentState.enter) setCommitmentState({ ...commitmentState, active: true });
                    if (departmentsState.enter) setDepartmentsState({ ...departmentsState, active: true });
                    if (virtualToolsState.enter) setVirtualToolsState({ ...virtualToolsState, active: true });
                    if (alliesState.enter) setAlliesState({ ...alliesState, active: true });
                }}
            >
                <VirtualTour />
            </CSSTransition>

            <CSSTransition
                in={departmentsState.active}
                timeout={500}
                classNames="departments-subarea"
                unmountOnExit
                onEnter={() => {
                    setHistoryState({ enter: false, active: false });
                    setPhilosophyState({ enter: false, active: false });
                    setCommitmentState({ enter: false, active: false });
                    setVirtualToolsState({ enter: false, active: false });
                    setVirtualTourState({ enter: false, active: false });
                    setAlliesState({ enter: false, active: false });
                }}
                onExited={() => {
                    if (historyState.enter) setHistoryState({ ...historyState, active: true });
                    if (philosophyState.enter) setPhilosophyState({ ...philosophyState, active: true });
                    if (commitmentState.enter) setCommitmentState({ ...commitmentState, active: true });
                    if (virtualToolsState.enter) setVirtualToolsState({ ...virtualToolsState, active: true });
                    if (virtualTourState.enter) setVirtualTourState({ ...virtualTourState, active: true });
                    if (alliesState.enter) setAlliesState({ ...alliesState, active: true });
                }}
            >
                <Departments />
            </CSSTransition>

            <VideoPlayer />
            <Links animationStates={animationStates} linkState={linkState} handleCurrentLinkState={handleCurrentLinkState} />
            <Dialog />
        </div >
    );
}

export default AboutusPage;