import React, { useState } from 'react';
import './consejoEstudiantil.scss';
import CourseTile from './CourseTile';
import CourseTable from './CourseTable';

const ConsejoEstudiantil = () => {

    const [selectedCourse, setSelectedCourse] = useState('1ro');

    return (
        <div className="students-board">
            <div className="students-board-inner">
                <div className="students-board-title">CONSEJO ESTUDIANTIL</div>
                <div className="students-board-text">
                    <p>
                        Está conformado por los presidentes de los consejos de curso. Garantiza la participación activa del estudiantado en la dinámica escolar.
                        La directiva se elige por votaciones generales de todos los estudiantes.
                    </p>
                </div>
                <div className="students-board-courses-tiles">
                    <div className="row">
                        <div className="col-lg-2 col-md-2 col-sm-4 col-6">
                            <CourseTile course="1ro" setSelectedCourse={setSelectedCourse} />
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-4 col-6">
                            <CourseTile course="2do" setSelectedCourse={setSelectedCourse} />
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-4 col-6">
                            <CourseTile course="3ro" setSelectedCourse={setSelectedCourse} />
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-4 col-6">
                            <CourseTile course="4to" setSelectedCourse={setSelectedCourse} />
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-4 col-6">
                            <CourseTile course="5to" setSelectedCourse={setSelectedCourse} />
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-4 col-6">
                            <CourseTile course="6to" setSelectedCourse={setSelectedCourse} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="students-board-courses-table">
                <CourseTable selectedCourse={selectedCourse} />
            </div>
        </div>
    )
}

export default ConsejoEstudiantil;