import React, { useEffect, useContext } from 'react';
import { baseURL } from '../../../../api';
import { Image } from 'react-bootstrap';
import DialogContentText from '@material-ui/core/DialogContentText';
import ReactMarkdown from 'react-markdown';

// CONTEXT
import MainContext from '../../../../contexts/pages/main/mainContext';

import AwesomeSlider from 'react-awesome-slider';
import WithAutoplay from 'react-awesome-slider/dist/autoplay';
const AutoplaySlider = WithAutoplay(AwesomeSlider);

const NewsBody = () => {

    const mainContext = useContext(MainContext);
    const { news } = mainContext;

    useEffect(() => {
    }, [news])

    // const dateWithMonth = (date) => {
    //     const day = String(date).split("-")[2]
    //     const month = String(date).split("-")[1]
    //     const year = String(date).split("-")[0]
    //     switch (month) {
    //         case "01":
    //             return `${day} de Enero ${year}`
    //         case "02":
    //             return `${day} de Febrero ${year}`
    //         case "03":
    //             return `${day} de Marzo ${year}`
    //         case "04":
    //             return `${day} de Abril ${year}`
    //         case "05":
    //             return `${day} de Mayo ${year}`
    //         case "06":
    //             return `${day} de Junio ${year}`
    //         case "07":
    //             return `${day} de Julio ${year}`
    //         case "08":
    //             return `${day} de Agosto ${year}`
    //         case "09":
    //             return `${day} de Septiembre ${year}`
    //         case "10":
    //             return `${day} de Octubre ${year}`
    //         case "11":
    //             return `${day} de Noviembre ${year}`
    //         case "12":
    //             return `${day} de Diciembre ${year}`
    //         default:
    //             return "01 Enero 2021"
    //     }
    // }
    return (
        // news
        //     ?
        //     news.map((value, index) => {
        //         if (index !== 0) return null
        //         return (
        //             <div key={value._id} className="row news-body">
        //                 <div className="col-lg-7 col-md-6 col-sm-12 new-image">
        //                     <Image src={`${baseURL}${value.imagen.url}`} />
        //                 </div>
        //                 <div className="col-lg-5 col-md-6 col-sm-12 new-info">
        //                     <div className="info new-title">{value.titulo}</div>
        //                     <div className="info divisor"></div>

        //                     <DialogContentText>
        //                         <div className="info new-subtitle">
        //                             <ReactMarkdown>
        //                                 {value.contenido.length > 330 ? `${value.contenido.substr(0, 330)}...` : value.contenido}
        //                             </ReactMarkdown>
        //                         </div>
        //                     </DialogContentText>

        //                     <div className="curves"></div>
        //                 </div>
        //             </div>
        //         )
        //     })
        //     : null

        <AutoplaySlider
                // animation='foldOutAnimation'
                bullets={true}
                organicArrows={false}
                play={true}
                cancelOnInteraction={false} // should stop playing on user interaction
                interval={3000}
                showTimer={false}
            >
                {/* { banner ? banner.map(value => (
                    value['enlace']
                        ? <div key={value._id} data-src={`${baseURL}${value.imagen.formats.large.url}`} className="slider-link" ><a href={`${value.enlace}`} target="_blank" rel="noreferrer noopener"><div className="slider-link"></div></a></div>
                        : <div key={value._id} data-src={`${baseURL}${value.imagen.formats.large.url}`} />
                )) : []} */}
                {
                    news ? news.map((value)=>(
                        <div key={value._id} className="news__container" data-src={`${baseURL}${value.imagen.url}`}  style={{ zIndex: 2, maxHeight: "700px",fill:"cover"}}>
                            <div style={{alignContent:'flex-start'}}>
                            {/* <img src={bgslider} alt="" className="news__image"/> */}
                            <h1  className="news__heading"><b>#NOTICIAS</b>BABEQUE</h1>
                            <h1  className="news__title">{value.titulo}</h1>
                            {/* <h2 style={bodytext}>{value.body}</h2> */}
                            <div className="news__body" >
                                    <ReactMarkdown>
                                        {value.contenido.length > 330 ? `${value.contenido.substr(0, 330)}...` : value.contenido}
                                    </ReactMarkdown>
                                </div>
                            </div>
                        </div>
                    )): null
                }

            </AutoplaySlider>
    )
}

export default NewsBody;