// GENERAL LAYOUT FOR ABOUTUS SUBMENU INSIDE GLOBAL HEADER

import React from 'react';
import './submenu.scss';

import Exit from './Exit';
import { Link } from 'react-router-dom';


const AboutUs = ({ setAboutState }) => {
    return (
        <div className="submenu submenu-aboutus">
            <Link to="/">
                <Exit setState={setAboutState} />
            </Link>
            <div className="links-group">
                <div className="links">
                    <Link to="/conocenos/historia">
                        <h6>Nuestra historia</h6>
                    </Link>
                    <div className="fg"></div>
                </div>
                <div className="links">
                    <Link to="/conocenos/filosofia">
                        <h6>Filosofía institucional</h6>
                    </Link>
                    <div className="fg"></div>
                </div>
                <div className="links">
                    <Link to="/conocenos/compromiso">
                        <h6>Compromiso Social</h6>
                    </Link>
                    <div className="fg"></div>
                </div>
                <div className="links">
                    <Link to="/conocenos/organismos-departamentos">
                        <h6>Organismos y departamentos</h6>
                    </Link>
                    <div className="fg"></div>
                </div>
                <div className="links">
                    <Link to="/conocenos/aliados">
                        <h6>Aliados y acreditaciones</h6>
                    </Link>
                    <div className="fg"></div>
                </div>
                <div className="links">
                    <Link to="/conocenos/herramientas">
                        <h6>Herramientas Virtuales</h6>
                    </Link>
                    <div className="fg"></div>
                </div>
                <div className="links">
                    <Link to="/conocenos/tour-virtual">
                        <h6>Tour Virtual</h6>
                    </Link>
                    <div className="fg"></div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs;