import React, { createContext, useState } from 'react';

export const secundaryPagesContext = createContext();

const SecundaryPagesContext = (props) => {

    const [clubesState, setClubesState] = useState({
        enter: false,
        active: false
    });
    const [feriaCulturalState, setFeriaCulturalState] = useState({
        enter: false,
        active: false
    });
    const [gozabequeState, setGozabequeState] = useState({
        enter: false,
        active: false
    });
    const [MNUState, setMNUState] = useState({
        enter: false,
        active: false
    });
    const [musicalState, setMusicalState] = useState({
        enter: false,
        active: false
    });
    const [optativasState, setOptativasState] = useState({
        enter: false,
        active: false
    });
    const [deporteState, setDeporteState] = useState({
        enter: false,
        active: false
    });
    const [blankState, setBlankState] = useState({
        enter: false,
        active: true
    });

    return (
        <secundaryPagesContext.Provider
            value={{
                clubesState,
                setClubesState,
                feriaCulturalState,
                setFeriaCulturalState,
                gozabequeState,
                setGozabequeState,
                MNUState,
                setMNUState,
                musicalState,
                setMusicalState,
                optativasState,
                setOptativasState,
                deporteState,
                setDeporteState,
                blankState,
                setBlankState

            }}
        >
            {props.children}
        </secundaryPagesContext.Provider>
    );
}

export default SecundaryPagesContext;