import React, { useContext, useEffect } from 'react';
import './cocurriculars.scss';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CocurricularCard from './CocurricularCard';
import { baseURL } from '../../../../api';

// CONTEXT
import MainContext from '../../../../contexts/pages/main/mainContext';

const Cocurriculars = () => {

    const mainContext = useContext(MainContext);
    const { coocurriculars, coocurricularsTitle } = mainContext;

    const responsive = {
        largeDesktop: {
            breakpoint: { max: 4000, min: 1500 },
            items: 5,
            slidesToSlide: 1 // optional, default to 1.
        },
        desktop: {
            breakpoint: { max: 1500, min: 1100 },
            items: 4,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1100, min: 464 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    useEffect(() => {
        // console.log(coocurriculars, coocurricularsTitle);
    }, [coocurriculars, coocurricularsTitle])

    return (
        <div className="co-curriculars-carousel">
            <div className="co-curriculars-inner">
                <div className="co-curriculars-header titles">
                    Actividades
                    <span>Electivas</span>
                    {/* {coocurricularsTitle ? coocurricularsTitle.split(" ")[0] : ""}
                    <span>{coocurricularsTitle ? coocurricularsTitle.split(" ")[1] : ""}</span> */}
                    <div className="bottom-margin"></div>
                </div>
                <Carousel
                    additionalTransfrom={0}
                    arrows={false}
                    autoPlay={true}
                    autoPlaySpeed={2000}
                    centerMode={false}
                    className=""
                    containerClass="container-with-dots"
                    dotListClass=""
                    draggable
                    focusOnSelect={true}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={responsive}
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                >
                    {coocurriculars ?
                        coocurriculars.map(value => {
                            return (
                                <CocurricularCard
                                    key={value._id}
                                    title={value.titulo}
                                    text={value.contenido}
                                    width="18rem"
                                    variant="top"
                                    image={`${baseURL}${value.imagen.url}`}
                                />
                            )
                        })
                        : []}
                </Carousel>
                {/* <div className="co-curriculars-footer">
                    <div className="co-curriculars-footer-button">
                        <Button className="co-curriculars-button">¡CONOCE MÁS!</Button>
                    </div>
                    <div className="co-curriculars-footer-figures">
                        <HollowTriangle Style={{ transform: 'rotate(70deg)' }} />
                        <DotsArray />
                    </div>
                </div> */}
            </div>
        </div>

    )
}

export default Cocurriculars;