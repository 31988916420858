import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import Logo from './Logo';
import DeviceSize from './Responsive';
import MobileNavLinks from './MobileNavLinks';
import '../Globals/Header/header.scss';

const NavbarContainer = styled.div`
	width: 100%;
	height: 10vh;
    // box-shadow: -15px -15px 15px rgba(255, 255, 255, 0.2),
    //             15px 15px 15px rgba(0,0,0,0.1),
    //             inset -50px -50px 50px rgba(255, 255, 255, 0.2),
    //             inset 50px 50px 50px rgba(0,0,0,0.1);
    // background-color: #0a415d;
	// background-color: #fff;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
	align-items: center;
    justify-content: space-between;
	padding: 0.5rem 1rem ;
    z-index: 11;
    position: fixed;
    top: 0;
`;

const RightSection = styled.div`
	display: flex;
`;

const Navbar = () => {
    const [isOpen, setOpen] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });

    return (
        <div className="row header-navbar">
            <NavbarContainer >
                <Logo setOpen={setOpen} />
                <RightSection>
                    {isMobile && <MobileNavLinks isOpen={isOpen} setOpen={setOpen} />}
                </RightSection>
            </NavbarContainer >
        </div>

    );
};

export default Navbar;
