import { TYPES } from '../../../types';

const departmentsReducer = (state, action) => {
    switch (action.type) {
        case TYPES.GET_DEPARTMENTS:
            return {
                ...state,
                directiveBoard: action.payload.consejo_directivo || {},
                academicBoard: action.payload.consejo_academico || {},
                studentsBoard: action.payload.consejo_estudiantil || {},
                departments: action.payload.departamentos || [],
            }
        default:
            return state
    }
}

export default departmentsReducer;