import React, { useEffect, useContext } from 'react';
import './gozabeque.scss';
import DialogContentText from '@material-ui/core/DialogContentText';
import ReactMarkdown from 'react-markdown';

// CONTEXT
import ActivitiesContext from '../../../../../contexts/pages/activities/activitiesContext';

const Gozabeque = () => {

    const activitiesContext = useContext(ActivitiesContext);
    const { gozabeque } = activitiesContext;

    useEffect(() => {
    }, [gozabeque])

    return (
        <div className="gozabeque activity-subpage">
            <div className="gozabeque-inner act">
                <div className="gozabeque-title">{gozabeque ? gozabeque.titulo.toUpperCase() : "GOZABEQUE"}</div>
                <div className="gozabeque-text">
                    {gozabeque
                        ?
                        // <DialogContentText>
                            <ReactMarkdown>
                                {gozabeque.contenido}
                            </ReactMarkdown>
                        // </DialogContentText>
                        :
                        <p>
                            La Secundaria Babeque es un centro educativo auspiciado por la Fundación Pedagógica Dominicana Inc.,
                            institución sin fines de lucro creada en el año de 1977  por un grupo de madres y padres cuyos hijos
                            terminaban el sexto grado del Colegio Babeque Inicial y Primaria. Con esta iniciativa se proponían
                            velar por el desarrollo de los valores universales, la dominicanidad y la formación integral de sus hijos e hijas.

                            Se seleccionó el nombre Babeque, voz de origen taíno que significa tierra de oro, por considerar a
                            la niñez y la juventud como la esperanza y el tesoro más preciado de una nación.
                        </p>
                    }
                </div>
            </div>
        </div>
    )
}

export default Gozabeque;