import { TYPES } from '../../types';

const aboutusReducer = (state, action) => {
    switch (action.type) {
        case TYPES.GET_ABOUTUS:
            return {
                ...state,
                allies: action.payload.acreditaciones || null,
                commitment: action.payload.compromiso_social || null,
                departments: action.payload.departamentos || null,
                history: action.payload.historia || null,
                philosophy: action.payload.filosofia || null,
                virtualTools: action.payload.herramientas_virtuales || null,
                virtualTour: action.payload.virtualTour || null,
            }
        default:
            return state
    }
}

export default aboutusReducer;