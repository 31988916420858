import React, { useEffect, useContext, useState } from 'react';
import './consejoAcademico.scss';
import ReactMarkdown from 'react-markdown';
import TeamCarousel from  '../../../../../../Helpers/TeamCarousel'

// CONTEXT
import AboutusDepartmentsContext from '../../../../../../../contexts/pages/aboutus/departments/departmentsContext';

const ConsejoAcademico = () => {

    const aboutusDepartmentsContext = useContext(AboutusDepartmentsContext);
    const { academicBoard } = aboutusDepartmentsContext;
    const { tarjetas_consejo_academico } = academicBoard;

    const [team, setTeam] = useState({
        directive: true,
        coordination: false,
        support: false
    });
    // const [directive, setDirective] = useState();
    // const [coordination, setCoordination] = useState();
    // const [support, setSupport] = useState();
    const [management, setManagement] = useState();
    const [members, setMembers] = useState();

    const filterBoard = () => {
        // const directiveTeam = tarjetas_consejo_academico.filter(value => value.equipo.toUpperCase() === 'DIRECTIVO');
        // const coordinationTeam = tarjetas_consejo_academico.filter(value => value.equipo.toUpperCase() === 'REPRESENTANTES');
        // const supportTeam = tarjetas_consejo_academico.filter(value => value.equipo.toUpperCase() === 'SOPORTE');
        const managementTeam = tarjetas_consejo_academico.filter(value => value.equipo.toUpperCase() === 'GESTION');
        const membersTeam = tarjetas_consejo_academico.filter(value => value.equipo.toUpperCase() === 'MIEMBROS');
        // setDirective(directiveTeam);
        // setCoordination(coordinationTeam)
        // setSupport(supportTeam)
        setManagement(managementTeam)
        setMembers(membersTeam)
    }

    useEffect(() => {
        if (academicBoard) filterBoard()
        // eslint-disable-next-line
    }, [academicBoard])

    const handleTeam = (team) => {
        if (team === 'directive') {
            setTeam({
                directive: true,
                coordination: false,
                support: false
            })
        } else if (team === 'coordination') {
            setTeam({
                directive: false,
                coordination: true,
                support: false
            })
        } else {
            setTeam({
                directive: false,
                coordination: false,
                support: true
            })
        }
    }
    //console.log(tarjetas_consejo_academico)
    return (
        <div className="academic-container">
            {/* {
                academicBoard
                    ?
                    <div className="academic-board">
                        < div className="academic-board-inner" >
                            <div className="academic-board-title">CONSEJO ACADEMICO</div>
                            <div className="academic-board-text">
                                <DialogContentText>
                                    <ReactMarkdown>
                                        {academicBoard.descripcion}
                                    </ReactMarkdown>
                                </DialogContentText>
                            </div>
                            <div className="board-division">
                                <div className="row">
                                    <div className="col-board-division col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                        <div className="directive-team">
                                            <div
                                                className="directive-team-inner"
                                                style={team.directive ? { color: '#0a415d', backgroundColor: '#ffe25d' } : null}
                                                onClick={() => handleTeam('directive')}
                                            >
                                                <p>Equipo Directivo</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-board-division col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                        <div className="coordinators">
                                            <div
                                                className="coordinators-inner"
                                                style={team.coordination ? { color: '#0a415d', backgroundColor: '#ffe25d' } : null}
                                                onClick={() => handleTeam('coordination')}
                                            >
                                                <p>Representantes de Grados</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-board-division col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                        <div className="support">
                                            <div
                                                className="support-inner"
                                                style={team.support ? { color: '#0a415d', backgroundColor: '#ffe25d' } : null}
                                                onClick={() => handleTeam('')}
                                            >
                                                <p>Equipo de Soporte</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                        <div className="academic-board-profiles-inner">
                            {team.directive ? <DirectiveTeamProfiles spacing={4} src={directive} small /> : null}
                            {team.coordination ? <DirectiveTeamProfiles spacing={4} src={coordination} small /> : null}
                            {team.support ? <DirectiveTeamProfiles spacing={2} src={support} small /> : null}
                        </div>
                    </div >
                    :
                    <div className="academic-board">
                        <div className="academic-board-inner">
                            <div className="academic-board-title">CONSEJO ACADEMICO</div>
                            <div className="academic-board-text">
                                <p>
                                    Es el responsable del diseño y ejecución del currículo, del crecimiento académico del profesorado
                                    y de poner en marcha los proyectos de innovación educativa. Está formado por el equipo directivo
                                    y los coordinadores de los diferentes departamentos y áreas académicas.
                                </p>
                            </div>
                            <div className="board-division">
                                <div className="row">
                                    <div className="col-board-division col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div className="directive-team">
                                            <div className="directive-team-inner"><p>Equipo Directivo</p></div>
                                        </div>
                                    </div>
                                    <div className="col-board-division col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div className="coordinators">
                                            <div className="coordinators-inner"><p>Coordinadores</p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="academic-board-profiles-inner"></div>
                    </div>
            } */}
            <div className="academic-board-title">CONSEJO ACADEMICO</div>
                            <div className="academic-board-text">
                                {/* <DialogContentText> */}
                                    <ReactMarkdown>
                                        {academicBoard.descripcion}
                                    </ReactMarkdown>
                                {/* </DialogContentText> */}
                            </div>
            <div className="academic-title">EQUIPO DE GESTION</div>
            <TeamCarousel items={management} />
            <div className="academic-title">MIEMBROS DEL CONSEJO</div>
            <TeamCarousel items={members}/>
            {/* <div className="academic-title">MIEMBROS DEL CONSEJO</div>
            <TeamCarousel items={support} /> */}
        </div>
    )
}

export default ConsejoAcademico;