import React from 'react';
import './main.scss';


const Main = (props) => {
	return (
		<div className="row main">
			<div className='col-lg-12 col-md-12 col-sm-12'>
				<div className="main-wrapper">
					{/* Component inherited */}
					{props.children}
					{/* Component inherited */}
				</div>
			</div>
		</div>

	);
};

export default Main;