// Main Page Reducer
const GET_MAIN = "GET_MAIN";

// Aboutus Page Reducer
const GET_ABOUTUS = "GET_ABOUTUS";

// Aboutus Page Reducer
const GET_ACTIVITIES = "GET_ACTIVITIES";

// Departaments Page Reducer
const GET_DEPARTMENTS = "GET_DEPARTMENTS";

// Curriculum Page Reducer
const GET_CURRICULUM = "GET_DEPARTMENTS";

export const TYPES = { 
    GET_MAIN,
    GET_ABOUTUS,
    GET_DEPARTMENTS,
    GET_ACTIVITIES,
    GET_CURRICULUM,
}