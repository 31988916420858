import { useState, useEffect, useContext } from 'react';

import AwesomeSlider from 'react-awesome-slider';
import WithAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/src/styles';
import './slider.scss';

import { baseURL } from '../../../../api';

//CONTEXT
import ActivitiesContext from '../../../../contexts/pages/activities/activitiesContext';
import { secundaryPagesContext } from '../../../../contexts/pages/activities/secundaryPages/secundaryPagesContext';

const AutoplaySlider = WithAutoplay(AwesomeSlider);

const Slider = ({ ClassName }) => {

    const [activeImages, setActiveImages] = useState([]);

    const ActivitiesState = useContext(ActivitiesContext);
    // const ActivitiesState  = activitiesContext;

    const activitiesPagesContext = useContext(secundaryPagesContext);
    const {
        feriaCulturalState,
        gozabequeState,
        musicalState,
        clubesState,
        optativasState,
        MNUState: modeloNacionesUnidasState,
    } = activitiesPagesContext;


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const activitiesStates = [
        feriaCulturalState,
        gozabequeState,
        musicalState,
        clubesState,
        optativasState,
        modeloNacionesUnidasState
    ]

    useEffect(() => {
        const getActiveActivity = () => {
            if (ActivitiesState?.feriaCultural) {
                activitiesStates.forEach((value, index) => {
                    if (value.active) {
                        if (Object.values(ActivitiesState)[index]['imagenes']) {
                            setActiveImages(Object.values(ActivitiesState)[index].imagenes)
                        }
                    }
                })
            }
        }
        getActiveActivity();
        // eslint-disable-next-line 
    }, [feriaCulturalState, gozabequeState, musicalState, clubesState, optativasState, modeloNacionesUnidasState, activitiesStates, ActivitiesState])


    return (
        <div className={`${ClassName || 'slider'}`}>
            {activeImages.length > 0
                ?
                <AutoplaySlider
                    bullets={true}
                    play={true}
                    organicArrows={false}
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={3000}
                    showTimer={true}
                >
                    {activeImages
                        ?
                        activeImages.map(value => {
                            return <div key={value._id} data-src={`${baseURL}${value.url}`} />
                        })
                        :
                        <div data-src={""} />
                    }

                </AutoplaySlider>
                : null}
        </div>
    )

}

export default Slider;