import React, { useContext, useEffect, useState } from "react";
import "./curriculum.scss";
import Slider from "./Slider";
import ReactMarkdown from "react-markdown";

import TeamCarousel from "../../Helpers/TeamCarousel";

//CONTEXT
import CurriculumContext from "../../../contexts/pages/curriculum/curriculumContext";



const CurriculumPage = () => {
  const curriculumContext = useContext(CurriculumContext);
  const { description, subjects, getCurriculum, dotsLoaded } =
    curriculumContext;


  useEffect(() => {
    getCurriculum();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {}, [dotsLoaded]);

  const [subjectState, setSubjectState] = useState({
    titulo: "",
    descripcion: "",
  });
  const handleSubjectState = (subject) => {
    setSubjectState(subject);
  };

  return (
    <div className="row curriculum-body">
      <Slider ClassName={"curriculum-slider"} />
      <div className="curriculum">
        <div className="curriculum-inner">
          <div className="curriculum-title">CURRICULUM</div>
          <div className="curriculum-text">
            {description ? <ReactMarkdown>{description}</ReactMarkdown> : ""}
          </div>
          <div className="subject-title">ÁREAS ACADÉMICAS</div>
          <div className="row-sub">
            {subjects
              ? subjects.map((subject) => {
                  const currSubject = {
                    titulo: subject.titulo,
                    descripcion: subject.descripcion,
                  };
                  return (
                    <div
                      key={subject.id}
                      className={`subjectTile ${
                        subject.titulo === subjectState.titulo ? "active" : ""
                      }`}
                      // active={subject.titulo === subjectState.titulo}
                      onClick={() => handleSubjectState(currSubject)}
                    >
                      <span className="subjectTile-title">
                        {subject.titulo.toUpperCase()}
                      </span>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
        {subjects ? (
          <div className="personal-row">
            <div className="personal-title">{subjectState.titulo}</div>
            <div className="personal-text">{subjectState.descripcion}</div>

            <TeamCarousel
              items={
                subjects
                  .filter((item) => item.titulo === subjectState.titulo)
                  .map((item) => item.tarjetas_personal)[0]
              }
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CurriculumPage;
