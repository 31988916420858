import React, { useContext } from 'react';
import './consejoDirectivo.scss';
import Cards from './Cards';

// CONTEXT
import AboutusDepartmentsContext from '../../../../../../../contexts/pages/aboutus/departments/departmentsContext';

const ConsejoDirectivo = () => {

    const aboutusDepartmentsContext = useContext(AboutusDepartmentsContext);
    const { directiveBoard } = aboutusDepartmentsContext;

    return (
        <div className="directors-board">
            <div className="directors-board-inner">
                <div className="directors-board-title">CONSEJO DIRECTIVO</div>
                <div className="directors-board-text">
                    {directiveBoard
                        ?
                        directiveBoard.descripcion
                        :
                        <p>
                            Es el administrador legal del patrimonio del Colegio.
                            Está formado por diez padres o madres elegidos por la Asamblea.
                        </p>
                    }
                </div>
            </div>
            <Cards directiveBoard={directiveBoard} />
        </div>
    )
}

export default ConsejoDirectivo;