import Allies from './Allies';
import Commitment from './Commitment';
import Departments from './Departments';
import History from './History';
import Philosophy from './Philosophy';
import VirtualTools from './VirtualTools';
import VirtualTour from './VirtualTour';

const AboutusPages = {
    Allies,
    Commitment,
    Departments,
    History,
    Philosophy,
    VirtualTools,
    VirtualTour,
}

export default AboutusPages