import React, { useState, useEffect } from 'react';
import Carousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/scss/alice-carousel.scss';
import './gallery.scss';

import { baseURL } from '../../../api'

const Gallery = ({ photos }) => {

    const [items, setItems] = useState([])

    const handleDragStart = (e) => e.preventDefault();

    const responsive = {
        0: {
            items: 2,
        },
        768: {
            items: 3,
        },
        1024: {
            items: 4,
        }
    }


    useEffect(() => {
        const setPhotos = () => {
            const photosArr = []
            photos.forEach(value => {
                photosArr.push(<img src={`${baseURL}${value.url}`} onDragStart={handleDragStart} role="presentation" alt="presentation" />);
                setItems(photosArr);
            })
        }
        setPhotos();
        // eslint-disable-next-line
    }, [])

    return (
        <div className="gallery-dialog">
            <Carousel responsive={responsive} mouseTracking items={items} />
        </div>
    )

}

export default Gallery;