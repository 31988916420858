import React from 'react';
import './secondCicle.scss';

const SecondCicle = () => {
    return (
        <div className="subpage second-cicle">
            <p>SEGUNDO CICLO</p>
        </div>
    )
}

export default SecondCicle;