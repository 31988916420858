// GENERAL LAYOUT FOR ACCOUNTS SUBMENU INSIDE GLOBAL HEADER

import React from 'react'
import { Link } from 'react-router-dom'

import alumnosSVG from '../../../../assets/Alumnos.svg'
import birreteSVG from '../../../../assets/Birrete.svg'
import padresSVG from '../../../../assets/Padres.svg'
import bbqLogo from '../../../../assets/icono_bbqs.png'
import papaSVG from '../../../../assets/Papa.svg'
import moodleIcon from '../../../../assets/moodle-icon.svg'
import powerschoolIcon from '../../../../assets/powerschool-icon.svg'

const Accounts = () => {
    return (
        <div className="sub-menu-1">
            <div className="sub-menu-1-socials">
                <div className="socials-icons">
                    <a href="https://www.instagram.com/secundariababeque/" target="_blank" rel="noopener" >
                        <div className="social-icon ig iconColorGroup">
                            <i className="fab fa-instagram"></i>
                        </div>
                    </a>
                    <a href="https://www.facebook.com/SecundariaBabeque/" target="_blank" rel="noopener" >
                        <div className="social-icon fb iconColorGroup">
                            <i className="fab fa-facebook-f"></i>
                        </div>
                    </a>
                    <a href="https://www.youtube.com/channel/UCObqQz5rZcd55SKlrpJNCng" target="_blank" rel="noopener" >
                        <div className="social-icon yt iconColorGroup ">
                            <i className="fab fa-youtube"></i>
                        </div>
                    </a>
                    <a href="https://moodle.secundariababeque.edu.do/" target="_blank" rel="noopener" >
                        <div className="social-icon fb iconColorGroup">
                        <img src={moodleIcon} alt="Acceso Moodle" width="28px"/>
                        </div>
                    </a>
                    <a href="https://secundariababeque.powerschool.com/public/home.html" target="_blank" rel="noopener" >
                        <div className="social-icon yt iconColorGroup">
                        <img src={powerschoolIcon} alt="Acceso Powerschool" width="28px"/>
                        </div>
                    </a>
                </div>
            </div>
            <div className="sub-menu-1-accounts">
                {/* <div className="links iconColorGroup">
                    <Link to="/">
                        <img  src={alumnosSVG} alt="SVG de Alumnos" />
                        <h6>ESTUDIANTES</h6>
                    </Link>
                </div>
                <div className="border-left iconColorGroup"></div> */}
                <div className="links iconColorGroup">
                    {/* <Link to="/">
                        <img  src={padresSVG} alt="SVG de Alumnos" />
                        <h6>FAMILIA</h6>
                    </Link> */}
                    <a href="https://intranet.secundariababeque.edu.do" target="_blank" rel="noreferrer">
                        <img  src={bbqLogo} alt="SVG de SBBQ" />
                        <h6>INTRANET</h6>
                    </a>
                </div>
                {/* <div className="border-left iconColorGroup"></div>
                <div className="links iconColorGroup">
                    <Link to="/">
                        <img  src={papaSVG} alt="SVG de Alumnos" />
                        <h6>DOCENTES</h6>
                    </Link>
                </div>
                <div className="border-left iconColorGroup"></div>
                <div className="links iconColorGroup">
                    <Link to="/">
                        <img  src={birreteSVG} alt="SVG de Alumnos" />
                        <h6>EGRESADOS/AS</h6>
                    </Link>
                </div> */}
            </div>

        </div>
    )
}

export default Accounts;
