import React, { useReducer } from 'react';
import MainContext from './mainContext';
import MainReducer from './mainReducer';
import api from '../../../api';

import { TYPES } from '../../types';

const MainState = (props) => {

    const initialState = {
        banner: null,
        words: null,
        coocurriculars: null,
        coocurricularsTitle: null,
        events: null,
        news: null,
        calendar: null,
        anthemLyrics:null,
        activitiesLink:null,
        eventBg:null
    }

    const getMonthName = (monthNumber) => {
        switch (monthNumber) {
            case 0:
                return `Enero`.toUpperCase()
            case 1:
                return `Febrero`.toUpperCase()
            case 2:
                return `Marzo`.toUpperCase()
            case 3:
                return `Abril`.toUpperCase()
            case 4:
                return `Mayo`.toUpperCase()
            case 5:
                return `Junio`.toUpperCase()
            case 6:
                return `Julio`.toUpperCase()
            case 7:
                return `Agosto`.toUpperCase()
            case 8:
                return `Septiembre`.toUpperCase()
            case 9:
                return `Octubre`.toUpperCase()
            case 10:
                return `Noviembre`.toUpperCase()
            case 11:
                return `Diciembre`.toUpperCase()
            default:
                return ""
        }
    }

    const getMonthNumber = (monthNumber) => {
        switch (monthNumber) {
            case "Enero".toUpperCase():
                return 0
            case "Febrero".toUpperCase():
                return 1
            case "Marzo".toUpperCase():
                return 2
            case "Abril".toUpperCase():
                return 3
            case "Mayo".toUpperCase():
                return 4
            case "Junio".toUpperCase():
                return 5
            case "Julio".toUpperCase():
                return 6
            case "Agosto".toUpperCase():
                return 7
            case "Septiembre".toUpperCase():
                return 8
            case "Octubre".toUpperCase():
                return 9
            case "Noviembre".toUpperCase():
                return 10
            case "Diciembre".toUpperCase():
                return 11
            default:
                return (new Date()).getMonth()
        }
    }

    const [state, dispatch] = useReducer(MainReducer, initialState);

    //FETCH ALL MAIN PAGE DATA FROM API
    const getMain = async () => {
        try {
            const result = await api.get('/inicio');
            dispatch({
                type: TYPES.GET_MAIN,
                payload: result.data
            })
        } catch (error) {
            
        }
    }

    return(
        <MainContext.Provider
            value={{
                banner: state.banner,
                words: state.words,
                coocurriculars: state.coocurriculars,
                coocurricularsTitle: state.coocurricularsTitle,
                events: state.events,
                news: state.news,
                calendar: state.calendar,
                anthemLyrics:state.anthemLyrics,
                activitiesLink: state.activitiesLink,
                eventBg: state.eventBg,
                getMain,
                getMonthName,
                getMonthNumber
            }}
        >
            {props.children}
        </MainContext.Provider>
    )

}

export default MainState;