import React, { createContext, useState } from 'react';

export const secundaryPagesContext = createContext();

const SecundaryPagesContext = (props) => {

    const [historyState, setHistoryState] = useState({
        enter: false,
        active: true
    });
    const [philosophyState, setPhilosophyState] = useState({
        enter: false,
        active: false
    });
    const [commitmentState, setCommitmentState] = useState({
        enter: false,
        active: false
    });
    const [alliesState, setAlliesState] = useState({
        enter: false,
        active: false
    });

    const [virtualToolsState, setVirtualToolsState] = useState({
        enter: false,
        active: false
    });
    const [virtualTourState, setVirtualTourState] = useState({
        enter: false,
        active: false
    });
    const [departmentsState, setDepartmentsState] = useState({
        enter: false,
        active: false
    });

    return (
        <secundaryPagesContext.Provider
            value={{
                historyState,
                setHistoryState,
                philosophyState,
                setPhilosophyState,
                commitmentState,
                setCommitmentState,
                alliesState,
                setAlliesState,
                virtualToolsState,
                setVirtualToolsState,
                virtualTourState,
                setVirtualTourState,
                departmentsState,
                setDepartmentsState,
            }}
        >
            {props.children}
        </secundaryPagesContext.Provider>
    );
}

export default SecundaryPagesContext;