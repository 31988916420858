import React from 'react';
import {render} from 'react-dom';
import './assets/fonts/Aleo-Bold.otf';
import './assets/fonts/Aleo-Regular.otf';
import './assets/fonts/Aleo-Italic.otf';
import './assets/fonts/Aleo-Light.otf';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root')
const script = document.createElement("script")
script.setAttribute("async","")
script.setAttribute("defer","")
script.setAttribute("data-website-id","284aa3b4-082c-4796-9782-6a4a4a4d0b80")
script.setAttribute("src", "https://umami.apps.secundariababeque.edu.do/script.js")
document.head.appendChild(script)

render( <App/>, container)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
