import React,{useContext} from 'react';
import './cards.scss';
import { baseURL } from '../../../api';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Image } from 'react-bootstrap'
import DirectiveTeamProfiles from '../../Helpers/ProfileCard';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// CONTEXT
import { dialogContext } from '../../../contexts/helpers/dialog/dialogContext';

const TeamCarousel = ({ items}) => {
    const DialogContext = useContext(dialogContext);
    const { handleOpen, setProfile, setType } = DialogContext;

    const responsive = {
        desktop: {
            breakpoint: { max: 4000, min: 1024 },
            items: 5,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 4,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
    // const directiveTeam = Array.of(...academicCouncil).filter((item) => item.equipo == area)
    // console.log(items)

    return (
        <div className="item-cards-carousel">
            <div className="item-cards-inner">
                <Carousel
                    additionalTransfrom={0}
                    arrows
                    // autoPlay
                    // autoPlaySpeed={30000}
                    centerMode={false}
                    className=""
                    containerClass="container-with-dots"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    // infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={responsive}
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable

                    style={{width:"auto"}}
                >
                    {items
                        ?
                        Array.of(...items).map(item => {
                            const handleClick = (value) => {
                                const { imagen, nombre, cargo, profesion, frase, correo, nacionalidad } = value;
                                setType("profileCard")
                                setProfile({ imagen, nombre, cargo, profesion, frase, correo, nacionalidad });
                                handleOpen();
                            }
                            return (
                                (
                                    <div key={item.id} className="item-card" style={{height:"35vh",width:200, margin: "0 10px"}}
                                        onClick={() => {
                                            handleClick({
                                                imagen: `${baseURL}${item.foto?.hasOwnProperty('formats') ? item.foto.formats?.hasOwnProperty('thumbnail') ? item.foto.formats?.thumbnail.url : item.foto.url : item.foto.url}`,
                                                nombre: String(item.nombre),
                                                cargo: String(item.cargo),
                                                profesion: String(item.profesion),
                                                frase: item.frase,
                                                correo: String(item.correo),
                                                nacionalidad: String(item.nacionalidad),
                                            })
                                        }}
                                    >
                                       {/* <Image src={`${baseURL}${ item.foto.hasOwnProperty('formats') ? item.foto.formats.hasOwnProperty('medium') ? item.foto.formats.medium.url : item.foto.url : item.foto.url}`} alt={`image-${item.nombre}`} style={{width:"100%", height:"200px",    objectFit: "cover",objectPosition: "top"}}></Image> */}
                                       {!!item?.foto?.formats?.small?.url && <Image src={`${baseURL}${ item.foto.formats.small.url}`} alt={`image-${item.nombre}`} style={{width:"100%", height:"200px",    objectFit: "cover",objectPosition: "top"}} />}
                                       <div className="card-content">
                                            <div className="card-name">
                                                {item.nombre}
                                            </div>
                                            <div className="card-charge">
                                                {item.cargo}
                                            </div>
                                       </div>
                                    </div>
                                )
                            )
                        })
                        : <div></div>
                    }
                </Carousel>
            </div>
            {/* <div className="academic-board-profiles-inner">
                <DirectiveTeamProfiles spacing={4} src={items} small /> 
            </div> */}
        </div>

    )
}

export default TeamCarousel;